import React, { useState, useEffect } from 'react';
import SteppedNavigation from '@exporter-services/common-ui/src/components/shared/steppedNavigation/SteppedNavigation';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { Row, Col } from 'reactstrap';
import { ContentItem } from '@exporter-services/common-ui';

interface XptSteppedNavigationProps {
    data: {
        item?: ContentItem;
    };
    activeStep: number;
}

interface Step {
    stepCounter: number;
    stepTitle: string;
}

const XptSteppedNavigation = (props: XptSteppedNavigationProps) => {
    const [loading] = useState<boolean>(false);
    const [steps, setSteps] = useState<Step[]>([]);

    useEffect(() => {
        const loadSteps = () => {
            if (props.data.item) {
                let templateWizardSteps: Step[] = [
                    { stepCounter: 1, stepTitle: props.data.item.elements.step1_title.value },
                    { stepCounter: 2, stepTitle: props.data.item.elements.step2_title.value },
                    { stepCounter: 3, stepTitle: props.data.item.elements.step3_title.value },
                ];

                setSteps(templateWizardSteps);
            }
        };

        loadSteps();
    }, []);

    const renderSteppedNavigation = () => {
        return <SteppedNavigation steps={steps} activeStep={props.activeStep} />;
    };

    return (
        <>
            <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
                <Row>
                    <Col md="12">{renderSteppedNavigation()}</Col>
                </Row>
            </LoadingOverlay>
        </>
    );
};

export default XptSteppedNavigation;
