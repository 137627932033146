import React from 'react';
import '../../controls/bodyCopy/BodyCopy.scss';

const NotFound = () => {
    return (
        <div className="body-copy">
            <h3>Sorry, this tool isn’t available in your location</h3>
            <p>
                You appear to be outside Australia. Due to our licensing agreement, this tool is only available to users in Australia. If you think
                this is a mistake, <a href="https://www.austrade.gov.au/contact/general-enquiries">send us a message</a> or call us on 13 28 78
                (within Australia).
            </p>
            <p>
                In the meantime, you can find:
                <ul>
                    <li>
                        {' '}
                        preferential tariffs through the Department of Foreign Affairs and Trade’s{' '}
                        <a href="https://ftaportal.dfat.gov.au/"> Free Trade Agreement</a>
                        Portal
                    </li>
                    <li>
                        most favoured nation tariffs through the{' '}
                        <a href="https://globaltradehelpdesk.org/">International Trade Centre's Global Trade Helpdesk.</a>
                    </li>
                </ul>
            </p>
        </div>
    );
};
export default NotFound;
