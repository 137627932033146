import React, { useEffect, useState } from 'react';
import { client } from '../../config';
import { removeLastTwoChar } from '../../utils/utils';
import Contacts from '@exporter-services/common-ui/src/components/shared/contacts/Contacts';
import { CommonConstants } from '../../CommonConstants';

interface ContactsProps {
    data: {
        item: any;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const ContactsPlaceholder = (props: ContactsProps) => {
    const [contacts, setContacts] = useState(null);

    const params = props.match.params;
    const market = params.market.toUpperCase();
    const propsType = props.type;

    const getIndustry = () => {
        if (propsType === 'strong_market') {
            return CommonConstants.PRODUCTS_CODENAME;
        } else {
            if (propsType === 'market' && params.sector !== 'all-sector') {
                return params.sector.replace(/-/g, '_');
            } else {
                return params.industry.replace(/-/g, '_');
            }
        }
    };

    const industry = getIndustry();

    const getHSCode = (hsCode: string) => {
        return removeLastTwoChar(hsCode);
    };

    const getContactsWithSector = () => {
        client
            .items()
            .equalsFilter('system.type', 'contact')
            .anyFilter('elements.industry_sector', [CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME, industry])
            .anyFilter('elements.marketcodesnippet__market', [CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME, market.toLowerCase()])
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                props.onLoad?.(response.data.items.length > 0, 'Contacts');
                if (response.data.items.length > 0) {
                    setContacts(response.data.items);
                }
            });
    };

    let contactsArray: any[] = [];

    const getContactsWithHSCode = (hsCode: string) => {
        if (hsCode) {
            client
                .items()
                .equalsFilter('system.type', 'contact')
                .anyFilter('elements.marketcodesnippet__market', [CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME, market.toLowerCase()])
                .anyFilter('elements.hscodesnippet__hscode', [CommonConstants.KONTENT_HSCODES_ALL_CODENAME, 'n' + hsCode])
                .depthParameter(5)
                .toPromise()
                .then((response) => {
                    if (response.data.items.length > 0) {
                        contactsArray = [...contactsArray, ...response.data.items];
                    }
                    getContactsWithHSCode(getHSCode(hsCode));
                    if (hsCode.length === 2) {
                        props.onLoad?.(contactsArray.length > 0, 'Contacts');
                        if (contactsArray.length > 0) {
                            const distinctArray = contactsArray.filter((item, i, arr) => {
                                return arr.indexOf(arr.find((t) => t.system.id === item.system.id)) === i;
                            });
                            setContacts(distinctArray);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (propsType === 'market' || propsType === 'market_explorer') {
            getContactsWithSector();
        } else {
            getContactsWithHSCode(props.match.params.sector);
        }
    }, []);

    return <>{contacts && <Contacts data={{ items: contacts }} />}</>;
};

export default ContactsPlaceholder;
