import React, { useState, useEffect, useRef } from 'react';
import '../../../controls/search/CommoditiesSearch.scss';
import { handleMarketSearchResponse } from '../../../utils/marketSearchUtils';
import { useGetTariffMarketsQuery } from '../../../providers/reducers/marketsApi';
import { MarketOption } from '../../../models/MarketOption';
import MarketSearchBase from '../../../controls/search/MarketSearchBase';

export interface TariffsMarketsSearchProps {
    onClick?: (selectedUrl: MarketOption) => void;
    ariaLabel: string;
    clearSelection?: boolean;
    onFocus?: Function;
}

const TariffsMarketsSearch = (props: TariffsMarketsSearchProps) => {
    const [selectedValue, setSelectedValue] = useState<MarketOption>(null);
    const [marketOptions, setMarketOptions] = useState<MarketOption[]>();
    const marketSearchInputRef = useRef(null);

    const { data, isSuccess } = useGetTariffMarketsQuery({});

    useEffect(() => {
        if (isSuccess) {
            const markets = handleMarketSearchResponse(data);
            setSelectedValue(null);
            props.onClick?.(null);
            setMarketOptions(markets);
        }
    }, [isSuccess]);

    useEffect(() => {
        marketSearchInputRef.current?.focus();
    }, [selectedValue]);

    useEffect(() => {
        if (props.clearSelection === true) {
            setSelectedValue(null);
        }
    }, [props.clearSelection]);

    const handleChange = (selectedOption: MarketOption) => {
        setSelectedValue(selectedOption);
        props.onClick?.(selectedOption);
    };

    const setMarketSearchInputRef = (ref: any) => {
        marketSearchInputRef.current = ref;
    };

    const onDropdownFocus = () => {
        props.onFocus?.();
    };

    return (
        <MarketSearchBase
            id={'productsMarketSearch'}
            marketOptions={marketOptions}
            setMarketSearchInputRef={setMarketSearchInputRef}
            handleOnChange={handleChange}
            selectedValue={selectedValue}
            class={'commoditiessearch-ahecc-container'}
            ariaLabel={props.ariaLabel}
            onFocus={onDropdownFocus}
        />
    );
};
export default TariffsMarketsSearch;
