import React from 'react';
import './BypassBlock.scss';

const BypassBlock = () => {
    return (
        <div className="skip-link">
            <a className="bypass-block" href="#mainsection">
                skip to content
            </a>
            <a className="bypass-block" href="#mainnavigation">
                skip to navigate
            </a>
        </div>
    );
};

export default BypassBlock;
