import React, { useEffect, useState } from 'react';
import { client } from '../../config';
import EventPathway from '../eventPathway/EventPathway';
import { removeLastTwoChar } from '../../utils/utils';
import { CommonConstants } from '../../CommonConstants';

interface EventsProps {
    data: {
        item: any;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const Events = (props: EventsProps) => {
    const [events, setEvents] = useState(null);

    const params = props.match.params;
    const market = params.market.toUpperCase();
    const propsType = props.type;

    const getIndustry = () => {
        if (propsType === 'strong_market') {
            return CommonConstants.PRODUCTS_CODENAME;
        } else {
            if (propsType === 'market' && params.sector !== 'all-sector') {
                return params.sector.replace(/-/g, '_');
            } else {
                return params.industry.replace(/-/g, '_');
            }
        }
    };

    const industry = getIndustry();

    const getHSCode = (hsCode: string) => {
        let str = removeLastTwoChar(hsCode);
        return str;
    };

    const getEventsWithSector = () => {
        client
            .items()
            .equalsFilter('system.type', 'event_pathway')
            .anyFilter('elements.industry_sector', [CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME, industry])
            .anyFilter('elements.marketcodesnippet__market', [CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME, market.toLowerCase()])
            .orderByAscending('elements.event_start_date')
            .depthParameter(2)
            .toPromise()
            .then((response) => {
                props.onLoad?.(response.data.items.length > 0, 'Upcoming events');
                if (response.data.items.length > 0) {
                    setEvents(response.data.items);
                }
            });
    };

    let eventsArray: any[] = [];

    const getEventsWithHSCode = (hsCode: string) => {
        if (hsCode) {
            client
                .items()
                .equalsFilter('system.type', 'event_pathway')
                .anyFilter('elements.marketcodesnippet__market', [CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME, market.toLowerCase()])
                .anyFilter('elements.hscodesnippet__hscode', [CommonConstants.KONTENT_HSCODES_ALL_CODENAME, 'n' + hsCode])
                .depthParameter(2)
                .toPromise()
                .then((response) => {
                    if (response.data.items.length > 0) {
                        eventsArray = [...eventsArray, ...response.data.items];
                    }
                    getEventsWithHSCode(getHSCode(hsCode));
                    if (hsCode.length === 2) {
                        props.onLoad?.(eventsArray.length > 0, 'Upcoming events');
                        if (eventsArray.length > 0) {
                            const distinctArray = eventsArray.filter((item, i, arr) => {
                                return arr.indexOf(arr.find((t) => t.system.id === item.system.id)) === i;
                            });
                            setEvents(distinctArray);
                        }
                    }
                });
        }
    };

    useEffect(() => {
        if (propsType === 'market' || propsType === 'market_explorer') {
            getEventsWithSector();
        } else {
            getEventsWithHSCode(props.match.params.sector);
        }
    }, []);

    return <>{events && <EventPathway data={{ items: events }} />}</>;
};

export default Events;
