import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Market } from '../../models/Market';
import { StrongMarket } from '../../models/StrongMarket';
import { UNCommtrade } from '../../models/UNCommtrade';

export const marketsApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api' }),
    reducerPath: 'marketsApi',
    endpoints: (builder) => ({
        getMarkets: builder.query<Market[], {}>({
            query: () => '/markets',
            transformResponse: (response: any) => {
                let data: Market[] = response.value.map((r) => ({
                    Name: r.Name,
                    Iso3lCode: r.Iso3lCode,
                    Iso2lCode: r.Iso2lCode,
                }));
                return data;
            },
        }),

        getAllStrongMarkets: builder.query<StrongMarket[], {}>({
            query: () => '/strongmarkets',
            transformResponse: (response: any) => {
                if (response.value.length === 0) {
                    return null;
                }
                return response.value;
            },
        }),

        getStrongMarketsByHSCode: builder.query<StrongMarket[], string>({
            query: (hsCode: string) => `/strongmarkets?$orderby=Rank&$filter=(Code eq '${hsCode}')`,
            transformResponse: (response: any) => {
                if (response.value.length === 0) {
                    return null;
                }
                return response.value;
            },
        }),

        getMicorOnlyMarkets: builder.query<Market[], {}>({
            query: () => '/markets/micorOnlyMarkets',
            transformResponse: (response: Market[]) => response,
        }),

        getImportingDocumentMarketsForHsCode: builder.query<Market[], string>({
            query: (hsCode) => `/markets/hscode/${hsCode}`,
            transformResponse: (response: Market[]) => response,
        }),

        getTariffMarkets: builder.query<Market[], {}>({
            query: () => '/markets/mv/tariffs',
            transformResponse: (response: Market[]) => response,
        }),

        getUnCommTradeData: builder.query<UNCommtrade, { marketCode: string; hsCode: string }>({
            query: ({ marketCode, hsCode }) => `/UNCommtrades?$filter=(CountryCode eq '${marketCode}') and (HSCode eq '${hsCode}')`,
            transformResponse: (response: any) => {
                if (response.value.length === 0) {
                    return null;
                }
                return response.value[0];
            },
        }),
    }),
});

export const {
    useGetMarketsQuery,
    useGetAllStrongMarketsQuery,
    useGetStrongMarketsByHSCodeQuery,
    useGetMicorOnlyMarketsQuery,
    useGetImportingDocumentMarketsForHsCodeQuery,
    useGetTariffMarketsQuery,
    useGetUnCommTradeDataQuery,
} = marketsApi;

export default marketsApi.reducer;
