import React from 'react';
import PageBody from '../pageBody/PageBody';
import Layout from '../../site/Layout';
import ErrorPageHeader from '../pageHeader/ErrorPageHeader';
import { Container } from 'reactstrap';

const Forbidden = () => {
    return (
        <Layout>
            <ErrorPageHeader data={{ title: 'Forbidden' }} />
            <PageBody>
                <Container>
                    <p>Sorry, but you do not have permission to access this resource.</p>
                </Container>
            </PageBody>
        </Layout>
    );
};

export default Forbidden;
