import React from 'react';
import { Col, Container } from 'reactstrap';
import './ErrorPageHeader.scss';
import WarningRed from '../../assets/warningRed.svg';

interface ErrorPageHeaderProps {
    data: {
        title: string;
    };
}

const ErrorPageHeader = (props: ErrorPageHeaderProps) => {
    const getStandardH1 = (showAria: boolean, testId: string) => (
        <h1 id="pageTitle" aria-hidden={!showAria} data-testid={testId}>
            {props.data.title}
        </h1>
    );

    return (
        <Container fluid className={'error-page-header page-header-red-theme'} id="page-header">
            <Container>
                <div className="content-header p-5">
                    <Col xl={8} md={10} sm={12}>
                        <div className="title-wrapper">
                            <div className="img-container mt-2 me-3 mb-4 mb-sm-0">
                                <img src={WarningRed} alt="" />
                            </div>
                            {getStandardH1(true, 'displayable-h1')}
                        </div>
                    </Col>
                </div>
            </Container>
        </Container>
    );
};
export default ErrorPageHeader;
