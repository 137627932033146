import { Elements } from '@kontent-ai/delivery-sdk';
import React from 'react';

interface HeadingLevelResolverProps {
    data: {
        headingLevel: Elements.MultipleChoiceElement;
        titleText: any;
    };
}

const render = (headingLevelCodeName: string, titleText: any) => {
    switch (headingLevelCodeName) {
        case 'h1':
            return <h1>{titleText}</h1>;
        case 'h2':
            return <h2>{titleText}</h2>;
        case 'h4':
            return <h4>{titleText}</h4>;
        case 'h5':
            return <h5>{titleText}</h5>;
        default:
            return <h3>{titleText}</h3>;
    }
};

const HeadingLevelResolver = (props: HeadingLevelResolverProps) => {
    const headingLevelCodeName = props.data.headingLevel?.value?.length > 0 ? props.data.headingLevel?.value[0].codename : '';
    return render(headingLevelCodeName, props.data.titleText);
};

export default HeadingLevelResolver;
