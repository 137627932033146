import { useCallback, useEffect, useState } from 'react';
import { replaceAll } from '../utils/utils';

export type GetUrlIndustryAndSector = {
    isSupplied: boolean;
    isProcessed: boolean;
    industryCode: string;
    sectorCode: string;
};

const useGetUrlIndustryAndSector = (): GetUrlIndustryAndSector => {
    const [isSupplied, setIsSupplied] = useState<boolean>(false);
    const [isProcessed, setIsProcessed] = useState<boolean>(false);
    const [industryCode, setIndustryCode] = useState<string>('');
    const [sectorCode, setSectorCode] = useState<string>('');

    const params = new URLSearchParams(window.location.search);
    const industryParam = params.get('industry');
    const sectorParam = params.get('sector');

    const parseIndustrySectorParam = useCallback((val): string => {
        return replaceAll(val, '-', '_');
    }, []);

    useEffect(() => {
        if (window.location.href.indexOf('?') === -1) {
            setIsSupplied(false);
            setIsProcessed(true);
            return;
        }

        if (!industryParam || !sectorParam) {
            setIsSupplied(false);
            setIsProcessed(true);
            return;
        }

        const tempIndustryCode = parseIndustrySectorParam(industryParam);
        const tempSectorCode = parseIndustrySectorParam(sectorParam);

        setIsSupplied(true);
        setIndustryCode(tempIndustryCode);
        setSectorCode(tempSectorCode);
        setIsProcessed(true);
    }, [parseIndustrySectorParam]);

    return { isSupplied, isProcessed, industryCode, sectorCode };
};

export default useGetUrlIndustryAndSector;
