import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import './RulesAndRestrictionsDocument.scss';
import { useGetMvImportDocumentDataQuery } from '../../../providers/reducers/mvLawsAndRegsApi';
import { ElementType } from 'domelementtype';

interface RulesAndRestrictionsDocumentProps {
    match: any;
}

const RulesAndRestrictionsDocument = (props: RulesAndRestrictionsDocumentProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showComponent, setShowComponent] = useState<boolean>(false);
    const { data, isSuccess } = useGetMvImportDocumentDataQuery(
        { sector: props.match.params.sector, market: props.match.params.market, documentId: props.match.params.document },
        { skip: !props.match.params.sector || !props.match.params.market || !props.match.params.document },
    );
    const [lastUpdated, setLastUpdated] = useState<String>('');
    const mendelVerlagBaseUrl = '/api/mv/resources/images/';

    useEffect(() => {
        if (data && isSuccess) {
            setLoading(false);
            setShowComponent(true);
            const formattedDate = data.LastUpdated.toLocaleDateString('en-AU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            setLastUpdated(formattedDate.toString());
        }
    }, [data, isSuccess]);

    const replaceDocBase = (domNode) => {
        if (domNode.type === ElementType.Tag) {
            const element = domNode as Element;
            if (element.tagName === 'img') {
                return (
                    <img
                        {...domNode.attribs}
                        alt={`example of ${domNode.attribs['alt']}`}
                        src={`${mendelVerlagBaseUrl}?src=${encodeURIComponent(domNode.attribs['src'])}`}
                    />
                );
            }
        }
    };

    const replace = (domNode) => {
        const base = replaceDocBase(domNode);
        if (base) {
            return base;
        }
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            {showComponent && (
                <>
                    <div className="mv-doc mb-3">{parse(data.Document, { replace: replace })}</div>
                    <p>
                        Source: <a href="https://www.mendel-verlag.de/en/">Mendel</a>, updated on {lastUpdated}
                    </p>
                </>
            )}
        </LoadingOverlay>
    );
};

export default RulesAndRestrictionsDocument;
