import { MvProductInfo, MvTaxesAndDutiesInfo } from '../../models/ReduxModels';
import GlobalState, { MVLawsAndRegsState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MvTaxesAndDuties } from '../../models/MvTaxesAndDuties';

const initState: MVLawsAndRegsState = {
    mvTaxesAndDutiesInfo: { retrieved: false, pending: false },
    mvProductInfo: { retrieved: false, pending: false },
    mvStandardSector: undefined,
};

const mvLawsAndRegsSlice = createSlice({
    name: 'mvLawsAndRegs',
    initialState: initState,
    reducers: {
        pendingMVProductInfo(state) {
            state.mvProductInfo.pending = true;
        },
        pendingMvTaxesAndDutiesInfo(state) {
            state.mvTaxesAndDutiesInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMvTaxesAndDutiesInfo.fulfilled, (state, action) => {
            state.mvTaxesAndDutiesInfo = action.payload as MvTaxesAndDutiesInfo;
        });

        builder.addCase(getMVProductInfo.fulfilled, (state, action) => {
            state.mvProductInfo = action.payload as MvProductInfo;
        });

        builder.addCase(setStandardSector.fulfilled, (state, action) => {
            state.mvStandardSector = action.payload as string;
        });
    },
});

export const getMVProductInfo = createAsyncThunk(
    'mvLawsAndRegs/getMVProductInfo',
    async (request: { sector: string; market: string }, thunkAPI: any) => {
        const state = thunkAPI.getState() as GlobalState;
        if (state.mvLawsAndRegs.mvProductInfo.retrieved || state.mvLawsAndRegs.mvProductInfo.pending) {
            return thunkAPI.rejectWithValue(true);
        }

        thunkAPI.dispatch(mvLawsAndRegsSlice.actions.pendingMVProductInfo());

        return await new Promise<MvProductInfo>((resolve) => {
            (thunkAPI.getState() as GlobalState).singletonServices.mvLawsAndRegsService
                .getProduct(request.sector, request.market)
                .then((resp) => {
                    resolve({ retrieved: true, pending: false, data: resp });
                })
                .catch(() => {
                    resolve({ retrieved: true, pending: false });
                });
        });
    },
);

export const getMvTaxesAndDutiesInfo = createAsyncThunk(
    'mvLawsAndRegs/getMVTaxesAndDutiesInfo',
    async (request: { commodity: string; market: string }, thunkAPI: any) => {
        const state = thunkAPI.getState() as GlobalState;
        if (state.mvLawsAndRegs.mvTaxesAndDutiesInfo.retrieved || state.mvLawsAndRegs.mvTaxesAndDutiesInfo.pending) {
            return thunkAPI.rejectWithValue(true);
        }

        thunkAPI.dispatch(mvLawsAndRegsSlice.actions.pendingMvTaxesAndDutiesInfo());

        return await new Promise<MvTaxesAndDutiesInfo>((resolve) => {
            (thunkAPI.getState() as GlobalState).singletonServices.mvLawsAndRegsService
                .getTaxesAndDutiesData(request.commodity, request.market)
                .then((resp) => {
                    resolve({ retrieved: true, pending: false, data: resp });
                })
                .catch(() => {
                    resolve({ retrieved: true, pending: false });
                });
        });
    },
);

export const setStandardSector = createAsyncThunk(
    'mvLawsAndRegs/setStandardSector',
    async (request: { sector: string; mvTaxesAndDuties: MvTaxesAndDuties }, thunkAPI: any) => {
        const new_ = (thunkAPI.getState() as GlobalState).singletonServices.mvLawsAndRegsService.getStandardNTLCCode(
            request.sector,
            request.mvTaxesAndDuties.BaseLevel,
        );

        return await new Promise<string>((resolve, reject) => {
            resolve(new_);
        });
    },
);

const mvLawsAndRegsReducer = mvLawsAndRegsSlice.reducer;

export default mvLawsAndRegsReducer;
