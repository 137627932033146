import { OnLoadPersonalisationInfo } from '../../models/ReduxModels';

export type QuestionAnswer = { i: number; answer: string };

export const differenceBetweenQuizQuestionLists = (lhs: any[], rhs: any[]) => {
    if (lhs.length !== rhs.length) {
        return true;
    }

    let foundDifference = false;
    lhs.forEach((q, i) => {
        if (foundDifference) {
            return;
        }

        if (q.id !== rhs[i].id) {
            foundDifference = true;
        }

        if (q.answer !== rhs[i].answer) {
            foundDifference = true;
        }
    });

    return foundDifference;
};

export const parseQuestionAnswers = (onLoadPersonalisation: OnLoadPersonalisationInfo): QuestionAnswer[] => {
    const url = new URL(onLoadPersonalisation.url);
    if (!url.search) {
        return null;
    }
    const matchObject = url.search.matchAll(/q\d{1,2}=(yes|notsure|no)/gi);
    return Array.from(matchObject, (m, i) => ({ i: i + 1, answer: m[1] }) as QuestionAnswer);
};
