export class CommodityService {
    public getCommodities = (queryString: string): Promise<any> => {
        return new Promise<string>((resolve, reject) => {
            fetch('/api/Commodities' + queryString)
                .then((response) => response.json())
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => reject(err));
        });
    };

    public getCommoditiesWithStrongMarkets = (queryString: string): Promise<any> => {
        return new Promise<string>((resolve, reject) => {
            fetch('/api/Commodities/Strongmarkets' + queryString)
                .then((response) => response.json())
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => reject(err));
        });
    };
}
