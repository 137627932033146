import React, { useEffect, useRef, useState } from 'react';
import { FilterItem, PageLayoutType, PaginationLinks, PathwayList } from '@exporter-services/common-ui';
import '../../styles/_filterPages.scss';
import { Col, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import FilterToolsAndTemplatesPanel, { ToolsAndTemplatesFilterCriteria } from './FilterToolsAndTemplatesPanel';
import { FilteredResultsContainerItem } from '../../controls/filteredResultsContainer/FilteredResultsContainerItem';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { client } from '../../config';
import _ from 'lodash';
import { ToolsAndTemplatesItem } from './ToolsAndTemplatesItem';
import Pluralize from 'pluralize';
import { ToolsAndTemplatesUtils } from './ToolsAndTemplatesUtils';
import { CommonConstants } from '../../CommonConstants';
import { scrollTo } from '../../utils/scrollUtils';

export interface FilteredToolsAndTemplatesResultsContainerProps {
    data: {
        item: FilteredResultsContainerItem;
        linkedItems?: IContentItemsContainer;
    };
}

const FilteredToolsAndTemplatesResultsContainer = (props: FilteredToolsAndTemplatesResultsContainerProps) => {
    const [allData, setAllData] = useState<ToolsAndTemplatesItem[]>([]);
    const [filteredData, setFilteredData] = useState<ToolsAndTemplatesItem[]>(null);
    const [currentPageData, setCurrentPageData] = useState<ToolsAndTemplatesItem[]>(null);

    const pageSize = CommonConstants.PAGE_SIZE;
    const [filteredCount, setFilteredCount] = useState<number>(0);

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [itemTypesFilterItems, setItemTypesFilterItems] = useState<FilterItem[]>(null);

    const [loading, setLoading] = useState<boolean>(true);
    const refResultCountElement = useRef(null);
    const resultsContainerRef = useRef<HTMLDivElement>(null);

    //#region Get data

    const getToolsAndTemplatesPathwayListData = () => {
        if (props.data.item?.elements?.linked_items) {
            const pathwayList = props.data.item.elements.linked_items['linkedItems'].find((t) => t.system.type === 'pathway_list');
            return pathwayList;
        }
        return null;
    };
    const toolsAndTemplatesPathwayListData = getToolsAndTemplatesPathwayListData();

    useEffect(() => {
        const data = toolsAndTemplatesPathwayListData?.elements?.cards['linkedItems'] as ToolsAndTemplatesItem[];

        if (!data) {
            setLoading(false);
            throw new Error('Unable to retrieve tools and templates data.');
        }

        if (data) {
            setAllData(data);
            setFilteredData(data);

            let tempTotalCount = data.length;
            setPaginationParameters(tempTotalCount);
            setTotalPages(Math.ceil(tempTotalCount / pageSize));

            //set first page results
            let pagedItems = _.drop(data, 0).slice(0, pageSize);
            setCurrentPageData(pagedItems);
            setCurrentPage(1);

            setLoading(false);
        }
    }, [toolsAndTemplatesPathwayListData]);

    useEffect(() => {
        if (allData?.length > 0) {
            const itemTypes = allData.map((t) => ToolsAndTemplatesUtils.getItemType(t));
            const groupedItemTypes = _.groupBy(itemTypes, (type) => type.code);

            let filterItems: FilterItem[] = [];
            _.forEach(groupedItemTypes, (g) => {
                filterItems.push({
                    value: g[0].code,
                    label: Pluralize(g[0].name),
                    count: g.length,
                } as FilterItem);
            });
            const sortedFilterItems = filterItems.sort((a, b) => (a.label < b.label ? -1 : 1));
            setItemTypesFilterItems(sortedFilterItems);
        } else {
            setItemTypesFilterItems([]);
        }
    }, [allData]);

    const setPaginationParameters = (resultsCount: number) => {
        setFilteredCount(resultsCount);
        setTotalPages(Math.ceil(resultsCount / pageSize));
    };

    //#endregion

    // #region GetData for the page
    const getDataForPage = (pageNumber: number) => {
        if (!filteredData) return;

        let offset = (pageNumber - 1) * pageSize;
        let pagedData = _.drop(filteredData, offset).slice(0, pageSize);

        setCurrentPageData(pagedData);
        setCurrentPage(pageNumber);
    };

    const scrollToPageTop = () => {
        scrollTo(refResultCountElement);
        if (resultsContainerRef?.current) resultsContainerRef.current.focus();
    };

    const onFirstPageClick = () => {
        getDataForPage(1);
        scrollToPageTop();
    };

    const onPreviousPageClick = () => {
        if (currentPage > 1) {
            getDataForPage(currentPage - 1);
        }
        scrollToPageTop();
    };

    const onNextPageClick = () => {
        if (currentPage < totalPages) {
            getDataForPage(currentPage + 1);
        }
        scrollToPageTop();
    };

    const onLastPageClick = () => {
        getDataForPage(totalPages);
        scrollToPageTop();
    };

    function onPageNumberClick(pageNumber: number) {
        getDataForPage(pageNumber);
        scrollToPageTop();
    }

    //#endregion

    //#region Apply Filters
    const onFilterChange = (filterCriteria: ToolsAndTemplatesFilterCriteria) => {
        let tempFilteredList = ToolsAndTemplatesUtils.applyTypesFilter(allData, filterCriteria.itemTypes);

        setFilteredData([...tempFilteredList]);
        setFilteredCount(tempFilteredList.length);

        //set results to 1st page of pagination
        let pagedItems = _.drop(tempFilteredList, 0).slice(0, pageSize);
        setCurrentPageData(pagedItems);
        setCurrentPage(1);

        setPaginationParameters(tempFilteredList.length);
    };

    //#endregion

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="" className="loader">
            <Row>
                <Col>
                    {filteredCount > 0 ? (
                        <h2 className="results-count-header" ref={refResultCountElement}>
                            Showing <strong>{filteredCount}</strong> {filteredCount === 1 ? 'tool or template' : 'tools and templates'}
                        </h2>
                    ) : (
                        <h2 className="results-count-header">No tools and templates to show</h2>
                    )}
                </Col>
            </Row>
            <Row className="filtered-results-container">
                <Col md="4">
                    <FilterToolsAndTemplatesPanel
                        data={{
                            itemTypesFilterItems: itemTypesFilterItems,
                        }}
                        onChange={onFilterChange}
                    />
                </Col>
                <Col md="8">
                    {currentPageData && (
                        <div ref={resultsContainerRef} tabIndex={-1}>
                            <PathwayList
                                data={{
                                    cardItems: currentPageData,
                                    title: toolsAndTemplatesPathwayListData?.elements?.title?.value,
                                    title_tag: toolsAndTemplatesPathwayListData?.elements?.title_tag,
                                }}
                                pageLayoutType={PageLayoutType.TwoColumnPage}
                                client={client}
                            />
                        </div>
                    )}

                    <div className="pagination-links-container" data-testid={'pagination'}>
                        {totalPages > 0 && (
                            <PaginationLinks
                                data={{
                                    totalPages: totalPages,
                                    currentPage: currentPage,
                                    pageSize: pageSize,
                                    onPageNumberClick: onPageNumberClick,
                                    onFirstPageClick: onFirstPageClick,
                                    onLastPageClick: onLastPageClick,
                                    onNextPageClick: onNextPageClick,
                                    onPreviousPageClick: onPreviousPageClick,
                                }}
                            />
                        )}
                    </div>
                </Col>
            </Row>
        </LoadingOverlay>
    );
};

export default FilteredToolsAndTemplatesResultsContainer;
