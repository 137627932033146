import { PersonalisationStateInterface } from '../providers/personalisation/types';
import { OnLoadPersonalisationInfo } from '../models/ReduxModels';
import { parseQuestionAnswers } from '../domains/quiz/QuizQuestionsContainer.utils';
import { replaceAll } from './utils';
import { CommonConstants } from '../CommonConstants';

export const generateServiceQueryString = (industry: string, sector: string) => `?industry=${industry}&sector=${sector}`;

export const generatePersonalisationClearedQueryString = () => '';

export const parseIndustrySectorParam = (val): string => {
    if (!val) return undefined;

    return replaceAll(val, '-', '_').toLowerCase();
};

export const generateServiceQuerystringByStoredPersonalisation = (personalisationState: PersonalisationStateInterface) => {
    return generateServiceQueryString(personalisationState.industryCode, personalisationState.sectorCode);
};

export const generateResultsQueryString = (personalisationState: PersonalisationStateInterface, questionAndResponseList: any[]) =>
    `${generateServiceQuerystringByStoredPersonalisation(personalisationState)}&results${questionAndResponseList
        .map((q, i) => `&q${i + 1}=${q.answer}`)
        .join('')}`;

export const isQuerystringContainingResults = (url: string) => url?.includes('&results');

export const isPersonalisationQuerystringContainingResults = (onLoadPersonalisation: OnLoadPersonalisationInfo) =>
    onLoadPersonalisation.isSet && isQuerystringContainingResults(onLoadPersonalisation.url);

export enum QuizAnswersStateType {
    NotApplicable,
    NoneOrSomeAnswered,
    AllAnswered,
}

type QuizAnswersState = {
    type: QuizAnswersStateType;
    earliestQuestionUnanswered?: {
        number: number;
        id: number;
    };
};

export const findEarliestQuizQuestionUnansweredFromQuerystring = (
    onLoadPersonalisation: OnLoadPersonalisationInfo,
    questionList: any[],
): QuizAnswersState => {
    if (!isPersonalisationQuerystringContainingResults(onLoadPersonalisation)) {
        return { type: QuizAnswersStateType.NotApplicable };
    }

    const answerList = parseQuestionAnswers(onLoadPersonalisation);

    if (answerList.length === 0) {
        return {
            type: QuizAnswersStateType.NoneOrSomeAnswered,
            earliestQuestionUnanswered: { number: 1, id: questionList[0].id },
        };
    }

    let earliestQuestionUnanswered: {
        number: number;
        id: number;
    } = null;

    questionList.forEach((question, i) => {
        if (earliestQuestionUnanswered) {
            return;
        }

        const matchingAnswer = answerList.find((answer) => answer.i === i + 1);
        if (!matchingAnswer || (matchingAnswer && !matchingAnswer.answer)) {
            earliestQuestionUnanswered = { number: i + 1, id: questionList[i].id };
        }
    });

    return {
        type: earliestQuestionUnanswered ? QuizAnswersStateType.NoneOrSomeAnswered : QuizAnswersStateType.AllAnswered,
        earliestQuestionUnanswered,
    };
};

export const validatePersonalisation = (personalisationState: PersonalisationStateInterface, industrySector: any) => {
    if (!personalisationState.isPersonalised) return true;
    if (!industrySector?.value?.length) return !personalisationState.sectorCode;

    for (let i = 0; i < industrySector?.value.length; i++) {
        let codeName = !industrySector ? CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME : industrySector?.value[i].codename;

        if (!personalisationState.sectorCode && codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) return true;
        if (
            personalisationState.sectorCode &&
            (codeName === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME || codeName === personalisationState.sectorCode)
        ) {
            return true;
        }
    }

    return false;
};
