import { LawsAndRegsDataSourceInfo } from '../../models/ReduxModels';
import GlobalState, { LawsAndRegsState } from '../globalState/globalState';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

export const initState: LawsAndRegsState = {
    dataSourceInfo: { retrieved: false },
};

const lawsAndRegsSlice = createSlice({
    name: 'lawsAndRegs',
    initialState: initState,
    reducers: {
        setDataSourceInfo(state: LawsAndRegsState, action: PayloadAction<LawsAndRegsDataSourceInfo>) {
            state.dataSourceInfo = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLawsAndRegsDataSource.fulfilled, (state, action) => {
            state.dataSourceInfo = action.payload as LawsAndRegsDataSourceInfo;
        });
    },
});

export const getLawsAndRegsDataSource = createAsyncThunk(
    'lawsAndRegs/getLawsAndRegsDataSource',
    async (request: { sector: string }, thunkAPI: any) => {
        if ((thunkAPI.getState() as GlobalState)?.lawsAndRegs.dataSourceInfo.retrieved) {
            return thunkAPI.rejectWithValue(true);
        }
        return await new Promise<LawsAndRegsDataSourceInfo>((resolve) => {
            (thunkAPI.getState() as GlobalState).singletonServices.lawsAndRegsService
                .getLawsAndRegsDataSource(request.sector)
                .then((resp) => {
                    resolve({ retrieved: true, data: resp });
                })
                .catch(() => {
                    resolve({ retrieved: true });
                });
        });
    },
);

const lawsAndRegsReducer = lawsAndRegsSlice.reducer;

export default lawsAndRegsReducer;
