import React from 'react';
import './EventDetails.scss';
import { formatDateShowDay } from '../../utils/utils';
import { ComButton, ContentItem } from '@exporter-services/common-ui';

interface EventDetailsProps {
    data: {
        item: ContentItem;
    };
}

const EventDetails = (props: EventDetailsProps) => {
    const handleEventBookingClick = () => {
        window.location.href = props.data.item.elements?.booking_link?.value;
    };

    const startDate = props.data.item.elements?.start_date?.value;
    const endDate = props.data.item.elements?.end_date?.value;

    return (
        <div className="event-details  mb-5">
            <div className="event-details-content p-4  h-100">
                <div className="item">
                    <div className="label">Date:</div>
                    <div>
                        {startDate && formatDateShowDay(startDate)}
                        {startDate && endDate && ` to ${formatDateShowDay(endDate)}`}
                    </div>
                </div>
                <div className="item">
                    <div className="label">Time:</div>
                    <div>{props.data.item.elements?.time?.value}</div>
                </div>
                <div className="item">
                    <div className="label">Location:</div>
                    <div>{props.data.item.elements?.location?.value}</div>
                </div>
                <div className="item">
                    <div className="label">Cost:</div>
                    <div>{props.data.item.elements?.cost?.value}</div>
                </div>
            </div>
            <div className="event-link p-4">
                <ComButton onClick={handleEventBookingClick}>Book</ComButton>
                <div className="text ps-md-4 pt-4 pt-md-0 semi-bold">Join the Go Global Toolkit to book</div>
            </div>
        </div>
    );
};

export default EventDetails;
