import React, { useEffect, useState } from 'react';
import './Notification.scss';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import parse, { Element } from 'html-react-parser';
import ResolveLink from '../resolveLink/ResolveLink';

interface NotificationProps {
    data: {
        item: any;
        linkedItems: IContentItemsContainer;
    };
    match?: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type?: string;
}

const Notification = (props: NotificationProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showContent, setShowContent] = useState<boolean>(true);
    const retrievedUrl = localStorage.getItem('url');

    useEffect(() => {
        if (props.data.item.elements.description.linkedItemCodenames.length === 0) {
            setLoading(false);
            setShowContent(true);
        }
    }, []);

    const handleLoadDone = (dataAvailable: boolean, title: string): void => {
        setLoading(false);
        setShowContent(dataAvailable);
        props.onLoad(dataAvailable, title);
    };

    const findEmbededObject = () => {
        let notificationState = true;
        parse(props.data.item.elements.description.value, {
            replace: function (domNode) {
                if ((domNode as Element).name !== 'a') {
                    return;
                }
                let pathname = (domNode as Element).attribs['href'];
                if (pathname === retrievedUrl) {
                    notificationState = false;
                }
            },
        });
        return notificationState;
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            {findEmbededObject() && showContent ? (
                <div className="information-notification">
                    <div className="information-notification-content">
                        <div className="information-icon"></div>
                        <div className="mini description no-space-under-last-child">
                            <ResolveLink
                                data={{
                                    item: props.data.item,
                                    linkedItems: props.data.linkedItems,
                                    match: props.match,
                                    onLoad: handleLoadDone,
                                }}
                                type={props.data.item.system.type || props.type}
                            />
                        </div>
                    </div>
                </div>
            ) : null}
        </LoadingOverlay>
    );
};

export default Notification;
