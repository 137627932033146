import React from 'react';
import { Container } from 'reactstrap';
import './UtilityGroup.scss';
import UtilityItem from '../utilityGroup/utilityItem/utilityItem';

const UtilityGroup = () => {
    const utilityGroup = [
        {
            title: 'Email',
            subject: document.title,
            icon: 'email',
            link: window.location.href,
        },
        {
            title: 'Print',
            icon: 'print',
            link: 'https://www.export.business.gov.au',
        },
    ];

    const renderUtilityGroup = () => {
        return utilityGroup.map((item: any, i) => {
            return <UtilityItem title={item.title} subject={item.subject} link={item.link} icon={item.icon} key={i} />;
        });
    };
    return (
        <Container>
            <div className={'utility-group d-flex flex-row justify-content-end d-print-none'}>{renderUtilityGroup()}</div>
        </Container>
    );
};

export default UtilityGroup;
