import React, { useEffect, useReducer, useState } from 'react';
import ResolveLink from '../../controls/resolveLink/ResolveLink';
import './QuizQuestion.scss';
import RadioButtonGroup from '../../controls/radioButtonGroup/RadioButtonGroup';

interface QuizQuestionProps {
    question: any;
    index: number;
    focusedValue: string;
    onAnswerClicked: (e) => void;
    onScrolltoTop: () => void;
}

const QuizQuestion = (props: QuizQuestionProps) => {
    const [showText, setShowText] = useState<any>(null);
    const [, forceUpdate] = useReducer((x) => x + 1, 0);
    const componentType = 'quizItem';

    useEffect(() => {
        resolveShowText(props.question.answer);
    }, [props.question]);

    const OptionText = () => {
        if (showText) {
            return (
                <div>
                    <ResolveLink
                        data={{
                            item: showText,
                            linkedItems: showText.linkedItems,
                        }}
                        type={componentType}
                    />
                </div>
            );
        } else {
            return <div dangerouslySetInnerHTML={{ __html: '' }}></div>;
        }
    };

    const resolveShowText = (answer: string) => {
        if (answer === 'yes') {
            let returnedYesText = (
                <ResolveLink
                    data={{
                        item: props.question.yes_message,
                        linkedItems: props.question.yes_message?.linkedItems,
                    }}
                    type={componentType}
                />
            );

            setShowText(returnedYesText.props.data.item);
        } else if (answer === 'no' || answer === 'notsure') {
            let returnedNoText = (
                <ResolveLink
                    data={{
                        item: props.question.no_message,
                        linkedItems: props.question.no_message?.linkedItems,
                    }}
                    type={componentType}
                />
            );
            setShowText(returnedNoText.props.data.item);
        } else {
            setShowText(null);
        }
    };

    const answerClickHandler = (e) => {
        resolveShowText(e.target.value);
        props.onAnswerClicked(e);
        forceUpdate();
        props.onScrolltoTop();
    };

    const getQuestionIndex = () => {
        return '.question-container .step .StepProgress-item.current:after {content: "' + props.index + '";}';
    };

    return (
        <div className="question-container">
            <div className="step row">
                <div className="col-12">
                    <div className="StepProgress">
                        <div className="StepProgress-item current mt-3">
                            <div className="row">
                                <div className="col-12 question">
                                    <h2>{props.question.question.value}</h2>
                                </div>
                            </div>
                            <div>
                                <RadioButtonGroup
                                    items={[
                                        { Id: `yes_${props.index + 1}`, Value: 'yes', Title: 'Yes' },
                                        { Id: `no_${props.index + 1}`, Value: 'no', Title: 'No' },
                                        { Id: `notsure_${props.index + 1}`, Value: 'notsure', Title: 'Not sure' },
                                    ]}
                                    legendAsLabel={false}
                                    selectedValue={props.question.answer}
                                    focusedValue={props.focusedValue}
                                    name={`looking_export_${props.index + 1}`}
                                    label={'Ready to export quiz option'}
                                    onChangeHandler={(e) => answerClickHandler(e)}
                                />
                                <OptionText />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <style dangerouslySetInnerHTML={{ __html: getQuestionIndex() }}></style>
        </div>
    );
};

export default QuizQuestion;
