import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import { useAppSelector } from '../../hooks/reduxHooks';
import { UserGeolocationInfo } from '../../models/ReduxModels';
import ResolveLink from '../../controls/resolveLink/ResolveLink';

interface RulesAndRestrictionsDocumentsOffshoreProps {
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}
const RulesAndRestrictionsDocumentsOffshore = (props: RulesAndRestrictionsDocumentsOffshoreProps) => {
    const geolocationInfo = useAppSelector<UserGeolocationInfo>((state) => state.user.geolocationInfo);
    const [loading, setLoading] = useState<boolean>(true);
    const [showComponent] = useState<boolean>(true);

    useEffect(() => {
        if (geolocationInfo.retrieved) {
            setLoading(false);
        }
    }, [geolocationInfo.retrieved]);

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            {showComponent && (
                <ResolveLink
                    data={{
                        item: props.data.item,
                        linkedItems: props.data.linkedItems,
                        match: props.match,
                    }}
                />
            )}
        </LoadingOverlay>
    );
};
export default RulesAndRestrictionsDocumentsOffshore;
