import React, { useState, BaseSyntheticEvent } from 'react';
import {
    FilterClear,
    FilterNestedCheckboxPanel,
    NestedFilterItemInteraction,
    NestedFilterItem,
    FilterItem,
    FilterItemInteraction,
    FilterCheckboxPanel,
    FilterCheckbox,
} from '@exporter-services/common-ui/src/components/shared/filterControls';
import '../../styles/_filterPages.scss';
import { CommonConstants } from '../../CommonConstants';

export interface MarketsFilterCriteria {
    regionCriteriaList?: { [id: string]: boolean };
    industrySectorCriteriaList?: { [id: string]: boolean };
    filterByTradeAgreements: boolean;
    marketFilter: string;
}

export interface FilterMarketsMainPanelProps {
    data?: {
        sectorSubsectorList?: NestedFilterItem[];
        regionList?: FilterItem[];
        tradeAgreementCount: number;
    };
    onChange?: (filterCriteria: MarketsFilterCriteria) => void;
}

const FilterEventsPanel = (props: FilterMarketsMainPanelProps) => {
    const FILTER_DEFAULT_STATE: MarketsFilterCriteria = {
        industrySectorCriteriaList: {},
        regionCriteriaList: {},
        filterByTradeAgreements: false,
        marketFilter: '',
    };

    let [filterCriteria, setFilterCriteria] = useState<MarketsFilterCriteria>(FILTER_DEFAULT_STATE);
    const [textFilter, setTextFilter] = useState('');
    const [clearState, setClearState] = useState<boolean>(false);
    const [filterByTradeAgreements, setFilterByTradeAgreements] = useState(false);

    const handleOnClearFilter = () => {
        setClearState(true);
        setFilterByTradeAgreements(false);
        setTextFilter('');

        let newState = Object.assign({}, FILTER_DEFAULT_STATE);

        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    const handleMarketFilterChange = (e: BaseSyntheticEvent) => {
        const newState = Object.assign({}, { ...filterCriteria, changeTriggerMarketFilter: true, marketFilter: e.target.value });
        props.onChange?.(newState);

        setFilterCriteria(newState);
        setClearState(false);
        setTextFilter(e.target.value);
    };

    const handleTradeAgreementsChange = (item: FilterItemInteraction) => {
        const newState = Object.assign({}, { ...filterCriteria, filterByTradeAgreements: item.selected });
        props.onChange?.(newState);

        setFilterCriteria(newState);
        setClearState(false);
        setFilterByTradeAgreements(!filterByTradeAgreements);
    };

    const handleRegionListPanelChange = (item: FilterItemInteraction) => {
        setClearState(false);

        let newState = Object.assign({}, { ...filterCriteria });
        newState.regionCriteriaList[item.value] = item.selected;

        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    const handleSectorSubsectorListPanelChange = (items: NestedFilterItemInteraction[]) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);
        items.forEach((item) => {
            let key = item.childValue ? item.parentValue + ':' + item.childValue : item.parentValue;
            newState.industrySectorCriteriaList[key] = item.selected;
        });

        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    return (
        <div className="filter-main-panel">
            <hr className="filter-main-panel-separator" />
            <FilterCheckbox
                data={{
                    id: 'trade_agreements_filter',
                    value: 'markets_with_trade_agreements',
                    label: 'Markets with trade agreements',
                    count: props.data.tradeAgreementCount,
                    checked: filterByTradeAgreements,
                    clearState: clearState,
                }}
                onChange={handleTradeAgreementsChange}
            />

            {props.data.regionList?.length > 0 ? (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterCheckboxPanel
                        data={{
                            label: 'Region',
                            itemList: props.data.regionList,
                            clearState: clearState,
                        }}
                        onChange={handleRegionListPanelChange}
                    />
                </>
            ) : null}

            {props.data.sectorSubsectorList?.length > 0 ? (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterNestedCheckboxPanel
                        data={{
                            label: 'Industry',
                            nestedFilterItems: props.data.sectorSubsectorList,
                            clearState: clearState,
                        }}
                        onChange={handleSectorSubsectorListPanelChange}
                    />
                </>
            ) : null}

            <hr className="filter-main-panel-separator" />
            <div className="market-explorer-box-form-field-container">
                <label className="field-label" htmlFor="market-input">
                    Enter a market name
                </label>
                <input
                    id="market-input"
                    className="market-explorer-box-form-field"
                    name="form-input-example"
                    type="text"
                    value={textFilter}
                    onChange={handleMarketFilterChange}
                    aria-label="Enter a market name"
                />
            </div>

            <hr className="filter-main-panel-separator" />
            <FilterClear data={{ label: CommonConstants.CLEAR_FILTERS_LABEL }} onClick={handleOnClearFilter} />
        </div>
    );
};

export default FilterEventsPanel;
