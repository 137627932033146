import React from 'react';
import { PageItem } from '../../models/PageItem';
import SiteSearchResultsHeader from './SiteSearchResultsHeader';
import LarPageHeader from './LarPageHeader';
import NewsOrEventsPageHeader from './NewsOrEventsPageHeader';
import PageHeader from './PageHeader';
import { PageName } from '../../models/PageName';
import { useAppSelector } from '../../hooks/reduxHooks';
import { LawsAndRegsPageService } from '../../services/LawsAndRegsPageService';
import { NewsFilterService } from '../../services/NewsFilterService';

interface PageHeaderResolverProps {
    content: PageItem;
    lastSlug?: any;
    params?: any;
}

const PageHeaderResolver = ({ content, lastSlug, params }: PageHeaderResolverProps) => {
    const lawsAndRegsService = useAppSelector<LawsAndRegsPageService>((state) => state.singletonServices.lawsAndRegsService);
    const newsFilterService = useAppSelector<NewsFilterService>((state) => state.singletonServices.newsFilterService);
    const lastUpdatedAt = content?.elements.last_updated?.value ? new Date(content.elements.last_updated.value) : null;

    const isSiteSearchResultsPage = lastSlug === PageName.SiteSearchResults;

    const isLarPage =
        lastSlug === PageName.Tariffs ||
        lawsAndRegsService.isExportRulesRequirementsPage(lastSlug) ||
        lawsAndRegsService.isResultsRulesAndRestrictionsDocumentPage({ params });

    const isNewsOrEventsPage = newsFilterService.isNewsOrEventsPage(content.system.type);

    const getEventDates = (): [string?, string?] => {
        const event = content?.elements?.content?.['linkedItems']?.find((item) => item.system.type === 'event_details');
        if (event) {
            return [event.elements.start_date?.value, event.elements.end_date?.value];
        }
        return [];
    };

    return isSiteSearchResultsPage ? (
        <SiteSearchResultsHeader
            data={{
                title: content.elements.title.value,
                title_tag: content.elements.common_page_info__title_tag.value,
                description: content.elements.abstract.value,
                metaDescription: content.elements.common_page_info__meta_description_tag.value,
            }}
        />
    ) : isLarPage ? (
        <LarPageHeader
            data={{
                label: content.elements.label.value,
                title: content.elements.title.value,
                title_tag: content.elements.common_page_info__title_tag.value,
                titleNumber: content.elements.title_number,
                description: content.elements.abstract.value,
                metaDescription: content.elements.common_page_info__meta_description_tag.value,
                image_or_video: content.elements.image,
                type: content.system.type,
                lastUpdated_at: lastUpdatedAt,
                urlSlug: content.elements.url,
                backgroundColorTheme: content.elements.page_header_color_theme__header_color,
            }}
            match={{ params: params }}
        />
    ) : isNewsOrEventsPage ? (
        <NewsOrEventsPageHeader
            data={{
                label: content.elements.label.value,
                title: content.elements.title.value,
                title_tag: content.elements.common_page_info__title_tag.value,
                titleNumber: content.elements.title_number,
                description: content.elements.abstract.value,
                metaDescription: content.elements.common_page_info__meta_description_tag.value,
                image_or_video: content.elements.image_or_video || content.elements.image,
                type: content.system.type,
                lastUpdated_at: lastUpdatedAt as Date,
                urlSlug: content.elements.url,
                backgroundColorTheme: content.elements.page_header_color_theme__header_color,
                eventDates: getEventDates(),
            }}
        />
    ) : (
        <PageHeader
            data={{
                label: content.elements.label.value,
                title: content.elements.title.value,
                title_tag: content.elements.common_page_info__title_tag.value,
                titleNumber: content.elements.title_number,
                description: content.elements.abstract.value,
                metaDescription: content.elements.common_page_info__meta_description_tag.value,
                image_or_video: content.elements.image,
                type: content.system.type,
                lastUpdated_at: lastUpdatedAt,
                marketType: content.elements.market_type,
                urlSlug: content.elements.url,
                backgroundColorTheme: content.elements.page_header_color_theme__header_color,
            }}
            match={{ params: params }}
        />
    );
};

export default PageHeaderResolver;
