import React, { useEffect, useState } from 'react';
import { FilterHeader } from './FilterHeader';
import { FilterCheckbox } from './FilterCheckbox';
import { FilterItem } from './FilterItem';
import { FilterItemInteraction } from './FilerItemInteraction';
import { ComMultiSelectUIItem } from '../../generic/formControls/ComMultiSelectUIItem';
import './FilterCheckboxPanel.scss';

export interface FilterCheckboxPanelProps {
    data?: {
        id?: string;
        label?: string;
        itemList?: FilterItem[];
        clearState: boolean;
    };
    onChange?: Function;
}

export const FilterCheckboxPanel = (props: FilterCheckboxPanelProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<Array<FilterItemInteraction>>([]);

    useEffect(() => {
        if (props.data.clearState) {
            setSelectedCheckboxes([]);
        }
    }, [props.data.clearState]);

    const headerOnClick = (open: boolean) => {
        setOpen(open);
    };

    const onChange = (item: FilterItemInteraction) => {
        if (item.selected) {
            setSelectedCheckboxes([...selectedCheckboxes, item]);
        } else {
            removeItem(item);
        }
        if (props.onChange) {
            props.onChange(item);
        }
    };
    const removeItem = (item: FilterItemInteraction) => {
        let changedList = selectedCheckboxes.filter((itemValue: FilterItemInteraction) => itemValue.value !== item.value);
        setSelectedCheckboxes([...changedList]);
    };

    const comMultiSelectUIItemRemove = (itemValue: string, itemText: string) => {
        let changedList = selectedCheckboxes.filter((item: FilterItemInteraction) => item.value !== itemValue);
        setSelectedCheckboxes([...changedList]);

        if (props.onChange) {
            props.onChange({ value: itemValue, selected: false });
        }
    };

    const shouldCheckboxBeChecked = (item: FilterItem): boolean => {
        let selectedCheckbox = selectedCheckboxes.filter((x) => x.value === item.value)[0];
        return selectedCheckbox?.selected;
    };

    return (
        <div className="filter-checkbox-panel">
            <FilterHeader data={{ label: props.data.label, open: open }} onClick={headerOnClick} />
            <div className="filter-checkbox-selection">
                {selectedCheckboxes?.map((item: FilterItemInteraction) => (
                    <ComMultiSelectUIItem
                        key={`check-item-${item.value}`}
                        data={{ value: item.value, text: item.text }}
                        removeItemHandler={comMultiSelectUIItemRemove}
                    />
                ))}
            </div>
            <div className={open ? 'filter-checkbox-expanded' : 'filter-checkbox-collapsed'}>
                {props.data.itemList?.map((item: FilterItem, index) => (
                    <FilterCheckbox
                        key={index}
                        data={{
                            id: item.value,
                            value: item.value,
                            label: item.label,
                            count: item.count,
                            checked: shouldCheckboxBeChecked(item),
                            clearState: props.data.clearState,
                        }}
                        onChange={onChange}
                    />
                ))}
            </div>
        </div>
    );
};
