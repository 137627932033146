import { DOMNode } from 'html-react-parser';

export class NodeDetails {
    public constructor(domNode: DOMNode, depth: number, index: number) {
        this.Depth = depth;
        this.DomNode = domNode;
        this.Index = index;
    }
    public Depth: number;
    public DomNode: DOMNode;
    public Index: number;
}
