import React from 'react';
import { ImageItem } from './ImageItem';
import './Image.scss';
import BaseImage from '../baseImage/BaseImage';

interface ImageProps {
    data: {
        item: ImageItem;
    };
}

const Image = (props: ImageProps) => {
    return (
        <div className="image-container">
            <BaseImage
                role="img"
                src={props.data.item.elements.image.value[0].url}
                alt={props.data.item.elements.image.value[0].description}
                className="responsive-image"
            />
            {props.data.item.elements.caption.value && (
                <div className="caption" data-testid="image-caption">
                    <p className="mini">{props.data.item.elements.caption.value}</p>
                </div>
            )}
        </div>
    );
};

export default Image;
