import { KenticoPageCategories } from '../../models/ReduxModels';
import GlobalState, { TaxonomyState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initState: TaxonomyState = {
    sectorTaxonomyGroup: {
        data: null,
        retrieved: false,
        pending: false,
    },
    regionMarketMapping: {
        data: null,
        retrieved: false,
        pending: false,
    },
    pageCategories: { data: [], retrieved: false, pending: false },
};

const taxonomySlice = createSlice({
    name: 'taxonomy',
    initialState: initState,
    reducers: {
        pendingSectorTaxonomyGroup(state) {
            state.sectorTaxonomyGroup.pending = true;
        },
        pendingRegionMarketMapping(state) {
            state.regionMarketMapping.pending = true;
        },
        pendingPageCategories(state) {
            state.pageCategories.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getKenticoSectorTaxonomyGroup.fulfilled, (state, action) => {
            state.sectorTaxonomyGroup = action.payload;
        });
        builder.addCase(getKenticoRegionMarketMapping.fulfilled, (state, action) => {
            state.regionMarketMapping = action.payload;
        });
        builder.addCase(getKenticoPageCategories.fulfilled, (state, action) => {
            state.pageCategories = action.payload;
        });
    },
});

export const getKenticoSectorTaxonomyGroup = createAsyncThunk('taxonomy/getSectorTaxonomy', async (request: null, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;
    if (state.taxonomy.sectorTaxonomyGroup.retrieved || state.taxonomy.sectorTaxonomyGroup.pending) {
        return thunkAPI.rejectWithValue(true);
    }
    thunkAPI.dispatch(taxonomySlice.actions.pendingSectorTaxonomyGroup());
    return await new Promise<any>((resolve) => {
        state.singletonServices.taxonomyService
            .getSectorTaxonomyGroup()
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

export const getKenticoRegionMarketMapping = createAsyncThunk('taxonomy/getRegionMarketMapping', async (request: null, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;
    if (state.taxonomy.regionMarketMapping.retrieved || state.taxonomy.regionMarketMapping.pending) {
        return thunkAPI.rejectWithValue(true);
    }
    thunkAPI.dispatch(taxonomySlice.actions.pendingRegionMarketMapping());
    return await new Promise<any>((resolve) => {
        state.singletonServices.taxonomyService
            .getRegionMarketMapping()
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

export const getKenticoPageCategories = createAsyncThunk('taxonomy/getPageCategories', async (request: null, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;
    if (state.taxonomy.pageCategories.retrieved || state.taxonomy.pageCategories.pending) {
        return thunkAPI.rejectWithValue(true);
    }
    thunkAPI.dispatch(taxonomySlice.actions.pendingPageCategories());
    return await new Promise<KenticoPageCategories>((resolve) => {
        state.singletonServices.taxonomyService
            .getPageCategoryTaxonomy()
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

const taxonomyReducer = taxonomySlice.reducer;

export default taxonomyReducer;
