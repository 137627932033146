import React from 'react';
import { ContentItem } from '@exporter-services/common-ui';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import BodyCopy from './controls/bodyCopy/BodyCopy';
import { BodyCopyItem } from './controls/bodyCopy/BodyCopyItem';
import Stepper from './domains/quiz/Stepper';
import { StepperItem } from './domains/quiz/StepperItem';
import Quote from './controls/quote/Quote';
import EbgaPathwayList from './controls/pathwayList/EbgaPathwayList';
import { PathwayListItem } from '@exporter-services/common-ui';
import FeatureImagePathway from './controls/featureImagePathway/FeatureImagePathway';
import { FeatureImageItem } from './controls/featureImagePathway/FeatureImageItem';
import EventPathway from './controls/eventPathway/EventPathway';
import { EventPathwayItem } from './controls/eventPathway/EventPathwayItem';
import { DownloadItem } from './controls/download/DownloadItem';
import Download from './controls/download/Download';
import Notification from './controls/notification/Notification';
import SearchContainer from './controls/searchContainer/SearchContainer';
import { SearchContainerItem } from './controls/searchContainer/SearchContainerItem';
import ProTip from './controls/proTip/ProTip';
import { ProTipItem } from './controls/proTip/ProTipItem';
import ProsAndCons from './controls/prosAndCons/ProsAndCons';
import Disclaimer from './controls/disclaimer/Disclaimer';
import { DisclaimerItem } from './controls/disclaimer/DisclaimerItem';
import ResultsContainer from './controls/resultsContainer/ResultsContainer';
import InlineLink from './controls/inlineLink/InlineLink';
import FilteredMarketResults from './controls/filteredMarketResults/FilteredMarketResults';
import MarketProfile from './controls/marketProfile/MarketProfile';
import CrmForm from './controls/Crm/CrmForm';
import CrmFormPlaceholder from './controls/Crm/CrmFormPlaceholder';
import UNCommtrade from './controls/unCommtrade/UNCommtrade';
import TickBoxList from './controls/tickboxList/TickBoxList';
import CompetitiveEnvironment from './controls/competitveEnvironment/CompetitiveEnvironment';
import Video from './controls/video/Video';
import Opportunities from './controls/opportunities/Opportunities';
import Challenges from './controls/challenges/Challenges';
import Accordion from './controls/accordion/Accordion';
import { AccordionContainerContentItem } from './controls/accordion/AccordionContainerContentItem';
import AccordionGuidePathway from './controls/accordionGuidePathway/AccordionGuidePathway';
import { AccordionContainerContentItemGuidePathway } from './controls/accordionGuidePathway/AccordionContainerContentItemGuidePathway';
import Events from './controls/events/Events';
import MarketAccess from './controls/marketAccess/MarketAccess';
import ContactsPlaceholder from './controls/contactsPlaceholder/ContactsPlaceholder';
import Image from './controls/image/Image';
import { ImageItem } from './controls/image/ImageItem';
import LawsAndRegulationsResource from './domains/lar/LawsAndRegulationsResource';
import ResolveLink from './controls/resolveLink/ResolveLink';
import XptExportPlanTemplateWizardContainer from './domains/xpt/XptExportPlanTemplateWizardContainer';
import FilteredLawsAndRegsDutiesandtaxesMarketResults from './domains/lar/FilteredLawsAndRegsDutiesandtaxesMarketResults';
import FilteredLawsAndRegsRulesAndRestrictionsMarketResults from './domains/lar/FilteredLawsAndRegsRulesAndRestrictionsMarketResults';
import SearchResultsCount, { SearchResultsCountContentItem } from './controls/search/SearchResultsCount';
import SearchResultsMessage, { SearchResultsMessageContentItem } from './controls/search/SearchResultsMessage';
import { ExampleItem } from './controls/example/ExampleItem';
import Example from './controls/example/Example';
import { InlineLinkItem } from './controls/inlineLink/InlineLinkItem';
import FilteredResultsContainer from './controls/filteredResultsContainer/FilteredResultsContainer';
import { FilteredResultsContainerItem } from './controls/filteredResultsContainer/FilteredResultsContainerItem';
import EventDetails from './controls/eventDetails/EventDetails';
import Authentication from './controls/authentication/Authentication';
import SidebarInfo from './controls/sidebar/SidebarInfo';

interface ComponentResolverProps {
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
    match?: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    onLoadOpportunityData?: (dataAvailable: boolean, title: string) => void;
    onLoadSubHeader?: (dataAvailable: boolean, title: string) => void;
    onLoadDescription?: (dataAvailable: boolean, description: string) => void;
    type?: string;
}

const ComponentResolver = (props: ComponentResolverProps) => {
    const handleOnLoad = (dataAvailable: boolean, title: string) => {
        props.onLoad?.(dataAvailable, title);
    };

    const handleOnLoadDescription = (dataAvailable: boolean, description: string) => {
        props.onLoad && props.onLoadDescription(dataAvailable, description);
    };

    const renderComponent = () => {
        switch (props.data.item.system.type) {
            case 'information_sidebar':
                return <SidebarInfo data={{ item: props.data.item }} />;
            case 'authentication_call_to_action':
                return <Authentication data={{ item: props.data.item }} />;
            case 'pathway_list':
                return (
                    <EbgaPathwayList
                        data={{
                            item: props.data.item as PathwayListItem,
                            title: props.data.item.elements.title.value,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                    />
                );
            case 'body_copy':
                return (
                    <div className="body-copy-wrapper">
                        <BodyCopy
                            data={{ item: props.data.item as BodyCopyItem, linkedItems: props.data.linkedItems }}
                            match={props.match}
                            displayTitle={true}
                            onLoad={handleOnLoad}
                            type={props.type}
                        />
                    </div>
                );
            case 'checklist':
                return <Stepper data={{ content: props.data.item as StepperItem }} />;
            case 'a_real_example':
                return <Example data={{ item: props.data.item as ExampleItem }} match={props.match} />;
            case 'event_pathway':
                let eventPathways: EventPathwayItem[] = [];
                eventPathways.push(props.data.item as EventPathwayItem);
                return <EventPathway data={{ items: eventPathways }} />;
            case 'expert_insight':
                return <Quote data={{ item: props.data.item }} match={props.match} />;
            case 'feature_image_pathway':
                return <FeatureImagePathway data={{ item: props.data.item as FeatureImageItem }} match={props.match} />;
            case 'template':
                return <Download data={{ item: props.data.item as DownloadItem }} />;
            case 'notification':
                return (
                    <Notification
                        data={{ item: props.data.item, linkedItems: props.data.linkedItems }}
                        type={props.type}
                        match={props.match}
                        onLoad={handleOnLoad}
                    />
                );
            case 'search_container':
                return (
                    <SearchContainer
                        data={{ item: props.data.item as SearchContainerItem, linkedItems: props.data.linkedItems }}
                        onLoad={handleOnLoad}
                        match={props.match}
                    />
                );
            case 'filtered_results_container':
                return (
                    <FilteredResultsContainer data={{ item: props.data.item as FilteredResultsContainerItem, linkedItems: props.data.linkedItems }} />
                );
            case 'pro_tips':
                return <ProTip data={{ item: props.data.item as ProTipItem }} match={props.match} />;
            case 'pros_and_cons':
                return <ProsAndCons data={{ item: props.data.item }} />;
            case 'disclaimer':
                return <Disclaimer data={{ item: props.data.item as DisclaimerItem }} />;
            case 'uncommtrade':
                return <UNCommtrade data={{ item: props.data.item }} match={props.match} onLoad={props.onLoad} />;
            case 'results_container':
                return <ResultsContainer data={{ item: props.data.item }} match={props.match} onLoad={props.onLoad} />;
            case 'search_results_count':
                return <SearchResultsCount data={{ item: props.data.item as SearchResultsCountContentItem }} />;
            case 'search_results_message':
                return <SearchResultsMessage data={{ item: props.data.item as SearchResultsMessageContentItem }} />;
            case 'inline_link':
                return <InlineLink data={{ item: props.data.item as InlineLinkItem }} />;
            case 'filtered_market_results_container':
                const isDutiesandtaxesLawsAndRegsExplorer =
                    props.data.item?.elements.market_multiple_choice.value.length > 0 &&
                    props.data.item.elements.market_multiple_choice.value[0].codename === 'duties_and_taxes_laws_and_regulations';
                const isRulesandregulationsLawsAndRegsExplorer =
                    props.data.item?.elements.market_multiple_choice.value.length > 0 &&
                    props.data.item.elements.market_multiple_choice.value[0].codename === 'products_export_requirements_laws_and_regulations';
                if (isDutiesandtaxesLawsAndRegsExplorer) {
                    return (
                        <FilteredLawsAndRegsDutiesandtaxesMarketResults
                            data={{ item: props.data.item, linkedItems: props.data.linkedItems }}
                            match={props.match}
                            onLoad={handleOnLoad}
                            onLoadDescription={handleOnLoadDescription}
                        />
                    );
                } else if (isRulesandregulationsLawsAndRegsExplorer) {
                    return (
                        <FilteredLawsAndRegsRulesAndRestrictionsMarketResults
                            data={{ item: props.data.item, linkedItems: props.data.linkedItems }}
                            match={props.match}
                            onLoad={handleOnLoad}
                            onLoadDescription={handleOnLoadDescription}
                        />
                    );
                } else {
                    return (
                        <FilteredMarketResults
                            data={{ item: props.data.item, linkedItems: props.data.linkedItems }}
                            match={props.match}
                            onLoad={handleOnLoad}
                        />
                    );
                }
            case 'market_profile_placeholder':
                return <MarketProfile data={{ item: props.data.item }} match={props.match} onLoad={handleOnLoad} type={props.type} />;
            case 'crm_form':
                return <CrmForm data={{ item: props.data.item }} />;
            case 'crm_form_placeholder':
                return <CrmFormPlaceholder data={{ item: props.data.item }} />;
            case 'competitive_environment_placeholder':
                return <CompetitiveEnvironment data={{ item: props.data.item }} match={props.match} onLoad={handleOnLoad} type={props.type} />;
            case 'opportunities_placeholder':
                return <Opportunities data={{ item: props.data.item }} match={props.match} onLoad={handleOnLoad} type={props.type} />;
            case 'challenges_placeholder':
                return <Challenges data={{ item: props.data.item }} match={props.match} onLoad={handleOnLoad} type={props.type} />;
            case 'laws_and_regulations_resource_placeholder':
                return (
                    <LawsAndRegulationsResource
                        data={{ item: props.data.item as BodyCopyItem, linkedItems: props.data.linkedItems }}
                        match={props.match}
                        onLoad={handleOnLoad}
                        type={props.type}
                        key={props.data.item?.elements.laws_and_regulations_resource_type.value[0]?.codename}
                    />
                );
            case 'market_access_placeholder':
                return <MarketAccess data={{ item: props.data.item }} match={props.match} type={props.type} onLoad={props.onLoad} />;
            case 'tickboxlist':
                return <TickBoxList data={{ item: props.data.item }} onLoad={handleOnLoad} />;
            case 'video':
                return <Video data={{ item: props.data.item }} />;
            case 'accordion':
                return <Accordion data={{ item: props.data.item as AccordionContainerContentItem }} />;
            case 'guide_pathway_accordion':
                return (
                    <AccordionGuidePathway
                        data={{
                            item: props.data.item as AccordionContainerContentItemGuidePathway,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                    />
                );
            case 'events_placeholder':
                return <Events data={{ item: props.data.item }} match={props.match} type={props.type} onLoad={handleOnLoad} />;
            case 'contacts_placeholder':
                return <ContactsPlaceholder data={{ item: props.data.item }} match={props.match} type={props.type} onLoad={handleOnLoad} />;
            case 'image':
                return <Image data={{ item: props.data.item as ImageItem }} />;
            case 'resolve_link':
                return <ResolveLink data={{ item: props.data.item }} type={props.type} />;
            case 'free_trade_agreements':
                return <div>{'free_trade_agreements'}</div>;
            case 'create_an_export_plan_template_wizard_container':
                return (
                    <XptExportPlanTemplateWizardContainer data={{ item: props.data.item, linkedItems: props.data.linkedItems }} match={props.match} />
                );
            case 'event_details':
                return <EventDetails data={{ item: props.data.item as ContentItem }} />;
            default:
                return '';
        }
    };

    return <>{renderComponent()}</>;
};
export default ComponentResolver;
