import React from 'react';
import Notification from '../notification/Notification';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';

interface NotListedProps {
    data: {
        item: any;
        linkedItems?: IContentItemsContainer;
    };
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type?: string;
}

const NotListed = (props: NotListedProps) => {
    return <Notification data={{ item: props.data.item, linkedItems: props.data.linkedItems }} type={props.type} onLoad={props.onLoad} />;
};

export default NotListed;
