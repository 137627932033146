import { useEffect, useState } from 'react';
import useGetUrlIndustryAndSector from './useGetUrlIndustryAndSector';
import { IndustryOption } from '../models/IndustrySector';
import { parseIndustrySectorParam } from '../utils/personalisationUtils';
import { CommonConstants } from '../CommonConstants';

export type ValidateOnLoadQuerystring = {
    isProcessed: boolean;
    isValid: boolean;
};

export default function useValidateOnLoadQuerystring(
    industryOptionList: IndustryOption[],
    industryOptionListRetrieved: boolean,
): ValidateOnLoadQuerystring {
    const getUrlIndustryAndSector = useGetUrlIndustryAndSector();
    const [isProcessed, setIsProcessed] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);

    const getIndustryOption = (industry: string) => {
        return industryOptionList.find((ind) => ind.codeName === industry);
    };

    const getSectorOption = (industry: string, sector: string) => {
        const industryOption = getIndustryOption(industry);
        if (!industryOption) {
            return undefined;
        }

        return industryOption.sectors.find((sec) => sec.codeName === sector);
    };

    useEffect(() => {
        if (isProcessed || !getUrlIndustryAndSector.isProcessed || !industryOptionListRetrieved) {
            return;
        }

        if (!getUrlIndustryAndSector.isSupplied) {
            setIsProcessed(true);
            return;
        }

        const industryPersonalizationValue = parseIndustrySectorParam(getUrlIndustryAndSector.industryCode);
        const sectorPersonalizationValue = parseIndustrySectorParam(getUrlIndustryAndSector.sectorCode);

        const industryOption = getIndustryOption(industryPersonalizationValue);
        const sectorOption = getSectorOption(industryPersonalizationValue, sectorPersonalizationValue);

        if (!(!industryOption || (!sectorOption && sectorPersonalizationValue !== industryOption.codeName))) {
            setIsValid(true);
        } else if (
            industryPersonalizationValue === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME &&
            sectorPersonalizationValue === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME
        ) {
            setIsValid(true);
        }

        setIsProcessed(true);
    }, [getUrlIndustryAndSector.isProcessed, getUrlIndustryAndSector.isSupplied, industryOptionListRetrieved]);

    return { isProcessed, isValid: isValid };
}
