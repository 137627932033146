import React, { useEffect, useRef } from 'react';
import { DisclaimerItem } from './DisclaimerItem';
import './Disclaimer.scss';

interface DisclaimerProps {
    data: {
        item: DisclaimerItem;
    };
}

const Disclaimer = (props: DisclaimerProps) => {
    const detailsRef = useRef<HTMLDetailsElement>(null);
    const summaryRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if (props.data.item?.elements?.display_mode?.value?.[0]?.codename !== 'expandable') {
            detailsRef.current?.setAttribute('open', '');
            detailsRef.current?.setAttribute('class', 'standard');

            summaryRef.current?.setAttribute('tabIndex', '-1');
            summaryRef.current?.addEventListener('click', (e) => {
                e.preventDefault();
                e.stopImmediatePropagation();
                return false;
            });
        }
    }, [props.data.item?.elements?.expand_collapse?.value, detailsRef?.current, summaryRef?.current]);

    return (
        <div className="disclaimer mb-5">
            <details ref={detailsRef}>
                <summary ref={summaryRef} className="disclaimer-summary">
                    <div className="disclaimer-icon"></div>
                    <div className="pe-5">{props.data.item?.elements?.title?.value}</div>
                    <div className="arrow-down-icon"></div>
                </summary>
                <div dangerouslySetInnerHTML={{ __html: props.data.item?.elements?.description?.value }} />
            </details>
        </div>
    );
};

export default Disclaimer;
