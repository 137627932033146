import React, { useEffect, useState } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import withRouter from './withRouter';
import { telemetryService } from '../../utils/telemetryService';

function TelemetryProvider(props) {
    const [initialized, setInitialized] = useState<boolean>(false);

    useEffect(() => {
        const appInsightsInstrumentationKey = window['env'].AppInsightsInstrumentationKey as string;

        if (!initialized) {
            telemetryService.initialize(appInsightsInstrumentationKey, props.history);
            setInitialized(true);
        }
    });

    return <>{props.children}</>;
}

export default withRouter(withAITracking(telemetryService.reactPlugin, TelemetryProvider));
