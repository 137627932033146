import React, { useEffect } from 'react';
import './CrmForm.scss';

interface CrmFormProps {
    data: {
        item: any;
    };
}

const CrmForm = (props: CrmFormProps) => {
    useEffect(() => {
        const script1 = document.createElement('script');
        script1.src = props.data.item.elements.form_loader_source.value;
        document.body.appendChild(script1);

        const script2 = document.createElement('script');
        script2.src = props.data.item.elements.tracking_source.value;
        document.body.appendChild(script2);
    }, []);

    return (
        <div className="crmForm">
            {props.data.item.elements.title.value && (
                <div>
                    <h3>{props.data.item.elements.title.value}</h3>
                </div>
            )}

            {props.data.item.elements.description.value && <div>{props.data.item.elements.description.value}</div>}
            <div data-form-block-id={props.data.item.elements.form_block_id.value}></div>
            <div
                className="d365-mkt-config"
                data-website-id={props.data.item.elements.website_id.value}
                data-hostname={props.data.item.elements.host_name.value}
            ></div>
        </div>
    );
};

export default CrmForm;
