export enum PageLayoutType {
    SingleColumnPage = 0,
    TwoColumnPage,
}
export enum ColorThemeEnum {
    blue,
    yellow,
    teal,
    orange,
    green,
    purple,
}
