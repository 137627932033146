import React from 'react';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import './Authentication.scss';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';

interface AuthenticationProps {
    data: {
        item: ContentItem;
    };
}

const Authentication = (props: AuthenticationProps) => {
    const {
        elements: {
            sign_in_title: signInTitle,
            sign_in_description: signInDescription,
            sign_in_button_text: signInButtonText,
            sign_up_title: signUpTitle,
            sign_up_description: signUpDescription,
            sign_up_button_text: signUpButtonText,
            headinglevelsnippet__level: headingLevel,
        },
    } = props.data.item;

    return (
        <div className="auth-container">
            <p className="mb-6">
                <HeadingLevelResolver
                    data={{
                        headingLevel,
                        titleText: signInTitle.value,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: signInDescription.value,
                    }}
                ></div>
                <ComButton>{signInButtonText.value}</ComButton>
            </p>
            <p>
                <HeadingLevelResolver
                    data={{
                        headingLevel,
                        titleText: signUpTitle.value,
                    }}
                />
                <div
                    dangerouslySetInnerHTML={{
                        __html: signUpDescription.value,
                    }}
                ></div>
                <ComButton variant="secondary">{signUpButtonText.value}</ComButton>
            </p>
        </div>
    );
};

export default Authentication;
