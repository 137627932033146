import React, { useEffect, useState } from 'react';
import { Elements } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import { useAppSelector } from '../../hooks/reduxHooks';
import { CurrentMarketSearchToolSearchData } from '../../models/ReduxModels';
import parse, { Element, Text } from 'html-react-parser';
import { ElementType } from 'domelementtype';

export class SearchResultsCountContentItem extends ContentItem {
    tool: Elements.MultipleChoiceElement;
    text: Elements.TextElement;
    plural_text: Elements.TextElement;
}

interface SearchResultsCountProps {
    data: {
        item: SearchResultsCountContentItem;
    };
}

const SearchResultsCount = (props: SearchResultsCountProps) => {
    const [showComponent, setShowComponent] = useState<boolean>(false);

    const marketSearchData = useAppSelector<CurrentMarketSearchToolSearchData>((state) => state.market.currentMarketSearchResultsInfo);

    useEffect(() => {
        if (!marketSearchData) {
            return;
        }

        if (marketSearchData) {
            if (!marketSearchData.timeTag) {
                setShowComponent(false);
                return;
            }

            setShowComponent(marketSearchData.results.length > 0);
        }
    }, [marketSearchData?.type, marketSearchData?.timeTag]);

    const getCountHtml = () => {
        let count = 0;
        if (!marketSearchData) {
            return <></>;
        }

        switch (props.data.item.elements.tool.value[0].codename) {
            case 'market_search':
                count = marketSearchData?.results.length;
                break;
        }

        return parse(`<div>${count > 1 ? props.data.item.elements.plural_text.value : props.data.item.elements.text.value}</div>`, {
            replace: (domNode) => {
                switch ((domNode as Element).type) {
                    case ElementType.Tag:
                        const splits = ((domNode as Element).children[0] as Text).data.split('{{count}}');
                        return (
                            <p className="results-count">
                                {splits[0]}
                                <span className="semi-bold">{count}</span>
                                {splits[1]}
                            </p>
                        );
                }
            },
        });
    };

    return <>{showComponent && getCountHtml()}</>;
};

export default SearchResultsCount;
