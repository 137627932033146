import React from 'react';
import { Col, Row } from 'reactstrap';
import { InlineLinkItem } from '../inlineLink/InlineLinkItem';
import './InlineLink.scss';
import RightArrow from '../../assets/iconArrowRight.svg';

interface InlineLinkProps {
    data: {
        item: InlineLinkItem;
    };
}

const InlineLink = (props: InlineLinkProps) => {
    return (
        <Row className="inline-link">
            <Col>
                <a href={props.data.item.elements.link.value}>
                    {props.data.item.elements.arrow.value.length === 0 && <img src={RightArrow} alt="" />}
                    {props.data.item.elements.title.value}
                </a>
            </Col>
        </Row>
    );
};

export default InlineLink;
