import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { LawsAndRegsDataSourceInfo, MvTaxesAndDutiesInfo } from '../models/ReduxModels';
import { getLawsAndRegsDataSource } from '../providers/reducers/lawsAndRegsSlice';
import { getMvTaxesAndDutiesInfo, setStandardSector } from '../providers/reducers/mvLawsAndRegsSlice';
import { LawsAndRegsDataSourceType } from '../models/LawsAndRegsDataSourceType';

export default function useSector(): { sector: string; standardSector: string } {
    const { sector, market } = useParams();
    const [value, setValue] = useState<string>(undefined);
    const [hasSector, setHasSector] = useState<boolean>(false);
    const [isMvCommodity, setIsMvCommodity] = useState<boolean>(false);
    const dataSourceInfo = useAppSelector<LawsAndRegsDataSourceInfo>((state) => state.lawsAndRegs.dataSourceInfo);
    const mvTaxesAndDutiesInfo = useAppSelector<MvTaxesAndDutiesInfo>((state) => state.mvLawsAndRegs.mvTaxesAndDutiesInfo);
    const mvStandardSector = useAppSelector<string>((state) => state.mvLawsAndRegs.mvStandardSector);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (sector && market) {
            dispatch(getLawsAndRegsDataSource({ sector }));
            setHasSector(true);
        }
    }, [sector, market]);

    useEffect(() => {
        if (hasSector) {
            if (!dataSourceInfo.retrieved) {
                return;
            }
            if (dataSourceInfo.data === LawsAndRegsDataSourceType.MV) {
                dispatch(getMvTaxesAndDutiesInfo({ commodity: sector, market }));
                setIsMvCommodity(true);
            }
        }
    }, [hasSector, sector, market, dataSourceInfo.data, dataSourceInfo.retrieved]);

    useEffect(() => {
        if (isMvCommodity && mvTaxesAndDutiesInfo.retrieved) {
            dispatch(setStandardSector({ sector, mvTaxesAndDuties: mvTaxesAndDutiesInfo.data }));
        }
    }, [isMvCommodity, sector, mvTaxesAndDutiesInfo.retrieved, mvTaxesAndDutiesInfo.data?.BaseLevel]);

    useEffect(() => {
        if (mvStandardSector) {
            setValue(mvStandardSector);
        }
    }, [mvStandardSector]);

    return { sector, standardSector: value };
}
