import React from 'react';
import '../../controls/bodyCopy/BodyCopy.scss';
import { useAppSelector } from '../../hooks/reduxHooks';
import { UserGeolocationInfo } from '../../models/ReduxModels';
import useUserGeolocation from '../../hooks/useUserGeolocation';
import SyncLoader from 'react-spinners/SyncLoader';
import LoadingOverlay from 'react-loading-overlay-ts';
import OffshoreExperience from './OffshoreExperience';

const RestrictedToOnshore = (props: any) => {
    const geolocationInfo = useAppSelector<UserGeolocationInfo>((state) => state.user.geolocationInfo);
    useUserGeolocation();

    const render = () => {
        if (geolocationInfo.retrieved && !geolocationInfo.data.isOffshore) {
            return props.children;
        } else if (geolocationInfo.retrieved && geolocationInfo.data.isOffshore) {
            return <OffshoreExperience />;
        }
        return <></>;
    };
    return (
        <LoadingOverlay active={!geolocationInfo.retrieved} spinner={<SyncLoader />} text="Please wait" className="loader">
            {render()}
        </LoadingOverlay>
    );
};

export default RestrictedToOnshore;
