import { ActionType, PersonalisationStateInterface } from './types';

const SET_INDUSTRYSECTOR = 'SET_INDUSTRYSECTOR';
const SET_INDUSTRYSECTORHSCODE = 'SET_INDUSTRYSECTORHSCODE';
const SET_INDUSTRYSECTORHSCODEMARKET = 'SET_INDUSTRYSECTORHSCODEMARKET';
const PURGE_STATE = 'PURGE_STATE';
const PURGE_INDUSTRYSECTORMARKET = 'PURGE_INDUSTRYSECTORMARKET';
const SET_ISPERSONALISED = 'SET_ISPERSONALISED';
const SET_HSCODE = 'SET_HSCODE';
const SET_PROGRESS = 'SET_PROGRESS';

export const setIndustrySector = (industryCode: string, sectorCode: string) => ({
    type: SET_INDUSTRYSECTOR,
    payload: { industryCode, sectorCode },
});

export const setHsCode = (hsCode: string) => ({
    type: SET_HSCODE,
    payload: { hsCode },
});

export const setIndustrySectorHsCode = (industryCode: string, sectorCode: string, hsCode: string) => ({
    type: SET_INDUSTRYSECTORHSCODE,
    payload: { industryCode, sectorCode, hsCode },
});

export const setIndustrySectorHsCodeMarket = (industryCode: string, sectorCode: string, hsCode: string, marketCode: string) => ({
    type: SET_INDUSTRYSECTORHSCODEMARKET,
    payload: { industryCode, sectorCode, hsCode, marketCode },
});

export const clearSelection = () => ({ type: PURGE_STATE });
export const clearIndustrySectorMarketSelection = () => ({ type: PURGE_INDUSTRYSECTORMARKET });

export const setProgress = () => ({ type: SET_PROGRESS });

export const setIsPersonalised = (isPersonalised: boolean) => ({
    type: SET_ISPERSONALISED,
    payload: { isPersonalised },
});

const Reducer = (state: PersonalisationStateInterface, action: ActionType): any => {
    switch (action.type) {
        case SET_INDUSTRYSECTOR:
            return {
                ...state,
                industryCode: action.payload.industryCode,
                sectorCode: action.payload.sectorCode,
            };
        case SET_INDUSTRYSECTORHSCODE:
            return {
                ...state,
                industryCode: action.payload.industryCode,
                sectorCode: action.payload.sectorCode,
                hsCode: action.payload.hsCode,
            };
        case SET_INDUSTRYSECTORHSCODEMARKET:
            return {
                ...state,
                industryCode: action.payload.industryCode,
                sectorCode: action.payload.sectorCode,
                hsCode: action.payload.hsCode,
                marketCode: action.payload.marketCode,
            };
        case PURGE_STATE:
            return {
                ...state,
                industryCode: '',
                sectorCode: '',
                hsCode: '',
                marketCode: '',
                startAgain: true,
            };
        case PURGE_INDUSTRYSECTORMARKET:
            return {
                ...state,
                industryCode: '',
                sectorCode: '',
                marketCode: '',
            };
        case SET_ISPERSONALISED:
            return {
                ...state,
                isPersonalised: action.payload.isPersonalised,
            };
        case SET_HSCODE:
            return {
                ...state,
                hsCode: action.payload.hsCode,
            };
        case SET_PROGRESS:
            return {
                ...state,
                startAgain: false,
            };
        default:
            return state;
    }
};

export default Reducer;
