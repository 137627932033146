import { Element, DOMNode, Text } from 'html-react-parser';
import parse, { domToReact } from 'html-react-parser';
import { ElementType } from 'domelementtype';
import React, { MouseEvent, useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import './Table.scss';

interface TableProps {
    table: Element;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

const Table = (props: TableProps) => {
    const groupParentClass: string = 'groupParent';
    const showGroupClass: string = 'showGroup';
    const mobileWidthMax: number = 587;
    const [caption, setCaption] = useState<Element[]>();
    const [head, setHead] = useState<Element[]>();
    const [body, setBody] = useState<DOMNode[]>();
    const [foot, setFoot] = useState<DOMNode[]>();
    const [tableColumnHeaders, setTableColumnHeaders] = useState<string[]>([]);
    const [showMobile, setShowMobile] = useState<boolean>(false);
    const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
    const [rowTitles, setRowTitles] = useState<string[]>([]);
    const [isDisplayed, setisDisplayed] = useState<boolean>(false);

    //#region lifecycle
    useEffect(() => {
        //for tables generated from kentico where there are no th
        const hasTh = containsTh(props.table);
        if (!hasTh) {
            processTableWithNoTh();
        } else {
            if (isTableWithThead()) {
                processTableWithThead();
            } else {
                if (!isDisplayed) {
                    setisDisplayed(true);
                }
                processTableWithTbody();
            }
        }
    }, [props.table, isDisplayed]);

    useEffect(() => {
        function handleResize() {
            if (!showMobile && getWindowDimensions().width <= mobileWidthMax) {
                setShowMobile(true);
            } else if (showMobile && getWindowDimensions().width > mobileWidthMax) {
                setShowMobile(false);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showMobile]);
    //#endregion

    //#region methods
    const isTagThead = (c: Element): boolean => {
        return c.type === 'tag' && (c as Element).tagName === 'thead';
    };

    const isTagTbody = (c: Element): boolean => {
        return c.type === 'tag' && (c as Element).tagName === 'tbody';
    };

    const containsTh = (e: Element): boolean => {
        return (
            (e.type === ElementType.Tag && e.tagName === 'th') ||
            ((e.tagName === 'table' || e.tagName === 'tbody' || e.tagName === 'thead' || e.tagName === 'tr') &&
                e.children &&
                e.children.map((c) => e.type === ElementType.Tag && containsTh(c as Element)).filter((b) => b).length > 0)
        );
    };

    const isTableWithThead = (): boolean => {
        return (
            props.table.children &&
            props.table.children.filter(
                (c) =>
                    isTagThead(c as Element) &&
                    //check to see if thead has any th data cells
                    containsTh(c as Element),
            ).length > 0
        );
    };

    const mapHeaderWithTh = (header: Element[]) => {
        const headerDetails = mapHeader(header, true);

        setTableColumnHeaders(headerDetails.columnHeaders);
        setHead(headerDetails.header);
    };

    const mapHeaderWithoutTh = (header: Element[]) => {
        const headerDetails = mapHeader(header, false);

        setTableColumnHeaders(headerDetails.columnHeaders);
        setHead(headerDetails.header);
    };

    const mapHeader = (header: Element[], lookForTh: boolean) => {
        let tableColumnHeaders: string[] = [];

        header.map((x) => {
            let columnHeaders = x.children.filter(
                (c) => (!lookForTh && (c as Element).name && (c as Element).name === 'td') || (lookForTh && (c as Element)).name === 'th',
            );

            return columnHeaders.map((th) => {
                const columnName = getTdData(th as Element);
                tableColumnHeaders.push(columnName);
                (th as Element).name = 'th';
                return th;
            });
        });

        return {
            header,
            columnHeaders: tableColumnHeaders,
        };
    };

    const mapBody = (rows: DOMNode[]) => {
        let rowTitles: string[] = [];

        rows.forEach((c, i) => {
            rowTitles = rowTitles.concat(getRowTitles(c as Element, i));
        });

        setRowTitles(rowTitles);

        setBody(rows);
    };

    const processTableWithNoTh = () => {
        let tbody: Element =
            props.table.children &&
            ((props.table.children.find((x) => x.type === 'tag' && (x as Element).name === 'tbody') as Element) ||
                ((props.table.children.find((x) => x.type === 'tag' && (x as Element).name === 'tr') as Element) && props.table));

        let rows: DOMNode[] = tbody.children.filter((x) => (x as Element).name && (x as Element).name === 'tr') as DOMNode[];
        let header: Element[] = [];

        header.push(rows[0] as Element);

        mapHeaderWithoutTh(header);

        rows.shift();

        mapBody(rows);
    };

    const processTableWithTbody = () => {
        let caption: Element = props.table.children.find((c) => c.type === ElementType.Tag && (c as Element).tagName === 'caption') as Element;
        let tbodyList: Array<DOMNode> = props.table.children && (props.table.children.filter((c) => isTagTbody(c as Element)) as Array<DOMNode>);
        let rowList: Array<DOMNode> = [];

        tbodyList.forEach((tb: Element) => {
            rowList = rowList.concat(tb.children as Array<DOMNode>);
        });

        let rows: DOMNode[] = rowList.filter((x) => (x as Element).name && (x as Element).name === 'tr');
        let header: Element[] = [];
        let tfoot =
            props.table.children && (props.table.children.find((c) => c.type === ElementType.Tag && (c as Element).tagName === 'tfoot') as Element);

        caption &&
            (caption as Element).children &&
            (caption as Element).children.length > 0 &&
            setCaption((caption as Element).children.map((c) => c as Element));
        tfoot &&
            (tfoot as Element).children &&
            (tfoot as Element).children.length > 0 &&
            setFoot((tfoot as Element).children.map((c) => c as Element));

        header.push(rows.find((x) => containsTh(x as Element)) as Element);

        mapHeaderWithTh(header);

        rows.shift();

        mapBody(rows);
    };

    const processTableWithThead = () => {
        let caption: Element = props.table.children.find((c) => c.type === ElementType.Tag && (c as Element).tagName === 'caption') as Element;
        let thead: Element = props.table.children && (props.table.children.filter((c) => isTagThead(c as Element))[0] as Element);
        let tbody: Element = props.table.children && (props.table.children.filter((c) => isTagTbody(c as Element))[0] as Element);
        let tfoot: Element =
            props.table.children && (props.table.children.find((c) => c.type === ElementType.Tag && (c as Element).tagName === 'tfoot') as Element);

        caption &&
            (caption as Element).children &&
            (caption as Element).children.length > 0 &&
            setCaption((caption as Element).children.map((c) => c as Element));
        tfoot &&
            (tfoot as Element).children &&
            (tfoot as Element).children.length > 0 &&
            setFoot((tfoot as Element).children.map((c) => c as Element));

        let headRows: DOMNode[] = thead.children.filter((x) => (x as Element).name && (x as Element).name === 'tr') as DOMNode[];
        let header: Element[] = [];
        header.push(headRows[0] as Element);

        mapHeaderWithTh(header);

        let rows = tbody.children.filter((x) => (x as Element).name && (x as Element).name === 'tr') as DOMNode[];

        mapBody(rows);
    };

    const getRowTitles = (domNode: Element, i: number, j: number = null): string[] => {
        let response: string[] = [];
        if (domNode.name === 'tr') {
            response = response.concat(domNode.children && domNode.children.length > 0 && getRowTitles(domNode.children[0] as Element, i, 0));
        } else if (domNode.name === 'td' || domNode.name === 'th') {
            response.push(
                j === 0 && domNode.children && domNode.children.length > 0 && (checkTdDataIsText(domNode) ? getTdData(domNode) : `Expand-${i}`),
            );
        }

        return response;
    };

    const getTdData = (td: Element): string => {
        return ((td as Element).children[0] as Text).data;
    };

    const checkTdDataIsText = (td: Element): boolean => {
        return ((td as Element).children[0] as DOMNode).type === ElementType.Text;
    };

    const replaceEmbededTableFooterObjects = function (domNode: Element, isFooter: boolean = null): JSX.Element {
        let isFooterLocal = isFooter === null || isFooter;
        return replaceEmbededTableObjects(domNode, null, 0, isFooterLocal);
    };

    const preventDefaultEventAction = (e) => {
        if (e.target.tagName.toLowerCase() !== 'a') {
            e.preventDefault();
        }
    };

    const replaceEmbededTableObjects = function (domNode: Element, i: number, j: number = null, isFooter: boolean = null): JSX.Element {
        if (domNode.name === 'tr') {
            let rowTitle = rowTitles[i];
            let className = expandedRows[rowTitle] && groupParentClass;
            return (
                <tr className={className}>
                    {domNode.children &&
                        domNode.children.length > 0 &&
                        domNode.children.map((c, iiN) => {
                            return replaceEmbededTableObjects(c as Element, i, iiN, isFooter);
                        })}
                </tr>
            );
        } else if (domNode.name === 'td') {
            let rowTitle = rowTitles[i];
            let className = expandedRows[rowTitle] && showGroupClass;
            let isFirstColumn = !isFooter && j === 0;
            let colSpan = parseInt(domNode.attributes?.find((a) => a.name === 'colspan')?.value) ?? null;
            let rowSpan = parseInt(domNode.attributes?.find((a) => a.name === 'rowspan')?.value) ?? null;
            return (
                <td
                    data-rowtitle={rowTitles[i]}
                    data-title={tableColumnHeaders[j]}
                    className={className}
                    onClick={(isFirstColumn && mobileRowOnClick) || preventDefaultEventAction}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                >
                    {domNode.children && domNode.children.length > 0 && (
                        <span>
                            {domToReact(domNode.children as DOMNode[], {
                                replace: replaceEmbeddedObjects,
                            })}
                        </span>
                    )}
                    {isFirstColumn && (
                        <div className="arrow-icon">
                            <Arrow />
                        </div>
                    )}
                </td>
            );
        } else if (domNode.name === 'th') {
            let rowTitle = rowTitles[i];
            let className = expandedRows[rowTitle] && showGroupClass;
            let isFirstColumn = !isFooter && j === 0;
            let colSpan = parseInt(domNode.attributes?.find((a) => a.name === 'colspan')?.value) ?? null;
            let rowSpan = parseInt(domNode.attributes?.find((a) => a.name === 'rowspan')?.value) ?? null;
            return (
                <td
                    data-rowtitle={rowTitles[i]}
                    data-title={tableColumnHeaders[j]}
                    className={className}
                    onClick={(isFirstColumn && mobileRowOnClick) || preventDefaultEventAction}
                    colSpan={colSpan}
                    rowSpan={rowSpan}
                >
                    {domNode.children &&
                        domNode.children.length > 0 &&
                        domToReact(domNode.children as DOMNode[], {
                            replace: replaceEmbeddedObjects,
                        })}
                    {isFirstColumn && (
                        <div className="arrow-icon">
                            <Arrow />
                        </div>
                    )}
                </td>
            );
        } else if (domNode.tagName !== 'tbody' && domNode.tagName !== 'thead' && domNode.tagName !== 'caption' && domNode.tagName !== 'tfoot') {
            if (domNode.children && domNode.children.length > 0) {
                return parse(
                    `<${domNode.name}>${() => {
                        return domToReact(domNode.children as DOMNode[], {
                            replace: replaceEmbeddedObjects,
                        });
                    }}</${domNode.name}>`,
                    {
                        replace: replaceEmbeddedObjects,
                    },
                ) as JSX.Element;
            }
        }
    };

    const replaceEmbeddedObjects = (domNode: Element): JSX.Element => {
        if (domNode.name === 'dl') {
            return (
                <dl>
                    {domToReact(domNode.children as DOMNode[], {
                        replace: replaceEmbeddedObjects,
                    })}
                </dl>
            );
        } else if (domNode.name === 'dt') {
            return (
                <dt>
                    {domToReact(domNode.children as DOMNode[], {
                        replace: replaceEmbeddedObjects,
                    })}
                </dt>
            );
        } else if (domNode.name === 'dd') {
            return (
                <dd>
                    {domToReact(domNode.children as DOMNode[], {
                        replace: replaceEmbeddedObjects,
                    })}
                </dd>
            );
        }
    };

    const mobileRowOnClick = function (clickEvent: MouseEvent) {
        let dataRowTitle = clickEvent.currentTarget.attributes.getNamedItem('data-rowtitle');

        if (showMobile) {
            setExpandedRows({
                ...expandedRows,
                [dataRowTitle.value]: !expandedRows[dataRowTitle.value],
            });
        }
    };
    //#endregion

    return (
        <div className="table-container table-responsive-p">
            <table className="table">
                {caption && <caption>{domToReact(caption)}</caption>}
                <thead>{head && domToReact(head)}</thead>
                {body && (
                    <tbody>
                        {body.map((c, i) => {
                            return replaceEmbededTableObjects(c as Element, i);
                        })}
                    </tbody>
                )}
                {foot && (
                    <tfoot>
                        {foot.map((c, i) => {
                            return replaceEmbededTableFooterObjects(c as Element, true);
                        })}
                    </tfoot>
                )}
            </table>
        </div>
    );
};
export default Table;
