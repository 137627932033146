import React, { useEffect, useRef, useState } from 'react';
import './NavigationMenu.scss';
import { Link } from 'react-router-dom';
import { MenuItemData } from './MenuItemData';
import ChevronUpBlueIcon from '../../assets/Chevron-up-blue.svg';
import ChevronDownBlueIcon from '../../assets/Chevron-down-blue.svg';
import ChevronUpWhiteIcon from '../../assets/Chevron-up-white.svg';
import ChevronDownWhiteIcon from '../../assets/Chevron-down-white.svg';

export interface NavigationMenuItemProps {
    data: {
        menuItem: MenuItemData;
        mainMenuId: string;
        activeMainMenuId: string;
        focusMainMenuId: string;
        isMobile: boolean;
    };
    onMenuItemOpenClose: (menuItem: MenuItemData, isOpen: boolean) => void;
    onMenuItemBlur: (menuItem: MenuItemData, relatedMenuItemId: string | null, isKeyboardEvent: boolean) => void;
}

const NavigationMenuItem = (props: NavigationMenuItemProps) => {
    const numberOfLevelsSupported = 3;
    const menuItem = props.data.menuItem;
    const hasChildItems = props.data.menuItem.menuItems?.some((item) => item.showInGlobalNav);

    const [isOpen, setIsOpen] = useState(false);
    const navMenuListItemRef = useRef<HTMLLIElement>(null);

    let isKeyDown = false;

    useEffect(() => {
        if (props.data.isMobile) {
            setIsOpen(
                menuItem.level === 1
                    ? props.data.mainMenuId === props.data.activeMainMenuId
                    : props.data.mainMenuId === props.data.activeMainMenuId && isOpen,
            );
        } else {
            setIsOpen(props.data.mainMenuId === props.data.activeMainMenuId);
        }

        if (menuItem.level === 1 && menuItem.id === props.data.focusMainMenuId) navMenuListItemRef.current.focus();
    }, [props.data.isMobile, props.data.menuItem.level, props.data.mainMenuId, props.data.activeMainMenuId, props.data.focusMainMenuId]);

    useEffect(() => {
        if (isOpen) {
            if (props.data.isMobile || menuItem.level === 1) {
                navMenuListItemRef.current.focus();
            }
        }
    }, [isOpen]);

    const ConditionalWrapper = ({ condition, wrapper, children }) => (condition ? wrapper(children) : children);

    const openCloseMenuItem = (shouldOpenMenu: boolean) => {
        if (props.data.isMobile || menuItem.level === 1) setIsOpen(shouldOpenMenu);

        if (menuItem.level === 1) props.onMenuItemOpenClose(menuItem, shouldOpenMenu);
    };

    const handleMenuItemClick = () => {
        openCloseMenuItem(!isOpen);
    };

    const handleMenuItemKeyDown = (e: React.KeyboardEvent<HTMLLIElement>) => {
        isKeyDown = true;

        if (e.key === 'ArrowDown') {
            openCloseMenuItem(true);
            e.preventDefault();
        }
        if (e.key === 'ArrowUp') {
            openCloseMenuItem(false);
            e.preventDefault();
        }
        if (e.key === 'Enter') {
            if (menuItem.level === 1 && hasChildItems) openCloseMenuItem(!isOpen);
            else e.currentTarget.querySelector('a')?.click();
        }
    };

    const handleMenuItemMouseDown = (e: React.MouseEvent<HTMLLIElement>) => {
        isKeyDown = false;
    };

    const handleMenuItemBlur = (e: React.FocusEvent<HTMLLIElement>) => {
        const currentMenuItemId = e.currentTarget?.dataset?.menuitemid;
        if (currentMenuItemId !== menuItem.id) return;

        const relatedElement = e.relatedTarget as HTMLLIElement;
        const relatedMenuItemId = relatedElement?.dataset?.menuitemid;

        props.onMenuItemBlur(menuItem, relatedMenuItemId, isKeyDown);
    };

    return (
        menuItem.level <= numberOfLevelsSupported && (
            <ConditionalWrapper
                condition={menuItem.level < numberOfLevelsSupported}
                wrapper={(children) => <div className={`${menuItem.level === 1 ? 'submenu-wrapper' : 'menu-group'}`}>{children}</div>}
            >
                <li
                    ref={navMenuListItemRef}
                    className={`nav-level-${menuItem.level} ${menuItem.level === 1 ? 'mainmenu nav-item' : ''} ${hasChildItems ? 'nav-dropdown' : ''} ${isOpen ? 'open' : ''}`}
                    onClick={handleMenuItemClick}
                    tabIndex={0}
                    data-menuitemid={menuItem.id}
                    onKeyDown={handleMenuItemKeyDown}
                    onMouseDown={handleMenuItemMouseDown}
                    onBlur={handleMenuItemBlur}
                >
                    {menuItem.level === 1 && hasChildItems ? (
                        <>
                            <span className="gtm-nav-level-1 nav-level-1-text">
                                {menuItem.title}
                                <span className="nav-level-1-icon">
                                    &nbsp;&nbsp;<img src={isOpen ? ChevronUpBlueIcon : ChevronDownBlueIcon} alt=""></img>
                                </span>
                            </span>
                        </>
                    ) : (
                        <>
                            <Link
                                to={menuItem.url ? menuItem.url : '#'}
                                className={`gtm-nav-level-${menuItem.level} ${menuItem.level === 1 ? 'nav-link' : ''}`}
                                tabIndex={-1}
                                reloadDocument
                            >
                                {menuItem.title}
                                {props.data.isMobile && hasChildItems && (
                                    <span className={`nav-level-${menuItem.level}-icon`}>
                                        <img src={isOpen ? ChevronUpWhiteIcon : ChevronDownWhiteIcon} alt=""></img>
                                    </span>
                                )}
                            </Link>
                        </>
                    )}
                </li>

                {hasChildItems && (
                    <ul className={`${menuItem.level < numberOfLevelsSupported - 1 ? 'submenu' : ''} ${isOpen ? 'd-block' : 'd-none'}`}>
                        {menuItem.menuItems.map((m: MenuItemData) => {
                            return (
                                <NavigationMenuItem
                                    key={`menuitem_${m.id}`}
                                    data={{
                                        menuItem: m,
                                        mainMenuId: props.data.mainMenuId,
                                        activeMainMenuId: props.data.activeMainMenuId,
                                        focusMainMenuId: props.data.focusMainMenuId,
                                        isMobile: props.data.isMobile,
                                    }}
                                    onMenuItemOpenClose={props.onMenuItemOpenClose}
                                    onMenuItemBlur={props.onMenuItemBlur}
                                />
                            );
                        })}
                    </ul>
                )}
            </ConditionalWrapper>
        )
    );
};

export default NavigationMenuItem;
