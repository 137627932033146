import { ColorThemeEnum, Colors } from '@exporter-services/common-ui';

export interface GuidePathwayColorMapping {
    colorName: keyof typeof ColorThemeEnum;
    accordionItemBackground: string;
    accordionItemHoverBackground: string;
    chevronBackground: string;
    chevronHoverBackground: string;
    imageHoverBackground: string;
}

export const colorMappingsForAccordionItem: GuidePathwayColorMapping[] = [
    {
        colorName: ColorThemeEnum[ColorThemeEnum.blue] as keyof typeof ColorThemeEnum,
        accordionItemBackground: Colors.bgaBlueBackgroundExtraLight,
        chevronBackground: Colors.bgaBlueHighlightMid,
        accordionItemHoverBackground: '#D0DFF0',
        chevronHoverBackground: Colors.bgaBlueHighlightDark,
        imageHoverBackground: Colors.bgaBlueBackgroundExtraLight,
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.yellow] as keyof typeof ColorThemeEnum,
        accordionItemBackground: Colors.bgaYellowHighlightLight,
        chevronBackground: Colors.bgaYellowHighlightDark,
        accordionItemHoverBackground: '#FFEDB4',
        chevronHoverBackground: Colors.bgaYellowHighlightDark,
        imageHoverBackground: '#FFF5D7',
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.teal] as keyof typeof ColorThemeEnum,
        accordionItemBackground: Colors.bgaTealHighlightLight,
        chevronBackground: Colors.bgaTealHighlightMid,
        accordionItemHoverBackground: '#BDE4E4',
        chevronHoverBackground: Colors.bgaTealHighlightDark,
        imageHoverBackground: '#E1F3F3',
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.orange] as keyof typeof ColorThemeEnum,
        accordionItemBackground: Colors.bgaOrangeHighlightLight,
        chevronBackground: Colors.bgaOrangeHighlightMid,
        accordionItemHoverBackground: '#FCD9B8',
        chevronHoverBackground: Colors.bgaOrangeHighlightDark,
        imageHoverBackground: '#FDEBDA',
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.purple] as keyof typeof ColorThemeEnum,
        accordionItemBackground: Colors.bgaPurpleHighlightLight,
        chevronBackground: Colors.bgaPurpleHighlightMid,
        accordionItemHoverBackground: '#D0C8F4',
        chevronHoverBackground: Colors.bgaPurpleHighlightDark,
        imageHoverBackground: '#E9E6FA',
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.green] as keyof typeof ColorThemeEnum,
        accordionItemBackground: Colors.bgaGreenHighlightLight,
        chevronBackground: Colors.bgaGreenHighlightMid,
        accordionItemHoverBackground: '#C2E5C2',
        chevronHoverBackground: Colors.bgaGreenHighlightDark,
        imageHoverBackground: '#DBF0DB',
    },
];
