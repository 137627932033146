import React from 'react';
import './PaginationLinks.scss';
import { ReactComponent as ChevronDoubleLeftIcon } from '../../../assets/chevronDoubleLeft.svg';
import { ReactComponent as ChevronLeftIcon } from '../../../assets/chevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from '../../../assets/chevronRight.svg';
import { ReactComponent as ChevronDoubleRightIcon } from '../../../assets/chevronDoubleRight.svg';
import { ComButton } from '../../base/ComButton';

export interface PaginationLinksProps {
    data: {
        onFirstPageClick: Function;
        onPreviousPageClick: Function;
        onNextPageClick: Function;
        onLastPageClick: Function;
        onPageNumberClick: (pageNumber: number) => void;
        totalPages: number;
        pageSize: number;
        currentPage: number;
    };
}

export const PaginationLinks = (props: PaginationLinksProps) => {
    const totalLinksCanBeDisplayed = 3;

    const renderPageNumberLinks = () => {
        const items = [];
        for (let i = 1; i <= props.data.totalPages; i++) {
            //we display only 3 links at a time, before current page we ignore.
            if (i < props.data.currentPage && props.data.totalPages >= totalLinksCanBeDisplayed) {
                continue;
            }

            //if we already displayed 3 links then we can break.
            if (i - props.data.currentPage >= totalLinksCanBeDisplayed) {
                break;
            }

            items.push(
                <ComButton
                    variant="link"
                    key={i}
                    className={(i === props.data.currentPage ? 'page active' : 'page') + ' gtm-paginagtion'}
                    onClick={(e) => {
                        e.preventDefault();
                        props.data.onPageNumberClick(i);
                    }}
                    aria-disabled={props.data.currentPage === i}
                    disabled={props.data.currentPage === i}
                >
                    {i}
                </ComButton>,
            );
        }

        return items;
    };
    return (
        <div className="pagination-links">
            <div className="pagination">
                <ComButton
                    variant="link"
                    aria-disabled={props.data.currentPage === 1}
                    disabled={props.data.currentPage === 1}
                    className="first gtm-pagination"
                    onClick={(e) => {
                        e.preventDefault();
                        props.data.onFirstPageClick();
                    }}
                >
                    <div className="svg-container">
                        <ChevronDoubleLeftIcon />
                    </div>
                    First
                </ComButton>
                <ComButton
                    variant="link"
                    aria-disabled={props.data.currentPage === 1}
                    disabled={props.data.currentPage === 1}
                    className="previous  gtm-pagination"
                    onClick={(e) => {
                        e.preventDefault();
                        props.data.onPreviousPageClick();
                    }}
                >
                    <div className="svg-container">
                        <ChevronLeftIcon />
                    </div>
                    Previous
                </ComButton>

                {renderPageNumberLinks()}

                <ComButton
                    variant="link"
                    aria-disabled={props.data.currentPage === props.data.totalPages}
                    disabled={props.data.currentPage === props.data.totalPages}
                    className="next gtm-pagination"
                    onClick={(e) => {
                        e.preventDefault();
                        props.data.onNextPageClick();
                    }}
                >
                    Next
                    <div className="svg-container">
                        <ChevronRightIcon />
                    </div>
                </ComButton>

                <ComButton
                    variant="link"
                    aria-disabled={props.data.currentPage === props.data.totalPages}
                    disabled={props.data.currentPage === props.data.totalPages}
                    className="last gtm-pagination"
                    onClick={(e) => {
                        e.preventDefault();
                        props.data.onLastPageClick();
                    }}
                >
                    Last
                    <div className="svg-container">
                        <ChevronDoubleRightIcon />
                    </div>
                </ComButton>
            </div>
        </div>
    );
};
