import React, { useEffect, useRef } from 'react';
import { ComButton } from '@exporter-services/common-ui';
import { scrollTo } from '../../utils/scrollUtils';
import './ScrollToTop.scss';

interface ScrollToTopProps {
    containerRef: React.RefObject<HTMLDivElement>;
}

const ScrollToTop = ({ containerRef }: ScrollToTopProps) => {
    const SCROLL_THRESHOLD = 1500;
    const scrollToTopRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        let currentScroll = window.scrollY;
        window.addEventListener('scroll', () => {
            if (SCROLL_THRESHOLD < window.scrollY && currentScroll > window.scrollY) scrollToTopRef?.current?.classList.add('show');
            else scrollToTopRef?.current?.classList.remove('show');
            currentScroll = window.scrollY;
        });
    }, []);

    return (
        <div
            ref={scrollToTopRef}
            className="scroll-to-top"
            onClick={() => {
                scrollTo(containerRef);
            }}
        >
            <ComButton variant="secondary" className="back-to-top-icon">
                <span className="d-none d-md-inline">Go to top</span>
            </ComButton>
        </div>
    );
};

export default ScrollToTop;
