import React from 'react';
import parse, { DOMNode } from 'html-react-parser';
import './LawsAndRegsTreeBase.scss';

interface LawsAndRegsTreeBaseProps {
    data: {
        treeHtml: string;
    };
    replaceRootEmbeddedObject: (domNode: DOMNode) => any;
}

const LawsAndRegsTreeBase = (props: LawsAndRegsTreeBaseProps) => {
    return (
        <div className="laws-and-regs-tree-base">
            {parse(props.data.treeHtml, {
                replace: props.replaceRootEmbeddedObject,
            })}
        </div>
    );
};
export default LawsAndRegsTreeBase;
