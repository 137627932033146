import React, { useState, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import AccordionItem from './AccordionItem';
import './Accordion.scss';
import { AccordionContainerContentItem } from './AccordionContainerContentItem';
import { AccordionContentItem } from './AccordionContentItem';
import ChevronUpIcon from '../../assets/chevronUp333.svg';
import ChevronDownIcon from '../../assets/chevronDown333.svg';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/personalisationUtils';

interface AccordionProps {
    data: {
        item: AccordionContainerContentItem;
    };
}

const Accordion = (props: AccordionProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { personalisationState } = useContext(personalisationContext);

    return (
        <>
            {!validatePersonalisation(personalisationState, props.data.item?.elements.industry_sector) ? (
                <></>
            ) : (
                <Row>
                    <Col className="accordion">
                        <div className="accordion-container">
                            <div className="accordion-group" role="tablist">
                                <div
                                    className={`accordion-group-toggle ${
                                        props.data.item.elements.accordion_items?.value.length > 1 ? 'visible' : ''
                                    }`}
                                >
                                    <button
                                        aria-label="button Open all items in accordion"
                                        className="accordion-group-toggle-content"
                                        onClick={() => setExpanded((expanded) => !expanded)}
                                        aria-expanded={expanded}
                                        data-testid="accordion-group-toggle"
                                    >
                                        <h5>
                                            <span>{!expanded ? 'Open all' : 'Close all'}</span>
                                            <img src={!expanded ? ChevronDownIcon : ChevronUpIcon} alt=""></img>
                                        </h5>
                                        <span className="sr-only">button toggle all accordions</span>
                                    </button>
                                </div>

                                {props.data.item?.elements.accordion_items?.['linkedItems']?.map((i: AccordionContentItem, index) => {
                                    return <AccordionItem key={index} item={i} expanded={expanded} />;
                                })}
                            </div>
                        </div>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Accordion;
