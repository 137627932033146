import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import TagManager from 'react-gtm-module';
import { ContentItem, PaginationLinks } from '@exporter-services/common-ui';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import '../../styles/_filterPages.scss';
import './SiteSearchResultsContainer.scss';
import { SiteSearchResultTile } from '../../controls/siteSearch/SiteSearchResultTile';
import { SiteSearchFilter, SiteSearchResultItem, useGetSiteSearchResultsQuery } from '../../providers/reducers/siteSearchApi';
import { CommonConstants } from '../../CommonConstants';
import { PageName } from '../../models/PageName';
import { SiteSearchResultsFilterPanel } from './SiteSearchResultsFilterPanel';
import { scrollTo } from '../../utils/scrollUtils';
import NotListed from '../../controls/notListed/NotListed';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { getKenticoPageCategories } from '../../providers/reducers/taxonomySlice';
import { KenticoPageCategories } from '../../models/ReduxModels';

interface SiteSearchResultsContainerProps {
    data: {
        item?: ContentItem;
        linkedItems?: IContentItemsContainer;
    };
}

const SiteSearchResultsContainer = (props: SiteSearchResultsContainerProps) => {
    const [query] = useSearchParams();
    const navigate = useNavigate();
    const { hash } = useLocation();
    const refResultCountElement = useRef(null);
    const resultsContainerRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const pageCategoryTaxonomy = useAppSelector<KenticoPageCategories>((state) => state.taxonomy.pageCategories);

    const queryResponse = useGetSiteSearchResultsQuery({
        searchTerm: query.get('searchTerm'),
        pageNumber: +query.get('pageNumber') || 1,
        pageSize: CommonConstants.PAGE_SIZE,
        filter: query.get('filter') || '',
    });

    const [searchResults, setSearchResults] = useState<SiteSearchResultItem[]>([]);
    const [totalPages, setTotalPages] = useState(1);
    const [totalRecords, setTotalRecords] = useState(0);
    const [filterOptions, setFilterOptions] = useState<SiteSearchFilter[]>([]);

    useEffect(() => {
        dispatch(getKenticoPageCategories());
    }, []);

    useEffect(() => {
        if (queryResponse?.isSuccess && !queryResponse?.isFetching && pageCategoryTaxonomy?.retrieved) {
            const { ResultItems, TotalRecords, PageSize, Filters } = queryResponse.data;

            setSearchResults(ResultItems);
            setTotalRecords(TotalRecords);
            setTotalPages(Math.ceil(TotalRecords / PageSize));

            if (pageCategoryTaxonomy?.data?.length > 0 && Filters) {
                const orderedFilterOptions = pageCategoryTaxonomy.data
                    .map((category) => {
                        const foundFilter = Filters.find((filter) => filter.Category === category);
                        return foundFilter ? { ...foundFilter, Selected: !!query.get('filter')?.includes(foundFilter.Category) } : null;
                    })
                    .filter(Boolean);

                setFilterOptions(orderedFilterOptions);
            }

            if (queryResponse?.isSuccess && TotalRecords > 0 && refResultCountElement?.current && !hash) scrollTo(refResultCountElement);

            if (query.get('searchTerm') && TotalRecords <= 0) {
                TagManager.dataLayer({
                    dataLayer: {
                        event: 'site_search_no_results',
                        dlv_site_search_query: query.get('searchTerm'),
                    },
                });
            }
        }
    }, [
        pageCategoryTaxonomy?.retrieved,
        queryResponse?.isSuccess,
        queryResponse?.isFetching,
        queryResponse?.data,
        refResultCountElement?.current,
        query,
        hash,
    ]);

    useEffect(() => {
        if (query.get('pageNumber') && queryResponse?.isSuccess && !queryResponse?.isFetching) {
            setTimeout(() => resultsContainerRef?.current?.focus(), 50);
        }
    }, [query.get('pageNumber'), queryResponse?.isSuccess, queryResponse?.isFetching]);

    const getNavigationUrl = (pageNumber: number, filter: string | Array<string>, filterChanged = false) => {
        const filterQuery = filter?.length > 0 ? `&filter=${filter}` : '';
        const pageNumberQuery = filterChanged ? '' : `&pageNumber=${pageNumber}`;
        return `/${PageName.SiteSearchResults}?searchTerm=${encodeURIComponent(query.get('searchTerm'))}${pageNumberQuery}${filterQuery}`;
    };

    // #region "Filter Methods"
    const filterResults = (filter: Array<string>) => {
        navigate(getNavigationUrl(1, filter, true), {
            replace: true,
        });
    };
    // #endregion "Filter Methods"

    // #region "Pagination Methods"
    const navigateToPage = (pageNumber: number) => {
        navigate(getNavigationUrl(pageNumber, query.get('filter')), {
            replace: true,
        });
    };

    const onFirstPageClick = () => {
        navigateToPage(1);
    };

    const onPreviousPageClick = () => {
        navigateToPage(+query.get('pageNumber') - 1);
    };

    const onNextPageClick = () => {
        navigateToPage((+query.get('pageNumber') || 1) + 1);
    };

    const onLastPageClick = () => {
        navigateToPage(totalPages);
    };

    function onPageNumberClick(pageNumber: number) {
        navigateToPage(pageNumber);
    }
    // #endregion "Pagination Methods"

    return (
        <LoadingOverlay active={queryResponse?.isLoading || queryResponse?.isFetching} spinner text="Please wait" className="loader">
            <Row>
                <Col>
                    {searchResults?.length > 0 ? (
                        <h2 className="results-count-header" ref={refResultCountElement}>
                            Showing <strong>{totalRecords}</strong> {totalRecords === 1 ? 'result' : 'results'} for{' '}
                            <strong>{query.get('searchTerm')}</strong>
                        </h2>
                    ) : (
                        <h2 className="results-count-header">No results to show</h2>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={4}>
                    <SiteSearchResultsFilterPanel filterOptions={filterOptions} filterResults={filterResults} />
                </Col>
                <Col xs={12} sm={8}>
                    <div ref={resultsContainerRef} className="site-search-results-container" tabIndex={-1}>
                        <Row>
                            {searchResults?.map((result, index) => (
                                <Col xs={12} key={index}>
                                    <SiteSearchResultTile searchResult={result} elementKey={index} />
                                </Col>
                            ))}
                        </Row>
                    </div>
                    {searchResults?.length > 0 ? (
                        <div className="pagination-links-container" data-testid={'pagination'}>
                            {totalPages > 0 && (
                                <PaginationLinks
                                    data={{
                                        totalPages: totalPages,
                                        currentPage: +query.get('pageNumber') || 1,
                                        pageSize: CommonConstants.PAGE_SIZE,
                                        onPageNumberClick: onPageNumberClick,
                                        onFirstPageClick: onFirstPageClick,
                                        onLastPageClick: onLastPageClick,
                                        onNextPageClick: onNextPageClick,
                                        onPreviousPageClick: onPreviousPageClick,
                                    }}
                                />
                            )}
                        </div>
                    ) : (
                        <NotListed
                            data={{ item: props.data.item.elements?.linked_items?.['linkedItems']?.[0], linkedItems: props.data.linkedItems }}
                        />
                    )}
                </Col>
            </Row>
        </LoadingOverlay>
    );
};

export default SiteSearchResultsContainer;
