import React, { useEffect, useState } from 'react';
import { client } from '../../config';
import BodyCopy from '../bodyCopy/BodyCopy';
import { BodyCopyItem } from '../bodyCopy/BodyCopyItem';
import { formatDate } from '../../utils/utils';
import { CommonConstants } from '../../CommonConstants';

interface MarketProfileProps {
    data: {
        item: any;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const MarketProfile = (props: MarketProfileProps) => {
    const [content, setContent] = useState(null);
    const [linkedItems, setLinkedItems] = useState(null);

    const params = props.match.params;
    const market = params.market.toUpperCase();
    const commodity = params.sector;
    const onLoad = props.onLoad;
    const propsType = props.type;

    const getIndustry = () => {
        if (propsType === 'strong_market') {
            return CommonConstants.PRODUCTS_CODENAME;
        } else {
            if (
                (propsType === 'market' ||
                    propsType === 'service_laws_and_regulations' ||
                    propsType === 'products_export_requirements_laws_and_regulations' ||
                    propsType === 'duties_and_taxes_laws_and_regulations') &&
                params.sector !== 'all-sector'
            ) {
                return params.sector.replace(/-/g, '_');
            } else {
                return params.industry.replace(/-/g, '_');
            }
        }
    };

    const industry = getIndustry();
    const codeName = props.data.item.elements.resource_type.value[0].codename;

    useEffect(() => {
        if (propsType === 'market' || propsType === 'service_laws_and_regulations') {
            client
                .items()
                .equalsFilter('system.type', 'doing_business_resource')
                .containsFilter('elements.resource_type', [codeName])
                .anyFilter('elements.industry_sector', [industry, CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
                .anyFilter('elements.marketcodesnippet__market', [market.toLowerCase(), CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
                .depthParameter(5)
                .toPromise()
                .then((response) => {
                    setContent(response.data.items[0]);
                    setLinkedItems(response.data.linkedItems);
                    onLoad?.(response.data.items.length > 0, response.data.items.length > 0 ? response.data.items[0].elements.title.value : '');
                });
        } else {
            client
                .items()
                .equalsFilter('system.type', 'doing_business_resource')
                .containsFilter('elements.resource_type', [codeName])
                .anyFilter('elements.marketcodesnippet__market', [market.toLowerCase(), CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME])
                .anyFilter('elements.hscodesnippet__hscode', ['n' + commodity, CommonConstants.KONTENT_HSCODES_ALL_CODENAME])
                .depthParameter(5)
                .toPromise()
                .then((response) => {
                    let dataItems = response.data.items;
                    if (dataItems && dataItems.length > 0) {
                        setContent(dataItems[0]);
                        setLinkedItems(response.data.linkedItems);
                    }
                    onLoad?.(dataItems.length > 0, dataItems.length > 0 ? dataItems[0].elements.title.value : '');
                });
        }
    }, [commodity, industry, market, codeName, propsType]);

    return (
        <>
            {content && linkedItems && (
                <BodyCopy data={{ item: content as BodyCopyItem, linkedItems: linkedItems }} match={props.match} displayTitle={false} />
            )}
            {content?.elements.last_updated_date__last_updated?.value && (
                <p>
                    <strong>Last updated:</strong> {formatDate(content.elements.last_updated_date__last_updated.value)}
                </p>
            )}
        </>
    );
};

export default MarketProfile;
