import React from 'react';
import './SteppedNavigation.scss';
import { Row, Col } from 'reactstrap';

interface SteppedNavigationProps {
    steps?: Step[];
    activeStep?: number;
}

interface Step {
    stepCounter: number;
    stepTitle: string;
}

const SteppedNavigation = (props: SteppedNavigationProps) => {
    const getStepCssClassName = (step: Step) => {
        if (step.stepCounter < props.activeStep) {
            return 'completed-step';
        } else if (step.stepCounter === props.activeStep) {
            return 'current-step';
        }
    };

    const renderSteppedNavigation = () => {
        return (
            <ul className="stepped-navigation">
                {props.steps.map((step) => {
                    return (
                        <li className={getStepCssClassName(step)}>
                            <div className="step-container">
                                <div className="step-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                                        <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M0.799988 7.07187L2.50765 5.33422L5.51983 8.35328L13.4776 0.400024L15.2 2.11155L5.51976 11.7918L0.799988 7.07187Z"
                                            fill="white"
                                        />
                                    </svg>
                                    <span className="step-number">{step.stepCounter}</span>
                                </div>
                                <div className="step-name mini">{step.stepTitle}</div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        );
    };

    return (
        <>
            <Row>
                <Col md="12">{renderSteppedNavigation()}</Col>
            </Row>
        </>
    );
};

export default SteppedNavigation;
