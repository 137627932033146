import { ColorThemeEnum } from '../../../models/enums';
import { Colors } from '../../../styles';

export interface HeroPathwayColorMapping {
    colorName: keyof typeof ColorThemeEnum;
    highlightDark: string;
    highlightMid: string;
    highlightLight: string;
}

export const heroPathwayColorMappings: HeroPathwayColorMapping[] = [
    {
        colorName: ColorThemeEnum[ColorThemeEnum.blue] as keyof typeof ColorThemeEnum,
        highlightDark: Colors.bgaBlueHighlightDark,
        highlightMid: Colors.bgaBlueHighlightMid,
        highlightLight: Colors.bgaBlueBackgroundExtraLight,
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.yellow] as keyof typeof ColorThemeEnum,
        highlightDark: Colors.bgaYellowHighlightDark,
        highlightMid: Colors.bgaYellowHighlightMid,
        highlightLight: Colors.bgaYellowHighlightLight,
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.teal] as keyof typeof ColorThemeEnum,
        highlightDark: Colors.bgaTealHighlightDark,
        highlightMid: Colors.bgaTealHighlightMid,
        highlightLight: Colors.bgaTealHighlightLight,
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.orange] as keyof typeof ColorThemeEnum,
        highlightDark: Colors.bgaOrangeHighlightDark,
        highlightMid: Colors.bgaOrangeHighlightMid,
        highlightLight: Colors.bgaOrangeHighlightLight,
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.green] as keyof typeof ColorThemeEnum,
        highlightDark: Colors.bgaGreenHighlightDark,
        highlightMid: Colors.bgaGreenHighlightMid,
        highlightLight: Colors.bgaGreenHighlightLight,
    },
    {
        colorName: ColorThemeEnum[ColorThemeEnum.purple] as keyof typeof ColorThemeEnum,
        highlightDark: Colors.bgaPurpleHighlightDark,
        highlightMid: Colors.bgaPurpleHighlightMid,
        highlightLight: Colors.bgaPurpleHighlightLight,
    },
];
