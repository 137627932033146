import React, { useEffect, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { MvImportDocumentMetadata } from '../../models/MVDocumentName';
import { MvDocumentCategoryKey } from '../../models/MvDocumentCategoryKey';
import { useGetMvImportDocumentsListQuery } from '../../providers/reducers/mvLawsAndRegsApi';
import { PageName } from '../../models/PageName';

interface RulesAndRequirementsDocumentListProps {
    data: {
        item: any;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

const RulesAndRequirementsDocumentList = (props: RulesAndRequirementsDocumentListProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showComponent, setShowComponent] = useState<boolean>(true);
    const [documentsData, setDocumentsData] = useState<Array<MvImportDocumentMetadata>>(null);
    const [lastUpdated, setLastUpdated] = useState<String>('');
    const { data, isSuccess } = useGetMvImportDocumentsListQuery(
        {
            sector: props.match.params.sector,
            market: props.match.params.market,
            type: MvDocumentCategoryKey.SpecificRequirements,
        },
        { skip: !props.match.params.sector || !props.match.params.market },
    );

    useEffect(() => {
        if (data && isSuccess) {
            setLoading(false);
            setShowComponent(true);
            const formattedDate = data.LastUpdated.toLocaleDateString('en-AU', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            });
            setLastUpdated(formattedDate.toString());
        }
    }, [data, isSuccess]);

    useEffect(() => {
        if (isSuccess) {
            const hasDocuments = data?.Categories?.length > 0;

            if (!hasDocuments) {
                setShowComponent(false);
            } else {
                setDocumentsData(data?.Categories[0].Documents);
            }
            setLoading(false);
        }
    }, [isSuccess]);

    return (
        <>
            <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
                {showComponent && (
                    <div>
                        {documentsData && (
                            <ul>
                                {documentsData.map((document, index) => (
                                    <li key={`${document.Title}-${index}`}>
                                        <a
                                            title={document.Title}
                                            href={
                                                `${window.location.origin}/${PageName.LawsAndRegulations}/${PageName.ExportRulesFinder}/${PageName.ProductRequirements}/` +
                                                `${props.match.params.sector}/${props.match.params.market}/${PageName.RulesAndRestrictionsDocument}/${document.Id}`
                                            }
                                        >
                                            {document.Title}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                        <div>
                            <p>
                                Source: <a href="https://www.mendel-verlag.de/en/">Mendel</a>, updated on {lastUpdated}
                            </p>
                        </div>
                    </div>
                )}
            </LoadingOverlay>
        </>
    );
};

export default RulesAndRequirementsDocumentList;
