import React, { useEffect, useState, useContext, useRef } from 'react';
import { Col, Row } from 'reactstrap';
import { CommonConstants } from '../../CommonConstants';
import './MarketSearchToolSearchContainer.scss';
import '../../controls/search/SearchFilterGroup.scss';
import RadioButtonGroup, { RadioButtonGroupItem } from '../../controls/radioButtonGroup/RadioButtonGroup';
import Shortlist from './Shortlist';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import CommoditiesSearch, { Commodity } from '../../controls/search/CommoditiesSearch';
import { CommoditySearchItem } from '../../controls/search/CommoditySearchItem';
import { personalisationContext } from '../../providers/personalisation';
import { setHsCode, setIndustrySectorHsCode, clearSelection } from '../../providers/personalisation/reducer';
import { setIndustrySectorAndHsCode, clearProductsServicesSelection } from '../../providers/reducers/productServiceSelectionSlice';
import * as ReactGA from 'react-ga';
import { useAppDispatch } from '../../hooks/reduxHooks';
import TagManager from 'react-gtm-module';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { scrollTo } from '../../utils/scrollUtils';
import { TooltipText } from '../../controls/tooltip/TooltipText';

interface MarketSearchToolSearchContainerProps {
    match?: any;
    data: {
        item?: ContentItem;
        linkedItems?: IContentItemsContainer;
    };
    onLoad?: (dataAvailable: boolean, title: string) => void;
}

interface IndustryOption {
    title: string;
    codeName: string;
    sectors: SectorOption[];
}

interface SectorOption {
    title: string;
    codeName: string;
}

const MarketSearchToolSearchContainer = (props: MarketSearchToolSearchContainerProps) => {
    const [selectedIndustry, setSelectedIndustry] = useState<IndustryOption>();
    const [selectedSector, setSelectedSector] = useState<SectorOption>(null);
    const [showIndustriesList, setShowIndustriesList] = useState<boolean>(false);
    const [showSectorsList, setShowSectorsList] = useState<boolean>(false);
    const [showApplyFilterButton, setShowApplyFilterButton] = useState<boolean>(false);
    const [strongMarketEntity, setStrongMarketEntity] = useState(null);
    const wrapperRef = useRef(null);
    const footerRef = useRef(null);
    const industrySelectorRef = useRef(null);
    const sectorSelectorRef = useRef(null);
    const commoditySelectorRef = useRef(null);

    const [industries, setIndustries] = useState<IndustryOption[]>([]);
    const [selectedHsCode, setSelectedHsCode] = useState('');
    const [showCommoditiesSearch, setShowCommoditiesSearch] = useState<boolean>(false);
    const [clearCommoditiesSelection, setClearCommoditiesSelection] = useState<boolean>(false);
    const { personalisationState, dispatch } = useContext(personalisationContext);
    const [hsCodesHelpText, setHsCodesHelpText] = useState<[string, string]>(null);

    const productSearchFilterRadioButtonGroupItem: RadioButtonGroupItem = {
        Id: CommonConstants.PRODUCTS_RADIOBUTTON_VALUE,
        Title: CommonConstants.PRODUCTS_RADIOBUTTON_TITLE,
        Value: CommonConstants.PRODUCTS_RADIOBUTTON_VALUE,
    };
    const serviceSelectionFilterRadioButtonGroupItem: RadioButtonGroupItem = {
        Id: CommonConstants.SERVICES_RADIOBUTTON_VALUE,
        Title: CommonConstants.SERVICES_RADIOBUTTON_TITLE,
        Value: CommonConstants.SERVICES_RADIOBUTTON_VALUE,
    };

    const [searchFilterRadioButtonGroupItemSelection, setSearchFilterRadioButtonGroupItemSelection] = useState<RadioButtonGroupItem>(
        productSearchFilterRadioButtonGroupItem,
    );
    const reduxDispatch = useAppDispatch();
    const { isTablet, isMobile } = useGetDisplayType();

    useEffect(() => {
        (async () => {
            if (window.location.href.indexOf('?') > -1) {
                await urlParamsOverride();
            }
        })();
    }, [window.location.search]);

    useEffect(() => {
        let extractedIndustries = extractIndustries();
        let previousHsCodeSelection = personalisationState.hsCode;
        extractHSCodesHelpText();
        setSelectedHsCode(previousHsCodeSelection);

        if (previousHsCodeSelection && searchFilterRadioButtonGroupItemSelection.Value === CommonConstants.PRODUCTS_RADIOBUTTON_VALUE) {
            setupPageInProductSearchMode(previousHsCodeSelection);
        } else if (personalisationState.industryCode) {
            let previousIndustrySelection = extractedIndustries.find((i) => i.codeName === personalisationState.industryCode);

            let previousSectorSelection = previousIndustrySelection?.sectors?.find((s) => s.codeName === personalisationState.sectorCode);

            if (!previousSectorSelection && personalisationState.industryCode === personalisationState.sectorCode) {
                previousSectorSelection = { codeName: personalisationState.sectorCode, title: null }; //It might be an industry taxonomy with no sub sector like defence
            }

            if (!previousIndustrySelection || !previousSectorSelection) {
                setupPageInProductSearchMode('');
            } else {
                setSelectedIndustry(previousIndustrySelection);
            }

            if (previousIndustrySelection && previousSectorSelection) {
                setupPageInServiceSelectionMode(previousIndustrySelection, previousSectorSelection.codeName);
            } else {
                setupPageInProductSearchMode('');
            }
        } else {
            setSelectedHsCode('');
            setupPageInProductSearchMode('');
        }

        setIndustries(extractedIndustries);
    }, [personalisationState.industryCode, personalisationState.sectorCode, personalisationState.hsCode]);

    const extractIndustries = () => {
        let industriesToCollect: IndustryOption[] = [];
        let industryNotListedFound = false;

        props.data.item?.elements.list_of_questions['linkedItems'][1].elements.dropdown_item['linkedItems'].forEach((item) => {
            let sectors: SectorOption[] = [];

            item.elements.filtered_component['linkedItems']
                .filter((fc) => fc?.elements.industry_sector?.value?.length > 0)
                .forEach((fc) => {
                    let sector: SectorOption = {
                        title: fc?.elements.title?.value,
                        codeName: fc?.elements.industry_sector?.value[0]?.codename,
                    };

                    sectors.push(sector);
                });

            let industry: IndustryOption = {
                title: item.elements.title?.value,
                codeName: item.elements.industry_sector?.value[0]?.codename,
                sectors: [...sectors],
            };

            industryNotListedFound = industry.codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME;
            industriesToCollect.push(industry);
        });

        if (!industryNotListedFound) {
            industriesToCollect.push({
                title: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_LABEL,
                codeName: CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                sectors: [],
            });
        }

        return industriesToCollect;
    };

    const extractHSCodesHelpText = () => {
        let hsCodesHelpTextResult = props.data.item?.elements.list_of_questions['linkedItems'][0].elements.linked_items['linkedItems'].find(
            (item) => {
                if (item?.system?.codename === CommonConstants.KONTENT_HSCODES_TOOLTIP_CODENAME) {
                    return item;
                }
                return null;
            },
        );

        if (hsCodesHelpTextResult) {
            let header = hsCodesHelpTextResult.elements.header?.value;
            let description = hsCodesHelpTextResult.elements.description?.value;
            if (header && description) {
                setHsCodesHelpText([header, description]);
            }
        }
    };

    const setupPageInProductSearchMode = (hsCode: string) => {
        setSelectedIndustry(null);
        setSelectedSector(null);
        setShowIndustriesList(false);
        setShowSectorsList(false);
        setShowCommoditiesSearch(true);
        setShowApplyFilterButton(hsCode ? true : false);
        setSearchFilterRadioButtonGroupItemSelection(productSearchFilterRadioButtonGroupItem);
        reduxDispatch(setIndustrySectorAndHsCode('', '', hsCode));
    };

    const setupPageInServiceSelectionMode = (industry: IndustryOption, sector: string) => {
        setSelectedHsCode(null);
        setShowCommoditiesSearch(false);
        setShowIndustriesList(true);
        setShowApplyFilterButton(false);

        if (industry?.sectors?.length > 0) {
            let previousSectorSelection = industry?.sectors?.find((s) => s.codeName === sector);
            setSelectedSector(previousSectorSelection);
            setShowSectorsList(true);
            setShowApplyFilterButton(true);
            reduxDispatch(setIndustrySectorAndHsCode(industry?.codeName, previousSectorSelection?.codeName, ''));
        } else if (industry) {
            setSelectedSector({ codeName: industry?.codeName, title: null });
            setShowSectorsList(false);
            setShowApplyFilterButton(true);
            reduxDispatch(setIndustrySectorAndHsCode(industry?.codeName, industry?.codeName, ''));
        } else {
            reduxDispatch(setIndustrySectorAndHsCode('', '', ''));
        }

        setSearchFilterRadioButtonGroupItemSelection(serviceSelectionFilterRadioButtonGroupItem);
    };

    function searchFilterRadioButtonItemSelected(item: RadioButtonGroupItem) {
        setSearchFilterRadioButtonGroupItemSelection(item);

        if (item.Value === CommonConstants.PRODUCTS_RADIOBUTTON_VALUE) {
            extractStrongMarketEntityForProductSearch();
            setupPageInProductSearchMode(selectedHsCode);
        } else {
            setupPageInServiceSelectionMode(selectedIndustry, selectedSector?.codeName);
        }
    }

    const isIndustrySelected = (industry) => {
        return selectedIndustry && industry.codeName === selectedIndustry.codeName;
    };

    const onIndustryFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(industrySelectorRef);
        }
    };

    const onSectorFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(sectorSelectorRef);
        }
    };

    const onCommodityFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(commoditySelectorRef);
        }
    };

    const changeIndustry = (event) => {
        let codeName = event.target.value;
        if (!codeName) return;
        let industry = industries.find((i) => i.codeName === codeName);
        setSelectedIndustry(industry);
        setSelectedSector(null);

        if (industry.sectors.length > 0 && industry.codeName !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            setShowSectorsList(true);
            setShowApplyFilterButton(false);
        } else if (industry.codeName !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            setShowSectorsList(false);
            let selectedSector: SectorOption = { title: industry.title, codeName: industry.codeName };
            setSelectedSector(selectedSector);
            setShowApplyFilterButton(true);
        }

        if (industry.codeName === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            reduxDispatch(
                setIndustrySectorAndHsCode(
                    CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                    CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME,
                    '',
                ),
            );
            setShowSectorsList(false);
            setShowApplyFilterButton(false);
        }

        ReactGA.modalview(`${window.location.pathname}?industry=${codeName}`);
    };

    const changeSector = (event) => {
        let codeName = event.target.value;
        if (!codeName) return;
        let sector = selectedIndustry.sectors.find((s) => s.codeName === codeName);
        setSelectedSector(sector);
        setShowApplyFilterButton(true);
    };

    const isSectorSelected = (sector) => {
        return selectedSector && sector.codeName === selectedSector.codeName;
    };

    const extractStrongMarketEntityForProductSearch = () => {
        setStrongMarketEntity(
            props.data.item?.elements.list_of_questions['linkedItems'][2].elements.dropdown_item['linkedItems'].filter(
                (v) => v.elements.industry_sector?.value[0]?.codename === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME,
            )[0],
        );
    };

    const onCommodityClick = (selectedOption: Commodity) => {
        setClearCommoditiesSelection(false);
        setSelectedHsCode(selectedOption?.Code);

        let isListed = selectedOption?.Code && selectedOption?.Code !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME;
        if (!isListed) {
            reduxDispatch(setIndustrySectorAndHsCode('', '', CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME));
            setShowApplyFilterButton(false);
        } else {
            setShowApplyFilterButton(true);
        }
    };

    const clearFilter = () => {
        setSelectedIndustry(null);
        setSelectedSector(null);
        setSelectedHsCode(null);
        setClearCommoditiesSelection(true);
        dispatch(clearSelection());
        reduxDispatch(clearProductsServicesSelection());

        if (window.location.search) {
            window.location.href = window.location.pathname;
            return;
        }

        // scroll for all modes - mobile, tablet and desktop
        scrollTo(wrapperRef);
    };

    const applyFilter = () => {
        const DL_EVENT_NAME = 'market_search_tool_search_button_click';

        if (!showApplyFilterButton) {
            return;
        }

        if (searchFilterRadioButtonGroupItemSelection.Value === productSearchFilterRadioButtonGroupItem.Value) {
            setSelectedIndustry(null);
            setSelectedSector(null);
            dispatch(setHsCode(selectedHsCode));
            reduxDispatch(setIndustrySectorAndHsCode('', '', selectedHsCode));
        } else {
            setSelectedHsCode(null);
            dispatch(setIndustrySectorHsCode(selectedIndustry?.codeName, selectedSector?.codeName, ''));
            reduxDispatch(setIndustrySectorAndHsCode(selectedIndustry?.codeName, selectedSector?.codeName, ''));
        }

        if (!selectedHsCode && selectedIndustry?.codeName) {
            TagManager.dataLayer({
                dataLayer: {
                    event: DL_EVENT_NAME,
                    dlv_industry: selectedIndustry?.codeName,
                    dlv_sector: selectedSector?.codeName,
                    dlv_product_code: '',
                    dlv_service_or_product: 'service',
                },
            });
        } else {
            TagManager.dataLayer({
                dataLayer: {
                    event: DL_EVENT_NAME,
                    dlv_industry: '',
                    dlv_sector: '',
                    dlv_product_code: selectedHsCode,
                    dlv_service_or_product: 'product',
                },
            });
        }

        // scroll for all modes - mobile, tablet and desktop
        scrollTo(footerRef);
    };

    const urlParamsOverride = async () => {
        const params = new URLSearchParams(window.location.search);
        const industryParam = params.get('industry');
        const sectorParam = params.get('sector');
        const hsCodeParam = params.get('hscode');

        if (industryParam && sectorParam) {
            const industryParamValue = replaceAll(industryParam, '-', '_');
            const sectorParamValue = replaceAll(sectorParam, '-', '_');

            if (sectorParamValue === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                dispatch(clearSelection());
            } else {
                reduxDispatch(setIndustrySectorAndHsCode(industryParamValue, sectorParamValue, ''));
                dispatch(setIndustrySectorHsCode(industryParamValue, sectorParamValue, ''));
            }
        } else if (hsCodeParam) {
            reduxDispatch(setIndustrySectorAndHsCode('', '', hsCodeParam));
            dispatch(setHsCode(hsCodeParam));
        }
    };

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    return (
        <>
            <div ref={wrapperRef}>
                <Row className="marketsearchtoolsearchcontainer-box marketsearchtoolsearchcontainer-box-container">
                    <Col md="12">
                        <RadioButtonGroup
                            items={[productSearchFilterRadioButtonGroupItem, serviceSelectionFilterRadioButtonGroupItem]}
                            legendAsLabel={true}
                            selectedValue={searchFilterRadioButtonGroupItemSelection.Value}
                            name={'looking_export'}
                            label={CommonConstants.EXPORT_TYPE_SELECTION_LABEL}
                            onChangeHandler={(e, item) => searchFilterRadioButtonItemSelected(item)}
                        />
                        {showIndustriesList && (
                            <>
                                <div ref={industrySelectorRef} className="filtergroup-form-field-container">
                                    <label className="field-label" htmlFor="industry">
                                        Select industry
                                    </label>
                                    <select id="industry" name="industry" onChange={changeIndustry} onFocus={onIndustryFocus}>
                                        {!selectedIndustry && (
                                            <option key="select_an_industry" value="">
                                                Select an industry
                                            </option>
                                        )}
                                        {industries.map((industry) => (
                                            <option
                                                className={`${isIndustrySelected(industry) ? 'selected' : ''} filter-item-select-option`}
                                                key={industry.codeName}
                                                value={industry.codeName}
                                                selected={isIndustrySelected(industry)}
                                            >
                                                {industry.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                        {selectedIndustry && showSectorsList && (
                            <>
                                <div ref={sectorSelectorRef} className="filtergroup-form-field-container">
                                    <label className="field-label" htmlFor="sector">
                                        Select sector
                                    </label>
                                    <select id="sector" name="sector" onChange={changeSector} onFocus={onSectorFocus}>
                                        {!selectedSector && (
                                            <option key="select_a_sector" value="">
                                                Select a sector
                                            </option>
                                        )}
                                        {selectedIndustry.sectors.map((sector) => (
                                            <option
                                                className={`${isSectorSelected(sector) ? 'selected' : ''}`}
                                                key={sector.codeName}
                                                value={sector.codeName}
                                                selected={isSectorSelected(sector)}
                                            >
                                                {sector.title}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                        )}
                        {showCommoditiesSearch && (
                            <div ref={commoditySelectorRef} className="filtergroup-form-field-container">
                                <label className="field-label" htmlFor="commodities-search">
                                    {strongMarketEntity?.elements.information_for?.value}
                                </label>
                                <CommoditiesSearch
                                    item={strongMarketEntity}
                                    searchItem={strongMarketEntity?.elements.filtered_component.linkedItems[0] as CommoditySearchItem}
                                    onClick={onCommodityClick}
                                    onFocus={onCommodityFocus}
                                    PnPFilter={false}
                                    industryCode={CommonConstants.PRODUCTS_CODENAME}
                                    commodityCode={selectedHsCode}
                                    clearSelection={clearCommoditiesSelection}
                                    isMarketSearch={true}
                                    ariaLabel={strongMarketEntity?.elements.information_for?.value}
                                />
                                {hsCodesHelpText && <TooltipText data={{ header: hsCodesHelpText[0], description: hsCodesHelpText[1] }} />}
                            </div>
                        )}
                        <div ref={footerRef} className="filtergroup-filter-footer button-container">
                            <ComButton
                                id="clearFilters"
                                variant="link"
                                className="filtergroup-clear-filters refresh-icon"
                                role="button"
                                aria-pressed="false"
                                onClick={clearFilter}
                            >
                                <span>Start again</span>
                            </ComButton>
                            {showApplyFilterButton && (
                                <ComButton id="personalization_filter_selection" className="sticktoright" onClick={applyFilter}>
                                    See results
                                </ComButton>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Shortlist
                            data={{
                                item: props.data.item,
                            }}
                            match={props.match}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default MarketSearchToolSearchContainer;
