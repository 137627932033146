import React, { useEffect, useState, useRef } from 'react';
import './SectorExplorerSearchContainer.scss';
import { ContentItem, ImageTilePathway, ImageTilePathwayItem } from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Elements } from '@kontent-ai/delivery-sdk';
import { client } from '../../config';

interface SectorExplorerSearchContainerProps {
    data: {
        item?: ContentItem;
    };
}

interface SectorOption {
    Id: string;
    Label: string;
    ImageUrl: string;
    PathwayLink: Elements.RichTextElement;
    ImageAltText: string;
}

const SectorExplorerSearchContainer = (props: SectorExplorerSearchContainerProps) => {
    const [sectorOptions] = useState<SectorOption[]>([]);
    const [imageTilePathwayItems, setImageTilePathwayItems] = useState<ImageTilePathwayItem[]>([]);
    const [textFilter, setTextFilter] = useState('');
    const scrollRef = useRef(null);

    useEffect(() => {
        props.data.item.elements.list_of_questions['linkedItems'][0].elements.content['linkedItems'].forEach((sector) => {
            sectorOptions.push({
                Id: sector.system.id,
                Label: sector.elements.title.value,
                ImageUrl: sector.elements.image.value[0].url,
                ImageAltText: sector.elements.image.value[0].description ?? '',
                PathwayLink: sector.elements.pathway__link,
            } as SectorOption);
        });
    }, []);

    useEffect(() => {
        if (sectorOptions) {
            const pathwayItems = sectorOptions
                .sort((a, b) => a.Label.localeCompare(b.Label))
                .filter((s) => (!textFilter || s.Label.toLowerCase().includes(textFilter)) && s.ImageUrl)
                .map((s) => {
                    return {
                        systemId: s.Id,
                        title: s.Label,
                        imageUrl: s.ImageUrl,
                        imageAltText: s.ImageAltText,
                        pathway_link: s.PathwayLink,
                    } as ImageTilePathwayItem;
                });
            setImageTilePathwayItems(pathwayItems);
        } else {
            setImageTilePathwayItems([]);
        }
    }, [sectorOptions, textFilter]);

    const onFilterClicked = () => {
        scrollRef.current.scrollIntoView();
    };

    const onFilterChanged = (e) => {
        setTextFilter(e.target.value.toLowerCase());
    };

    return (
        <>
            {sectorOptions && (
                <div className="sector-explorer-box-form-field-container">
                    <label className="field-label" htmlFor="sector-input">
                        Enter an industry or sector
                    </label>
                    <input
                        id="sector-input"
                        ref={scrollRef}
                        className="sector-explorer-box-form-field"
                        name="form-input-example"
                        type="text"
                        value={textFilter}
                        onClick={onFilterClicked}
                        onChange={onFilterChanged}
                        aria-label="Enter an industry or sector"
                    />
                </div>
            )}
            <LoadingOverlay active={!sectorOptions} spinner text="Please wait" className="loader">
                <div className="sector-explorer-box-container">
                    <ImageTilePathway data={{ items: imageTilePathwayItems }} client={client} />
                </div>
            </LoadingOverlay>
        </>
    );
};

export default SectorExplorerSearchContainer;
