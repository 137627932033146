import React from 'react';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import PageBody from '../pageBody/PageBody';
import Layout from '../../site/Layout';
import '../../controls/bodyCopy/BodyCopy.scss';
import ErrorPageHeader from '../pageHeader/ErrorPageHeader';

const NotFound = () => {
    return (
        <>
            <Helmet>
                <title>Page not found</title>
                <meta name="robots" content="noindex" />
            </Helmet>
            <Layout>
                <ErrorPageHeader data={{ title: 'Sorry, we can’t find the page you’re looking for' }} />
                <PageBody>
                    <Container>
                        <div className="body-copy">
                            <p>
                                Have a look around our <a href="/">Export Guide</a>.
                            </p>
                            <p>
                                If you still need help you can <a href="/contact-us">Contact us</a>
                            </p>
                        </div>
                    </Container>
                </PageBody>
            </Layout>
        </>
    );
};

export default NotFound;
