import React, { useEffect, useState } from 'react';
import parse, { Element } from 'html-react-parser';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { useAppSelector } from '../../hooks/reduxHooks';
import './MVTaxes.scss';
import { MVTax } from '../../models/MVTax';
import { CurrentMarketNameInfo, MvTaxesAndDutiesInfo } from '../../models/ReduxModels';
import Table from '../../controls/table/Table';

interface MVTaxesProps {
    data: {
        item: any;
        linkedItems: IContentItemsContainer;
    };
    match: any;
    type?: string;
}

const MVTaxes = (props: MVTaxesProps) => {
    const [taxesData, setTaxesData] = useState<MVTax[]>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [showTaxesComponent, setShowTaxesComponent] = useState<boolean>(true);
    const mvTaxesAndDutiesInfo = useAppSelector<MvTaxesAndDutiesInfo>((state) => state.mvLawsAndRegs.mvTaxesAndDutiesInfo);
    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);
    const sector = props.match.params.sector;
    const [marketName, setMarketName] = useState<string>(null);

    //#region lifecycle
    useEffect(() => {
        if (mvTaxesAndDutiesInfo.retrieved) {
            const measureTaxesSectionTemp = mvTaxesAndDutiesInfo.data?.Taxes?.length > 0;

            if (!measureTaxesSectionTemp) {
                setShowTaxesComponent(false);
            } else {
                setTaxesData(mvTaxesAndDutiesInfo.data?.Taxes);
            }
        }
    }, [mvTaxesAndDutiesInfo.retrieved]);

    useEffect(() => {
        if (currentMarketNameInfo.retrieved) {
            setMarketName(currentMarketNameInfo.data);
        }
    }, [currentMarketNameInfo.retrieved]);

    useEffect(() => {
        if (mvTaxesAndDutiesInfo.retrieved && currentMarketNameInfo.retrieved) {
            setLoading(false);
        }
    }, [mvTaxesAndDutiesInfo.retrieved, currentMarketNameInfo.retrieved]);
    //#endregion

    const replaceEmbededObject = (domNode: Element) => {
        if (domNode.name === 'table') {
            return <Table table={domNode as Element} />;
        }
    };

    const generateTaxesTableContentHtml = () => {
        return `<table><tr><td>Type of tax</td><td>Amount</td></tr>${taxesData
            .map((t, i) => `<tr><td>${t.TypeLabel} (${t.Abrev})</td><td>${t.Label}</td></tr>`)
            .join('')}</table>`;
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            {showTaxesComponent && taxesData && (
                <div>
                    <h3>
                        Taxes for {sector} to {marketName}
                    </h3>
                    {parse(generateTaxesTableContentHtml(), {
                        replace: replaceEmbededObject,
                    })}
                </div>
            )}
        </LoadingOverlay>
    );
};

export default MVTaxes;
