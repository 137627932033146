import { instantiateKenticoClient } from '@exporter-services/common-ui/src/utils/contentHelpers';

export const kenticoProjectId = window['env'].KenticoProjectId as string;
const kenticoUsePreviewMode = window['env'].KenticoUsePreviewMode as boolean;
const kenticoPreviewApiKey = window['env'].KenticoPreviewApiKey as string;

export const client = instantiateKenticoClient(kenticoProjectId, kenticoUsePreviewMode, kenticoPreviewApiKey);

export const isSiteSearchEnabled = window['env'].FeatureManagementEnableSiteSearch as boolean;
export const isExpansionWorkEnabled = window['env'].FeatureManagementEnableExpansionWork as boolean;
