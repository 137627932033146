import React, { useState, useContext } from 'react';
import './XptExportPlanTemplateWizardContainer.scss';
import { Row, Col } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ComButton, ContentItem } from '@exporter-services/common-ui';
import { personalisationContext } from '../../providers/personalisation';
import { setIndustrySectorHsCodeMarket } from '../../providers/personalisation/reducer';
import XptSteppedNavigation from './XptSteppedNavigation';
import ExportPlanStep1 from './XptExportPlanStep1';
import ExportPlanStep2 from './XptExportPlanStep2';
import ExportPlanStep3 from './XptExportPlanStep3';

interface XptExportPlanTemplateWizardContainerProps {
    match?: any;
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
}

const XptExportPlanTemplateWizardContainer = (props: XptExportPlanTemplateWizardContainerProps) => {
    const { personalisationState, dispatch } = useContext(personalisationContext);

    const [step, setStep] = useState<number>(1);
    const [steppedNavStep, setSteppedNavStep] = useState<number>(step);
    const [industryCode, setIndustryCode] = useState<string>(personalisationState.industryCode);
    const [sectorCode, setSectorCode] = useState<string>(personalisationState.sectorCode);
    const [hsCode, setHsCode] = useState<string>(personalisationState.hsCode);
    const [marketCode, setMarketCode] = useState<string>(personalisationState.marketCode);
    const [isBackAvailable, setIsBackAvailable] = useState<boolean>(false);
    const [isNextAvailable, setIsNextAvailable] = useState<boolean>(false);
    const [buttonsHidden, setButtonsHidden] = useState<boolean>(false);

    const gotoPreviousStep = () => {
        setStep(step - 1);
        setSteppedNavStep(step - 1);
    };

    const gotoNextStep = () => {
        dispatch(setIndustrySectorHsCodeMarket(industryCode, sectorCode, hsCode, marketCode));
        setStep(step + 1);
        setSteppedNavStep(step + 1);
    };

    const renderButton = (type: string) => {
        const isBack = type.toUpperCase() === 'BACK';
        const id = isBack ? 'back' : 'next';
        const label = isBack ? <span>Previous</span> : <span>Next</span>;
        return isBack && isBackAvailable ? (
            <ComButton type={'button'} id={id} onClick={gotoPreviousStep} aria-pressed="false" variant="link">
                {label}
            </ComButton>
        ) : isBack && step === 1 ? null : !isBack && step === 3 ? null : !isBack && isNextAvailable ? (
            <ComButton id={id} onClick={gotoNextStep}>
                {label}
            </ComButton>
        ) : null;
    };

    const renderXptSteppedNavigation = () => {
        return <XptSteppedNavigation data={{ item: props.data.item }} activeStep={steppedNavStep} />;
    };

    const renderStep1 = () => {
        return step === 1 ? (
            <ExportPlanStep1
                data={{ item: props.data.item }}
                industryCode={industryCode}
                sectorCode={sectorCode}
                hsCode={hsCode}
                setIndustryCode={setIndustryCode}
                setSectorCode={setSectorCode}
                setHsCode={setHsCode}
                setMarketCode={setMarketCode}
                setIsBackAvailable={setIsBackAvailable}
                setIsNextAvailable={setIsNextAvailable}
            />
        ) : (
            <></>
        );
    };

    const renderStep2 = () => {
        return step === 2 ? (
            <ExportPlanStep2
                data={{ item: props.data.item }}
                marketCode={marketCode}
                setMarketCode={setMarketCode}
                setIsBackAvailable={setIsBackAvailable}
                setIsNextAvailable={setIsNextAvailable}
            />
        ) : (
            <></>
        );
    };

    const renderStep3 = () => {
        return step === 3 ? (
            <ExportPlanStep3
                data={{ item: props.data.item, linkedItems: props.data.linkedItems }}
                match={props.match}
                setIsBackAvailable={setIsBackAvailable}
                setButtonsHidden={setButtonsHidden}
                setFinalStepCompletion={() => setSteppedNavStep(steppedNavStep + 1)}
            />
        ) : (
            <></>
        );
    };

    return (
        <div className="exportplantemplatebuilderwizardcontainer">
            {renderXptSteppedNavigation()}
            {renderStep1()}
            {renderStep2()}
            {renderStep3()}

            {!buttonsHidden && (
                <Row>
                    <Col className="button-container">
                        {renderButton('Back')}
                        {renderButton('Next')}
                    </Col>
                </Row>
            )}
        </div>
    );
};

export default XptExportPlanTemplateWizardContainer;
