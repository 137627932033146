import React, { useState, useEffect, useContext } from 'react';
import './CrmFormPlaceholder.scss';
import { client } from '../../config';
import { personalisationContext } from '../../providers/personalisation';
import CrmForm from '../../controls/Crm/CrmForm';

interface CrmFormPlaceholderProps {
    data: {
        item: any;
    };
}

const CrmFormPlaceholder = (props: CrmFormPlaceholderProps) => {
    const [crmForm, setCrmForm] = useState<object>(null);
    const { personalisationState } = useContext(personalisationContext);
    useEffect(() => {
        client
            .items()
            .equalsFilter('system.type', 'crm_form')
            .containsFilter('elements.industry_sector', [personalisationState.sectorCode])
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                if (response.data.items && response.data.items.length > 0) {
                    setCrmForm(response.data.items[0]);
                }
            });
    }, []);

    return <div className="crmFormPlaceholder">{crmForm && <CrmForm data={{ item: crmForm }} />}</div>;
};

export default CrmFormPlaceholder;
