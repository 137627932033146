import { CommoditiesInfo } from '../../models/ReduxModels';
import GlobalState, { CommodityState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initState: CommodityState = {
    commoditiesInfo: { retrieved: false, pending: false },
};

const commoditySlice = createSlice({
    name: 'commodity',
    initialState: initState,
    reducers: {
        pendingCommoditiesInfo(state) {
            state.commoditiesInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCommodities.fulfilled, (state, action) => {
            state.commoditiesInfo = action.payload as CommoditiesInfo;
        });

        builder.addCase(getCommoditiesWithStrongMarkets.fulfilled, (state, action) => {
            state.commoditiesInfo = action.payload as CommoditiesInfo;
        });
    },
});

export const getCommodities = createAsyncThunk('commodities/getCommodities', async (request: { queryString: string }, thunkAPI) => {
    thunkAPI.dispatch(commoditySlice.actions.pendingCommoditiesInfo());
    return await new Promise<CommoditiesInfo>((resolve) => {
        (thunkAPI.getState() as GlobalState).singletonServices.commodityService
            .getCommodities(request.queryString)
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

export const getCommoditiesWithStrongMarkets = createAsyncThunk(
    'commodities/getCommoditiesWithStrongMarkets',
    async (request: { queryString: string }, thunkAPI) => {
        thunkAPI.dispatch(commoditySlice.actions.pendingCommoditiesInfo());

        return await new Promise<CommoditiesInfo>((resolve) => {
            (thunkAPI.getState() as GlobalState).singletonServices.commodityService
                .getCommoditiesWithStrongMarkets(request.queryString)
                .then((resp) => {
                    resolve({ retrieved: true, pending: false, data: resp });
                })
                .catch(() => {
                    resolve({ retrieved: true, pending: false });
                });
        });
    },
);

const commodityReducer = commoditySlice.reducer;

export default commodityReducer;
