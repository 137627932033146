import React from 'react';
import { DeliveryClient, Elements } from '@kontent-ai/delivery-sdk';
import './ImagePathway.scss';
import { ImagePathwayItem } from './ImagePathwayItem';
import ComPathwayAnchor from '../../base/ComPathwayAnchor';
import ComImage from '../../base/ComImage';
import { Col, Row } from 'reactstrap';

export interface ImagePathwayProps {
    data: {
        content: ImagePathwayItem[];
        parentHeadingLevel?: string;
    };
    client: DeliveryClient;
}

const ImagePathway = (props: ImagePathwayProps) => {
    return (
        <Row className="image-pathway-list full-width">
            {props.data.content.map((item: ImagePathwayItem) => (
                <Col lg={4} sm={6} className="image-pathway-list-block" key={item.system.id}>
                    <div className="image-pathway-anchor-wrapper">
                        <ComPathwayAnchor
                            data={{
                                pathway_link: item.elements.pathway__link as Elements.RichTextElement,
                            }}
                            client={props.client}
                            className={'image-pathway-anchor'}
                        >
                            <div className="image-pathway-list-button image-pathway-list-wrapper">
                                <div className="image-pathway-list-flexItem">
                                    <ComImage src={item.elements.image.value[0].url} alt={item.elements.image.value[0].description} />
                                </div>
                                <div className="image-pathway-list-body">
                                    <div className="image-pathway-list-content">
                                        {props.data.parentHeadingLevel === 'h3' ? (
                                            <h4>{item.elements.title.value}</h4>
                                        ) : (
                                            <h3>{item.elements.title.value}</h3>
                                        )}
                                    </div>
                                    <div
                                        className="image-pathway-list-description"
                                        dangerouslySetInnerHTML={{ __html: item.elements.description.value }}
                                    ></div>
                                    <div className="image-pathway-list-footer">
                                        <div className="image-pathway-list-icon">
                                            <span className="arrow-right-white-icon"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ComPathwayAnchor>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default ImagePathway;
