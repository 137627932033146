import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommodityMicorLinkResult } from '../../models/CommodityMicorLinkResult';
import { Commodity } from '../../models/Commodity';

export const commoditiesApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api/commodities/' }),
    reducerPath: 'commoditiesApi',
    endpoints: (build) => ({
        getMicorLinksForCommodities: build.query<CommodityMicorLinkResult, { sector: string; market: string }>({
            query: ({ sector, market }) => `countries/${market.toUpperCase()}/products/${sector}/micorlinks`,
            transformResponse: (response: CommodityMicorLinkResult) => response,
        }),

        getHSCodeLabel: build.query<Commodity, { hsCode: string }>({
            query: ({ hsCode }) => `GetHSCodeAndLabel?query=${hsCode}`,
            transformResponse: (response: Commodity[]) => {
                if (response.length === 0 || !response[0].LabelWithoutCode) {
                    return null;
                }

                return { Code: response[0].Code, Label: response[0].Label, LabelWithoutCode: response[0].LabelWithoutCode };
            },
        }),
    }),
});

export const { useGetMicorLinksForCommoditiesQuery, useGetHSCodeLabelQuery } = commoditiesApi;

export default commoditiesApi.reducer;
