import { useContext, useEffect, useState } from 'react';
import { personalisationContext } from '../providers/personalisation';
import useGetUrlIndustryAndSector from './useGetUrlIndustryAndSector';
import { IndustryOption } from '../models/IndustrySector';
import { CommonConstants } from '../CommonConstants';
import { PersonalisationStateInterface } from '../providers/personalisation/types';
import { isStringNullOrUndefined, STRING_EMPTY } from '@exporter-services/common-ui';

export type ValidateOnLoadStoredPersonalisation = {
    isProcessed: boolean;
    isValid: boolean;
    isDefault: boolean;
    isEmpty: boolean;
};

const getIndustryOption = (industryOptionList: IndustryOption[], industry: string) => {
    return industryOptionList.find((ind) => ind.codeName === industry);
};

const getSectorOption = (industryOptionList: IndustryOption[], industry: string, sector: string) => {
    const industryOption = getIndustryOption(industryOptionList, industry);
    if (!industryOption) {
        return undefined;
    }

    return industryOption.sectors.find((sec) => sec.codeName === sector);
};

const isStoredIndustryAndSectorInIndustryList = (industryOptionList: IndustryOption[], personalisationState: PersonalisationStateInterface) => {
    const industryOption = getIndustryOption(industryOptionList, personalisationState.industryCode);
    const sectorOption = getSectorOption(industryOptionList, personalisationState.industryCode, personalisationState.sectorCode);

    return !(!industryOption || (!sectorOption && personalisationState.sectorCode !== industryOption.codeName));
};

const isBlankStoredPersonalisationState = (personalisationState: PersonalisationStateInterface) =>
    !isStringNullOrUndefined(personalisationState.industryCode) &&
    personalisationState.industryCode === STRING_EMPTY &&
    !isStringNullOrUndefined(personalisationState.sectorCode) &&
    personalisationState.sectorCode === STRING_EMPTY;

const isDefaultStoredPersonalisationState = (personalisationState: PersonalisationStateInterface) =>
    personalisationState.industryCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME &&
    personalisationState.sectorCode === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME;

export default function useValidatePersonalisedPageOnLoadStoredPersonalisation(
    industryOptionList: IndustryOption[],
    industryOptionListRetrieved: boolean,
): ValidateOnLoadStoredPersonalisation {
    const { personalisationState } = useContext(personalisationContext);
    const getUrlIndustryAndSector = useGetUrlIndustryAndSector();
    const [isProcessed, setIsProcessed] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean>(false);
    const [isDefault, setIsDefault] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);

    useEffect(() => {
        if (isProcessed || !getUrlIndustryAndSector.isProcessed || !industryOptionListRetrieved) {
            return;
        }

        if (getUrlIndustryAndSector.isSupplied) {
            setIsProcessed(true);
            return;
        }

        if (!isStringNullOrUndefined(personalisationState.industryCode)) {
            if (isStoredIndustryAndSectorInIndustryList(industryOptionList, personalisationState)) {
                setIsValid(true);
            } else if (isDefaultStoredPersonalisationState(personalisationState)) {
                setIsDefault(true);
            } else if (isBlankStoredPersonalisationState(personalisationState)) {
                setIsEmpty(true);
            }
        }

        setIsProcessed(true);
    }, [
        getUrlIndustryAndSector.isProcessed,
        getUrlIndustryAndSector.isSupplied,
        personalisationState.industryCode,
        personalisationState.sectorCode,
        industryOptionListRetrieved,
    ]);

    return { isProcessed, isValid, isDefault, isEmpty };
}
