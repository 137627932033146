import React from 'react';
import './ProTip.scss';
import { ProTipItem } from './ProTipItem';
import ResolveLink from '../resolveLink/ResolveLink';
import { filterContentItemAgainstUrlParams } from '../../utils/utils';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';
import { useUrlParams } from '../../hooks/useUrlParams';

interface ProTipProps {
    data: {
        item: ProTipItem;
    };
    match?: any;
}

const ProTip = (props: ProTipProps) => {
    const urlCodes = useUrlParams(props.match);
    const proTipItem = filterContentItemAgainstUrlParams(props.data.item, urlCodes);

    if (!proTipItem) return null;

    return (
        <div className="protip-wrapper pb-5">
            <div className="protip feature-box m-0">
                <div className="protip-content py-4 ps-4 pe-8  h-100">
                    <div className="icon-wrapper me-3 mb-3">
                        <span className="light-bulb-icon"></span>
                    </div>
                    <div className="content-text no-space-under-last-child">
                        <HeadingLevelResolver
                            data={{
                                headingLevel: proTipItem.elements.headinglevelsnippet__level,
                                titleText: proTipItem.elements?.title?.value ?? 'Pro tip',
                            }}
                        />
                        <ResolveLink data={{ item: proTipItem }} type={proTipItem.system.type} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProTip;
