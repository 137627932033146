import { ITaxonomyGroup } from '@kontent-ai/delivery-sdk';
import { client } from '../config';
import HttpServiceBase from '../services/HttpServiceBase';
import { ContentItem } from '@exporter-services/common-ui';

export default class DeliveryService extends HttpServiceBase {
    public getAll<T extends ContentItem>(type: string): Promise<T[]> {
        return client
            .items<T>()
            .type(type)
            .toPromise()
            .then((response) => response.data.items)
            .catch((error) => this.handleError(error));
    }

    public getByUrl<T extends ContentItem>(url: string): Promise<T> {
        return client
            .items<T>()
            .equalsFilter('elements.url_pattern', url)
            .depthParameter(1)
            .toPromise()
            .then((response) => response.data.items[0])
            .catch((error) => this.handleError(error));
    }

    public getTaxonomyGroup(taxonomyGroupCode: string): Promise<ITaxonomyGroup> {
        return client
            .taxonomy(taxonomyGroupCode)
            .toPromise()
            .then((response) => response.data.taxonomy)
            .catch((error) => this.handleError(error));
    }
}
