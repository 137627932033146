import React, { useEffect, useState } from 'react';
import MarketSearchPathway from '../marketSearchPathway/MarketSearchPathway';
import './ResultsContainer.scss';
import { Row, Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { CurrentMarketSearchToolSearchData, ProductServiceSelectionInfo } from '../../models/ReduxModels';
import { MarketSearchRequest, MarketSearchToolSearchType } from '../../services/MarketService';
import { StrongMarket } from '../../models/StrongMarket';
import { ContentItem } from '@exporter-services/common-ui';
import { getMarketSearchToolSearchData } from '../../providers/reducers/marketSlice';

interface ResultsContainerProps {
    match?: any;
    data: {
        item: any;
    };
    onLoad?: (available: boolean, title: string) => void;
}

const ResultsContainer = (props: ResultsContainerProps) => {
    const [results, setResults] = useState<any>();
    const STRONG_MARKET_CODENAME = 'strong_market';
    const productsServicesSelectionState = useAppSelector<ProductServiceSelectionInfo>(
        (state) => state.productServiceSelection.productServiceSelectionInfo,
    );
    const marketSearchData = useAppSelector<CurrentMarketSearchToolSearchData>((state) => state.market.currentMarketSearchResultsInfo);

    const dispatch = useAppDispatch();

    function handleStrongMarkets() {
        const strongMarkets = marketSearchData.results as StrongMarket[];
        props.onLoad(strongMarkets.length && strongMarkets.length > 0, '');
        let newResultArray = strongMarkets.map((b: any) => ({
            market: b.CountryCode,
            description: 'Get tailored trade data and insights to help you export to this market.',
            rank: b.Rank,
        }));
        let rank = newResultArray.slice(0);
        rank.sort(function (a, b) {
            return a.rank - b.rank;
        });
        setResults(rank);
    }

    function handleMarkets() {
        const markets = marketSearchData.results as ContentItem[];
        props.onLoad(markets.length && markets.length > 0, '');
        let newResultArray = markets.map((b: any) => ({
            market:
                b.elements.marketcodesnippet__market?.value && b.elements.marketcodesnippet__market?.value.length > 0
                    ? b.elements.marketcodesnippet__market?.value[0].codename
                    : '',
            description: b.elements.abstract.value,
        }));
        let alpha = newResultArray.slice(0);
        alpha.sort(function (a, b) {
            return a.market - b.market;
        });
        setResults(alpha);
    }

    useEffect(() => {
        let sector = props.match?.params?.sector
            ? props.match?.params?.sector?.replace(/-/g, '_')
            : props.match?.params?.industry
              ? props.match?.params?.industry?.replace(/-/g, '_')
              : productsServicesSelectionState.hsCode
                ? productsServicesSelectionState.hsCode
                : productsServicesSelectionState.sectorCode;

        if (!sector) {
            return;
        }

        if (props.data.item.elements.type.value[0].codename === STRONG_MARKET_CODENAME) {
            let request: MarketSearchRequest = new MarketSearchRequest();
            request.Type = MarketSearchToolSearchType.StrongMarkets;
            request.StrongMarketSearchRequest = { Commodity: sector };
            dispatch(getMarketSearchToolSearchData(request));
        } else {
            let request: MarketSearchRequest = new MarketSearchRequest();
            request.Type = MarketSearchToolSearchType.Markets;
            request.MarketSearchRequest = { Sector: sector };
            dispatch(getMarketSearchToolSearchData(request));
        }
    }, [
        props.data.item.elements.type.value[0].codename,
        props.match?.params.industry,
        props.match?.params.sector,
        productsServicesSelectionState.hsCode,
        productsServicesSelectionState.sectorCode,
        productsServicesSelectionState.industryCode,
        productsServicesSelectionState.market,
    ]);

    useEffect(() => {
        if (!!!marketSearchData) {
            return;
        }

        switch (marketSearchData.type) {
            case MarketSearchToolSearchType.StrongMarkets:
                handleStrongMarkets();
                break;

            case MarketSearchToolSearchType.Markets:
                handleMarkets();
                break;
        }
    }, [marketSearchData?.timeTag, marketSearchData?.type]);

    return (
        <Row className="results-container">
            <Col>
                {results && (
                    <MarketSearchPathway
                        data={{ content: results }}
                        strongMarkets={props.data.item.elements.type.value[0].codename === STRONG_MARKET_CODENAME}
                        match={props.match}
                    />
                )}
            </Col>
        </Row>
    );
};
export default ResultsContainer;
