import React, { useCallback, useEffect, useState } from 'react';
import { IContentItem, IContentItemsContainer, Responses } from '@kontent-ai/delivery-sdk';
import { client } from '../../config';
import BodyCopy from '../../controls/bodyCopy/BodyCopy';
import { BodyCopyItem } from '../../controls/bodyCopy/BodyCopyItem';
import { useAppSelector } from '../../hooks/reduxHooks';
import { LawsAndRegsDataSourceInfo } from '../../models/ReduxModels';
import MVTariffs from '../../domains/lar/MVTariffs';
import MVTaxes from '../../domains/lar/MVTaxes';
import RulesAndRequirementsDocumentList from './RulesAndRequirementsDocumentList';
import RulesAndRestrictionsDocumentsOffshore from './RulesAndRestrictionsDocumentsOffshore';
import RulesAndRestrictionsDocument from './rulesAndRestrictionsDocument/RulesAndRestrictionsDocument';
import MicorProhibitedExportRequirements from './MicorProhibitedExportRequirements';
import MicorSpecificExportRequirements from './MicorSpecificExportRequirements';
import { LawsAndRegsPageService } from '../../services/LawsAndRegsPageService';

interface LawsAndRegulationsResourceProps {
    data: {
        item: any;
        linkedItems: IContentItemsContainer;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
    type: string;
}

const getContentBySystemTypeAndTaxonomyCode = (systemType: string, taxonomyCodeName: string) => {
    return client
        .items()
        .equalsFilter('system.type', systemType)
        .containsFilter('elements.resource_type', [taxonomyCodeName])
        .depthParameter(5)
        .toPromise();
};

const getLawsAndRegsResourceContent = (taxonomyCodeName: string) => {
    return getContentBySystemTypeAndTaxonomyCode('laws_and_regs_resource', taxonomyCodeName);
};

const LawsAndRegulationsResource = (props: LawsAndRegulationsResourceProps) => {
    const { third_slug } = props.match.params;

    const [content, setContent] = useState<IContentItem>(null);

    const lawsAndRegsService = useAppSelector<LawsAndRegsPageService>((state) => state.singletonServices.lawsAndRegsService);
    const lawsAndRegsDataSourceInfo = useAppSelector<LawsAndRegsDataSourceInfo>((state) => state.lawsAndRegs.dataSourceInfo);
    const taxonomyCodeName = props.data.item?.elements.laws_and_regulations_resource_type.value[0]?.codename;
    const isDutiesAndTaxesPage = lawsAndRegsService.isDutiesAndTaxesPage(third_slug);

    const handleOnLoadComponent = useCallback((dataAvailable: boolean, title: string) => {
        //inject the title of content item here to bounce back
        //up the chain in the callback and correctly set
        //the navigation link visibility
        return props.onLoad?.(dataAvailable, title);
    }, []);

    const handleGetContentResponse = useCallback(
        (response: Responses.IListContentItemsResponse) => {
            setContent(response.items[0]);
            handleOnLoadComponent?.(response.items.length > 0, response.items.length > 0 ? response.items[0].elements.title.value : '');
        },
        [handleOnLoadComponent],
    );

    useEffect(() => {
        const onLoad = props.onLoad;
        if ((taxonomyCodeName === 'tariffs' || taxonomyCodeName === 'taxes') && lawsAndRegsDataSourceInfo.retrieved) {
            client
                .items()
                .equalsFilter('system.type', 'laws_and_regs_resource')
                .containsFilter('elements.resource_type', [taxonomyCodeName])
                .depthParameter(5)
                .toPromise()
                .then((response) => {
                    setContent(response.data.items[0]);
                    onLoad?.(response.data.items.length > 0, response.data.items.length > 0 ? response.data.items[0].elements.title.value : '');
                });
        }
    }, [taxonomyCodeName, lawsAndRegsDataSourceInfo.retrieved]);

    const taxonomyCodeNamesForDefaultKenticoFetch: string[] = [
        'rules_and_restrictions_document',
        'export_requirements_documents_offshore',
        'micor_specific_export_requirements',
        'micor_prohibited_export_requirements',
        'export_requirements_specific_requirements_document_list',
    ];

    useEffect(() => {
        if (!taxonomyCodeNamesForDefaultKenticoFetch.includes(taxonomyCodeName)) {
            return;
        }
        getLawsAndRegsResourceContent(taxonomyCodeName).then((response) => handleGetContentResponse(response.data));
    }, [taxonomyCodeName, handleGetContentResponse]);

    return (
        <>
            {(taxonomyCodeName === 'importing_country_requirements' && content && (
                <BodyCopy
                    data={{ item: content as BodyCopyItem, linkedItems: props.data.linkedItems }}
                    match={props.match}
                    displayTitle={false}
                    type={taxonomyCodeName}
                />
            )) ||
                (taxonomyCodeName === 'tariffs' && content && lawsAndRegsDataSourceInfo.retrieved && (
                    <MVTariffs
                        data={{ item: content as BodyCopyItem, linkedItems: props.data.linkedItems }}
                        match={props.match}
                        type={taxonomyCodeName}
                    />
                )) ||
                (taxonomyCodeName === 'rules_and_restrictions_link' && content && lawsAndRegsDataSourceInfo.retrieved && <></>) ||
                (taxonomyCodeName === 'rules_and_restrictions_link__offshore_' && content && lawsAndRegsDataSourceInfo.retrieved && <></>) ||
                (taxonomyCodeName === 'taxes' && isDutiesAndTaxesPage && content && lawsAndRegsDataSourceInfo.retrieved && (
                    <MVTaxes
                        data={{ item: content as BodyCopyItem, linkedItems: props.data.linkedItems }}
                        match={props.match}
                        type={taxonomyCodeName}
                    />
                )) ||
                (taxonomyCodeName === 'export_requirements_specific_requirements_document_list' && content && (
                    <RulesAndRequirementsDocumentList
                        data={{
                            item: content,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                    />
                )) ||
                (taxonomyCodeName === 'export_requirements_documents_offshore' && content && lawsAndRegsDataSourceInfo.retrieved && (
                    <RulesAndRestrictionsDocumentsOffshore
                        data={{
                            item: content,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                    />
                )) ||
                (taxonomyCodeName === 'rules_and_restrictions_document' && content && lawsAndRegsDataSourceInfo.retrieved && (
                    <RulesAndRestrictionsDocument match={props.match} />
                )) ||
                (taxonomyCodeName === 'micor_specific_export_requirements' && content && (
                    <MicorSpecificExportRequirements
                        data={{
                            item: content,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                        type={taxonomyCodeName}
                    />
                )) ||
                (taxonomyCodeName === 'micor_prohibited_export_requirements' && content && (
                    <MicorProhibitedExportRequirements
                        data={{
                            item: content,
                            linkedItems: props.data.linkedItems,
                        }}
                        match={props.match}
                        type={taxonomyCodeName}
                    />
                )) || <></>}
        </>
    );
};

export default LawsAndRegulationsResource;
