import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import BackArrow from '../../assets/backArrow.svg';
import { isExpansionWorkEnabled } from '../../config';
import AccountMenu from '../../controls/menus/AccountMenu';
import { MenuItemData } from '../../controls/menus/MenuItemData';

interface FeedbackBannerProps {
    data: {
        menu: Array<MenuItemData>;
    };
}

const FeedbackBanner = (props: FeedbackBannerProps) => {
    const xsWidth = isExpansionWorkEnabled ? 10 : 12;
    const smFeedback = isExpansionWorkEnabled ? 7 : 8;
    const mdFeedback = isExpansionWorkEnabled ? 6 : 7;

    return (
        <div data-testid="feedback">
            <Container fluid className="feedback-banner d-print-none">
                <Container>
                    <Row>
                        <Col xs={xsWidth} sm={4} md={5} className="businessgovau-holder ">
                            <ul className="p-0 m-0">
                                <li className="businessgovau-link p-0">
                                    <a aria-label="Australian Government logo. Business, business.gov.au" href="https://www.business.gov.au/">
                                        <img src={BackArrow} alt="Back arrow" />
                                        <strong>business</strong>.gov.au
                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col xs={xsWidth} sm={smFeedback} md={mdFeedback} className="feedback-holder text-sm-end">
                            <ul className="p-0 m-0">
                                <li className="feedback-link p-0">
                                    Your{' '}
                                    <a
                                        href="https://surveys.hotjar.com/de6d04cd-9c3c-4778-b98f-4c1be68a25e9"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        title="survey"
                                        data-testid="survey"
                                    >
                                        feedback<span className="sr-only">Opens in new window</span>
                                    </a>{' '}
                                    can improve this site.
                                </li>
                            </ul>
                        </Col>
                        {isExpansionWorkEnabled && (
                            <Col xs={2} sm={1} className="pb-4 pb-sm-0 ps-0 align-content-center account-menu-container">
                                <AccountMenu data={{ menu: props.data?.menu }} />
                            </Col>
                        )}
                    </Row>
                </Container>
            </Container>
        </div>
    );
};

export default FeedbackBanner;
