import { createSlice } from '@reduxjs/toolkit';
import { SingletonServicesState } from '../globalState/globalState';
import { LawsAndRegsPageService } from '../../services/LawsAndRegsPageService';
import { PageService } from '../../services/PageService';
import { MVLawsAndRegsService } from '../../services/MVLawsAndRegsService';
import { MarketService } from '../../services/MarketService';
import { UserService } from '../../services/UserService';
import { NewsFilterService } from '../../services/NewsFilterService';
import { TaxonomyService } from '../../services/TaxonomyService';
import { CommodityService } from '../../services/CommodityService';
import { ExportPlanService } from '../../services/ExportPlanService';

const initialState: SingletonServicesState = {
    lawsAndRegsService: new LawsAndRegsPageService(),
    pageService: new PageService(),
    mvLawsAndRegsService: new MVLawsAndRegsService(),
    marketService: new MarketService(),
    userService: new UserService(),
    newsFilterService: new NewsFilterService(),
    taxonomyService: new TaxonomyService(),
    commodityService: new CommodityService(),
    exportPlanService: new ExportPlanService(),
};

const singletonServicesSlice = createSlice({
    name: 'singletonServices',
    initialState: initialState,
    reducers: {},
});

const singleServicesReducer = singletonServicesSlice.reducer;

export default singleServicesReducer;
