import React from 'react';
import './InlinePathway.scss';
import { InlinePathwayItem } from './InlinePathwayItem';
import { DeliveryClient, Elements } from '@kontent-ai/delivery-sdk';
import { getLinkOpenTypeString } from '../../../utils/linkOpenTypeHelper';
import ComPathwayAnchor from '../../base/ComPathwayAnchor';

interface InlinePathwayProps {
    data: {
        content: InlinePathwayItem[];
    };
    client: DeliveryClient;
}

const InlinePathway = (props: InlinePathwayProps) => {
    return props.data.content?.length > 0 ? (
        <>
            {props.data.content.map((a) => (
                <div key={a.system.id}>
                    <ComPathwayAnchor
                        data={{
                            pathway_link: a.elements.pathway__link as Elements.RichTextElement,
                        }}
                        target={getLinkOpenTypeString(a)}
                        className="inline-pathway"
                        client={props.client}
                    >
                        <div className="inline-container">
                            <div className="inline-wrapper">
                                <div className="description" dangerouslySetInnerHTML={{ __html: a.elements.description.value }}></div>
                                <div className="icon-link">
                                    <span className="icon arrow-right-blue-icon"></span>
                                    <span className="text">{a.elements.title.value}</span>
                                </div>
                            </div>
                        </div>
                    </ComPathwayAnchor>
                </div>
            ))}
        </>
    ) : null;
};

export default InlinePathway;
