import React from 'react';
import { PageOrchestratorProps } from '../../models/PageOrchestratorProps';
import useUserGeolocation from '../../hooks/useUserGeolocation';
import useDutiesandtaxesLawsAndRegsPageOrchestrator from '../../hooks/useDutiesandtaxesLawsAndRegsPageOrchestrator';

const LawAndRegsPageOrchestrator = (props: PageOrchestratorProps) => {
    useUserGeolocation();
    useDutiesandtaxesLawsAndRegsPageOrchestrator(props);

    return <></>;
};

export default LawAndRegsPageOrchestrator;
