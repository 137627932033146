import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';

const getParent = (codeName: string, linkedItems: IContentItemsContainer, codeNames: Array<string>): string => {
    for (const [key, value] of Object.entries(linkedItems)) {
        if (value?.elements.description?.value.linkedItemCodenames?.includes(codeName)) {
            if (codeNames?.includes(key)) {
                return key;
            } else {
                return getParent(key, linkedItems, codeNames);
            }
        }
    }
    return '';
};

export const getContainerParentName = (codeName: String, linkedItems: IContentItemsContainer, parentLevelCodeNames: Array<string>): Array<string> => {
    let codeNames = Array<string>();
    for (const key in linkedItems) {
        if (
            linkedItems[key]?.elements.laws_and_regulations_resource_type?.value.length > 0 &&
            linkedItems[key]?.elements.laws_and_regulations_resource_type?.value[0].codename === codeName
        ) {
            codeNames.push(linkedItems[key].system.codename);
        }
    }
    let parentCodeNames = Array<string>();
    for (const val of codeNames) {
        const parentName = getParent(val, linkedItems, parentLevelCodeNames);
        parentCodeNames.push(parentName);
    }
    return parentCodeNames;
};
