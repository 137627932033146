import * as React from 'react';
import './utilityItem.scss';
import { ComButton } from '@exporter-services/common-ui';

interface UtilityItemProps {
    title: string;
    subject: string;
    link: string;
    icon: string;
}

function handleEmailClick(subject: string, link: string) {
    window.location.href =
        'mailto:?subject=' +
        subject +
        ' | export.business.gov.au&body=I thought you might be interested in ' +
        subject +
        ' | export.business.gov.au. You can view this article: ' +
        encodeURIComponent(link);
}

const UtilityItem = (props: UtilityItemProps) => {
    const onPrintClick = (event: any) => {
        event.preventDefault();
        window.print();
    };
    const renderEmailShare = (subject: string, link: string) => {
        return (
            <ComButton variant="link" className="utility-button email-button" onClick={() => handleEmailClick(subject, link)}>
                <span>{props.title}</span>
            </ComButton>
        );
    };
    return (
        <div className="utility-item">
            {props.title === 'Email' ? (
                renderEmailShare(props.subject, props.link)
            ) : (
                <ComButton variant="link" className="utility-button print-button" onClick={(e) => onPrintClick(e)}>
                    <span>{props.title}</span>
                </ComButton>
            )}
        </div>
    );
};

export default UtilityItem;
