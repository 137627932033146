export const getLinkOpenTypeString = (a: any) => {
    let linkOpenType;
    if (a.elements.link_open_type.value?.[0]?.codename) {
        linkOpenType = a.elements.link_open_type.value[0].codename;
    }
    if (linkOpenType && linkOpenType === 'new_window') {
        return '_blank" rel="noopener noreferrer"';
    } else {
        return '_self';
    }
};

export const getLinkOpenTypeWithDefaultReturnString = (a: any) => {
    let linkOpenType;
    if (a.elements.link_open_type.value?.[0]?.codename) {
        linkOpenType = a.elements.link_open_type.value[0].codename;
    }
    if (linkOpenType && linkOpenType === 'new_window') {
        return '_blank" rel="noopener noreferrer"';
    } else if (linkOpenType && linkOpenType === 'same_window') {
        return '_self';
    } else {
        return '_blank" rel="noopener noreferrer"';
    }
};
