import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import './HeroPathway.scss';
import { HeroPathwayItem } from './HeroPathwayItem';
import ComPathwayAnchor from '../../base/ComPathwayAnchor';
import { ContentItemElementsIndexer, DeliveryClient, Elements } from '@kontent-ai/delivery-sdk';
import ComImage from '../../base/ComImage';
import { HeroPathwayColorMapping, heroPathwayColorMappings } from './HeroPathwayColorMapping';
import { ColorThemeEnum } from '../../../models/enums';
import { Colors } from '../../../styles';
import { getThemeColourFromContentItem } from '../../../utils/themeHelper';

interface HeroPathwayProps {
    data: {
        content: HeroPathwayItem[];
        parentHeadingLevel?: string;
    };
    client: DeliveryClient;
}

const HeroPathway = (props: HeroPathwayProps) => {
    const [hover, setHover] = useState(false);
    const [activediv, setActiveDiv] = useState(-1);

    const isFullWidthItem = (item: HeroPathwayItem): boolean => {
        return item.elements.control_width.value?.length > 0 && item.elements.control_width.value[0].codename === 'full_width';
    };

    const resolveLargeContainerColumnSpan = (item: HeroPathwayItem): string => {
        return isFullWidthItem(item) ? '12' : item.elements.image?.value?.length > 0 ? '8' : '4';
    };

    const resolveSmallContainerColumnSpan = (item: HeroPathwayItem): string => {
        return isFullWidthItem(item) ? '12' : '6';
    };

    const getColorMapping = (colorElement: ContentItemElementsIndexer): HeroPathwayColorMapping => {
        let colorThemeValue = getThemeColourFromContentItem(colorElement) || ColorThemeEnum[ColorThemeEnum.blue];
        let filterResult = heroPathwayColorMappings.filter((item) => item.colorName === colorThemeValue);

        return filterResult?.length > 0 ? filterResult[0] : heroPathwayColorMappings[0];
    };

    const getPathwaySideBarColor = (colorElement: ContentItemElementsIndexer) => {
        let result = getColorMapping(colorElement)?.highlightDark;
        return result;
    };

    const getPathwayCircleBackgroundColor = (colorElement: ContentItemElementsIndexer) => {
        let result = getColorMapping(colorElement)?.highlightMid;
        return result;
    };

    const getPathwayButtonTitleBackgroundColor = (colorElement: ContentItemElementsIndexer) => {
        let result = getColorMapping(colorElement)?.highlightLight;
        return result;
    };

    return (
        <Row>
            {props.data.content.map((item, index) => (
                <Col
                    lg={resolveLargeContainerColumnSpan(item)}
                    sm={resolveSmallContainerColumnSpan(item)}
                    className="hero-pathway-wrapper"
                    key={item.system.id}
                >
                    <ComPathwayAnchor
                        data={{
                            pathway_link: item.elements.pathway__link as Elements.RichTextElement,
                        }}
                        client={props.client}
                        className={'hero-pathway-anchor'}
                        title={item.elements.title.value}
                    >
                        <div
                            className="hero-pathway-card"
                            style={{ borderLeftColor: getPathwaySideBarColor(item.elements.colors) }}
                            key={index}
                            onMouseEnter={() => {
                                setHover(true);
                                setActiveDiv(index);
                            }}
                            onMouseLeave={() => {
                                setHover(false);
                                setActiveDiv(-1);
                            }}
                        >
                            <div className="hero-pathway-card-content">
                                <div className="hero-pathway-card-content-left">
                                    <div className="hero-pathway-card-text">
                                        {props.data.parentHeadingLevel === 'h3' ? (
                                            <h4 className="hero-pathway-card-title">{item.elements.title.value}</h4>
                                        ) : (
                                            <h3 className="hero-pathway-card-title">{item.elements.title.value}</h3>
                                        )}

                                        <div
                                            className="hero-pathway-card-description"
                                            dangerouslySetInnerHTML={{
                                                __html: item.elements.description.value,
                                            }}
                                        ></div>
                                    </div>

                                    <div className="hero-pathway-footer">
                                        <div
                                            className="circle-background"
                                            style={{ backgroundColor: getPathwayCircleBackgroundColor(item.elements.colors) }}
                                        >
                                            <span className="arrow-right-black-icon"></span>
                                        </div>
                                        {item.elements.button_title?.value && (
                                            <div
                                                className="button-title ps-7"
                                                style={{
                                                    backgroundColor:
                                                        index === activediv && hover
                                                            ? Colors.bgaWhite
                                                            : getPathwayButtonTitleBackgroundColor(item.elements.colors),
                                                }}
                                            >
                                                <p className="button-text">{item.elements.button_title.value}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {item.elements.image.value.length > 0 && (
                                    <div className="hero-pathway-card-content-right">
                                        <div className="hero-pathway-image">
                                            <ComImage src={item.elements.image.value[0].url} alt={item.elements.image.value[0].description} />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </ComPathwayAnchor>
                </Col>
            ))}
        </Row>
    );
};

export default HeroPathway;
