import React, { useState } from 'react';
import {
    FilterClear,
    FilterNestedCheckboxPanel,
    NestedFilterItemInteraction,
    NestedFilterItem,
    FilterCheckbox,
    FilterItemInteraction,
} from '@exporter-services/common-ui';
import '../../styles/_filterPages.scss';
import { CommonConstants } from '../../CommonConstants';
import { isExpansionWorkEnabled } from '../../config';

export interface EventsFilterCriteria {
    regionMarketCriteriaList?: { [id: string]: boolean };
    industrySectorCriteriaList?: { [id: string]: boolean };
    filterByAustradeEvents?: boolean;
}

export interface FilterEventsMainPanelProps {
    data?: {
        industrySectorList?: NestedFilterItem[];
        regionMarketList?: NestedFilterItem[];
        austradeEventsCount?: number;
    };
    onChange?: (filterCriteria: EventsFilterCriteria) => void;
}

const FilterEventsPanel = (props: FilterEventsMainPanelProps) => {
    const FILTER_DEFAULT_STATE: EventsFilterCriteria = {
        regionMarketCriteriaList: {},
        industrySectorCriteriaList: {},
        filterByAustradeEvents: false,
    };

    let [filterCriteria, setFilterCriteria] = useState<EventsFilterCriteria>(FILTER_DEFAULT_STATE);
    let [clearState, setClearState] = useState<boolean>(false);

    const handleOnClearFilter = () => {
        setClearState(true);
        filterCriteria = JSON.parse(JSON.stringify(FILTER_DEFAULT_STATE));

        let newState = Object.assign({}, FILTER_DEFAULT_STATE);
        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    const handleIndustrySectorListPanelChange = (items: NestedFilterItemInteraction[]) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);
        items.forEach((item) => {
            let key = item.childValue ? item.parentValue + ':' + item.childValue : item.parentValue;
            newState.industrySectorCriteriaList[key] = item.selected;
        });

        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    const handleRegionMarketListPanelChange = (items: NestedFilterItemInteraction[]) => {
        setClearState(false);
        let newState = Object.assign({}, filterCriteria);
        items.forEach((item) => {
            let key = item.childValue ? item.parentValue + ':' + item.childValue : item.parentValue;
            newState.regionMarketCriteriaList[key] = item.selected;
        });

        props.onChange?.(newState);
        setFilterCriteria(newState);
    };

    const handleAustradeEventsChange = (item: FilterItemInteraction) => {
        const newState = Object.assign({}, { ...filterCriteria, filterByAustradeEvents: item.selected });
        props.onChange?.(newState);

        setFilterCriteria(newState);
        setClearState(false);
    };

    return (
        <div className="filter-main-panel">
            {isExpansionWorkEnabled && (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterCheckbox
                        data={{
                            id: 'austrade_events_filter',
                            value: 'austrade_events',
                            label: 'Austrade events',
                            count: props.data.austradeEventsCount,
                            checked: filterCriteria.filterByAustradeEvents,
                            clearState: clearState,
                        }}
                        onChange={handleAustradeEventsChange}
                    />
                </>
            )}
            {props.data.industrySectorList?.length > 0 ? (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterNestedCheckboxPanel
                        data={{
                            label: 'Industry',
                            nestedFilterItems: props.data.industrySectorList,
                            clearState: clearState,
                        }}
                        onChange={handleIndustrySectorListPanelChange}
                    />
                </>
            ) : null}

            {props.data.regionMarketList?.length > 0 ? (
                <>
                    <hr className="filter-main-panel-separator" />
                    <FilterNestedCheckboxPanel
                        data={{ label: 'Region', nestedFilterItems: props.data.regionMarketList, clearState: clearState }}
                        onChange={handleRegionMarketListPanelChange}
                    />
                </>
            ) : null}

            <hr className="filter-main-panel-separator" />
            <FilterClear data={{ label: CommonConstants.CLEAR_FILTERS_LABEL }} onClick={handleOnClearFilter} />
        </div>
    );
};

export default FilterEventsPanel;
