import React from 'react';
import '../../controls/download/Download.scss';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';

interface XptDownloadProps {
    title: string;
    description: string;
    filename: string;
    downloading: boolean;
    onClick: () => void;
}

const XptDownload = (props: XptDownloadProps) => {
    return (
        <div className={'download-information'}>
            <h2>{props.title}</h2>
            {props.description && <p className="description">{props.description}</p>}
            <button className="download-item" disabled={props.downloading} onClick={props.onClick}>
                {props.downloading ? (
                    <>
                        <LoadingOverlay
                            active={props.downloading}
                            spinner={<SyncLoader />}
                            text={<span>Building the export plan...</span>}
                            className="loader"
                        />
                    </>
                ) : (
                    <span className="file-type download-icon">{props.filename}</span>
                )}
            </button>
        </div>
    );
};

export default XptDownload;
