import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import ComponentResolver from '../../ComponentResolver';
import PersonalisedPageSearchContainer from '../pagePersonalisation/PersonalisedPageSearchContainer';
import LeftNavigation, { LeftNavLink } from '../leftNavigation/leftNavigation';
import useGetKontentPlaceholderValues, { KontentPlaceHolder, replacePlaceHolders } from '../../hooks/useGetKontentPlaceholderValues';

interface ContentResolverProps {
    data: {
        item: ContentItem;
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

type getIdType = (title: string) => string;

export default function Content(props: ContentResolverProps) {
    const contentItems: ContentItem[] = props.data.item.elements.content['linkedItems'];
    const [leftHandLinks, setleftHandLinks] = useState<LeftNavLink[]>([]);
    const kontentPlaceHolder: KontentPlaceHolder = useGetKontentPlaceholderValues().kontentPlaceHolder;

    const isPersonalisedPage = (content: ContentItem) => {
        return content?.elements.personalisation?.value?.length > 0 && content?.elements.personalisation_options;
    };

    const leftNav = (
        rootItem: ContentItem,
        items: ContentItem[],
        getId: getIdType,
        setleftHandLinks: React.Dispatch<React.SetStateAction<LeftNavLink[]>>,
    ) => {
        const personalisedSearchTitle = rootItem.elements?.personalisation_text_messages__title?.value;

        if (isPersonalisedPage(rootItem) && personalisedSearchTitle) {
            let linkItem: LeftNavLink = {
                id: getId(personalisedSearchTitle),
                title: personalisedSearchTitle,
                selected: false,
                visible: true,
                skipPersonalisationCheck: true,
            };
            setleftHandLinks((results) => [...results, linkItem]);
        }

        items.map((item: ContentItem) => {
            if (item.system.type === 'body_copy') {
                let linkItem: LeftNavLink = {
                    id: getId(item.elements.title.value),
                    title: item.elements.title.value,
                    selected: false,
                    visible: true,
                    industrySector: item.elements.industry_sector,
                };
                setleftHandLinks((results) => [...results, linkItem]);
            }

            if (item.system.type === 'filtered_market_results_container') {
                item.elements.linked_items['linkedItems'].map((x: any) => {
                    if (x.system.type === 'body_copy') {
                        let linkItem = {
                            id: getId(x.elements.title.value),
                            title: x.elements.title.value,
                            selected: false,
                            visible: false,
                        };
                        setleftHandLinks((results) => [...results, linkItem]);
                    }
                    return null;
                });
            }
            return null;
        });
    };

    useEffect(() => {
        leftNav(props.data.item, contentItems, getId, setleftHandLinks);
    }, [contentItems]);

    useEffect(() => {
        if (leftHandLinks.length > 0) {
            const updatedLinks = leftHandLinks.map((link: LeftNavLink) => {
                const title = replacePlaceHolders(link.title, kontentPlaceHolder);
                link.title = title;
                link.id = getId(title);
                return link;
            });

            setleftHandLinks(updatedLinks);
        }
    }, [leftHandLinks.length, kontentPlaceHolder.market]);

    const handleComponentResolverLoad = (available: boolean, title: string) => {
        if (available) {
            setleftHandLinks((leftHandLinks) => {
                if (leftHandLinks.length > 0) {
                    for (let i = 0; i < leftHandLinks.length; i++) {
                        if (leftHandLinks[i].title === replacePlaceHolders(title, kontentPlaceHolder)) {
                            leftHandLinks[i].visible = true;
                        }
                    }
                    return leftHandLinks;
                } else {
                    return [
                        {
                            id: getId(title),
                            title: title,
                            selected: false,
                            visible: true,
                        },
                    ];
                }
            });

            setleftHandLinks((leftHandLinks) => [...leftHandLinks]);
        }
    };

    const getId: getIdType = (title) =>
        title
            ?.replace(/\s+/g, '-')
            .replace(/[^\w-]/g, '')
            .toLowerCase();

    const showLeftNav = props.data.item.elements?.left_navigation?.value.length > 0;
    const rightContentItems = props.data.item.elements?.right_hand_content?.value?.map((el) => props.data.linkedItems?.[el]) || [];
    const showRightContent = rightContentItems.length > 0;

    const colXl = showLeftNav || showRightContent ? 8 : 12;
    const colSm = showLeftNav ? 9 : 12;
    const colMd = showRightContent ? 11 : colSm;
    const colLg = showRightContent ? 8 : colXl;

    return (
        <Row>
            {!showRightContent && showLeftNav && (
                <Col sm={3} className="d-print-none">
                    {leftHandLinks && <LeftNavigation leftNavLinks={leftHandLinks} />}
                </Col>
            )}
            <Col sm={colSm} md={colMd} lg={colLg} xl={colXl}>
                {isPersonalisedPage(props.data.item) && (
                    <PersonalisedPageSearchContainer
                        data={{
                            item: props.data.item,
                        }}
                    />
                )}
                {contentItems?.map((item: ContentItem) => (
                    <ComponentResolver
                        data={{ item: item, linkedItems: props.data.linkedItems }}
                        key={item.system.id}
                        match={props.match}
                        onLoad={handleComponentResolverLoad}
                    />
                ))}
            </Col>
            {showRightContent && (
                <Col md={11} lg={4} className="mb-5">
                    {rightContentItems?.map((item: ContentItem) => (
                        <ComponentResolver
                            data={{ item: item, linkedItems: props.data.linkedItems }}
                            key={item.system.id}
                            match={props.match}
                            onLoad={handleComponentResolverLoad}
                        />
                    ))}
                </Col>
            )}
        </Row>
    );
}
