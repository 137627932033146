import React, { PropsWithChildren } from 'react';
import { DeliveryClient, Elements, ILink } from '@kontent-ai/delivery-sdk';
import parse, { Element, DOMNode } from 'html-react-parser';
import { ElementType } from 'domelementtype';
import { ResolveContentLink } from '../../utils/contentHelpers';

//#region utility methods
const getAnchorHtmlAttributesFromComPathwayAnchorProps = (props: ComPathwayAnchorProps) => {
    let temp = Object.assign({}, props);
    delete temp['data'];
    delete temp['client'];
    return temp as React.AnchorHTMLAttributes<any>;
};

const removeWrappingParagraphElementFromValue = (value: string): string => {
    if (value.indexOf('<p>') === -1) return value;

    const startOfValueIndex = value.indexOf('<p>') + 3;
    const endOfValueIndex = value.indexOf('</p>');
    return value.substring(startOfValueIndex, endOfValueIndex);
};
//#endregion

//#region resolve anchor components
const ResolveContentItemLinks = (
    client: DeliveryClient,
    props: React.AnchorHTMLAttributes<any>,
    children: React.ReactNode,
    value: string,
    links: ILink[],
): JSX.Element => {
    const replaceEmbeddedObjects = (domNode: DOMNode) => {
        if (domNode.type === ElementType.Tag) {
            let element = domNode as Element;
            if (element.name === 'a' && element.attribs['data-item-id'] && !element.attribs['href']) {
                let id = element.attribs['data-item-id'];
                let link = links.find((link) => link.linkId === id);
                //resolve/reset link
                element.attribs['href'] = ResolveContentLink(client, link).url;
                return (
                    <a {...props} data-item-id={element.attribs['data-item-id']} href={element.attribs['href']}>
                        {children}
                    </a>
                );
            } else if (element.name === 'a') {
                return (
                    <a {...props} href={element.attribs['href']}>
                        {children}
                    </a>
                );
            }
        } else if (domNode.type === ElementType.Text) {
            return null;
        }
    };

    return parse(value, {
        replace: replaceEmbeddedObjects,
    }) as JSX.Element;
};

const ResolveTextualLink = (props: React.AnchorHTMLAttributes<any>, children: React.ReactNode, value: string) => {
    const removeAllHtmlCharacters = (str: string) => {
        return str?.replace(/<\/?[^>]+(>|$)/g, '');
    };

    const href = removeAllHtmlCharacters(value)?.trim() ?? '';

    return (
        <a {...props} href={href}>
            {children}
        </a>
    );
};
//#endregion

export interface ComPathwayAnchorProps extends React.AnchorHTMLAttributes<any> {
    data: {
        pathway_link: Elements.RichTextElement;
    };
    client: DeliveryClient;
}

const ComPathwayAnchor = ({ children, ...props }: PropsWithChildren<ComPathwayAnchorProps>): JSX.Element => {
    const doesValueContainAnyAnchorTag = (value: string) => /<\/a>/i.test(value);

    let anchorProps = getAnchorHtmlAttributesFromComPathwayAnchorProps(props);
    const value = removeWrappingParagraphElementFromValue(props.data.pathway_link.value);

    if (doesValueContainAnyAnchorTag(value)) {
        return ResolveContentItemLinks(props.client, anchorProps, children, value, props.data.pathway_link.links);
    } else {
        return ResolveTextualLink(anchorProps, children, value);
    }
};

export default ComPathwayAnchor;
