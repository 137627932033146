import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import AccordionItemGuidePathway from './AccordionItemGuidePathway';
import './AccordionGuidePathway.scss';
import { AccordionContainerContentItemGuidePathway } from './AccordionContainerContentItemGuidePathway';
import { AccordionContentItemGuidePathway } from './AccordionContentItemGuidePathway';
import ChevronUpIcon from '../../assets/chevronUp333.svg';
import ChevronDownIcon from '../../assets/chevronDown333.svg';
import { filterContentItemAgainstUrlParams } from '../../utils/utils';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { client } from '../../config';
import { useUrlParams } from '../../hooks/useUrlParams';

interface AccordionGuidePathwayProps {
    data: {
        item: AccordionContainerContentItemGuidePathway;
        linkedItems: IContentItemsContainer;
    };
    match?: any;
}

const AccordionGuidePathway = (props: AccordionGuidePathwayProps) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const urlCodes = useUrlParams(props.match);
    const filteredGuidePathway = filterContentItemAgainstUrlParams(props.data.item, urlCodes);
    const [accordionContent, setAccordionContent] = useState<any>(null);

    useEffect(() => {
        filteredGuidePathway && getAccordionItemDetails(filteredGuidePathway?.system.codename);
    }, []);

    const getAccordionItemDetails = (systemCodeName) => {
        client
            .items()
            .equalsFilter('system.codename', systemCodeName)
            .equalsFilter('system.type', 'guide_pathway_accordion')
            .depthParameter(5)
            .toPromise()
            .then((response) => {
                setAccordionContent(response.data);
            });
    };

    if (!filteredGuidePathway) return null;

    return (
        accordionContent && (
            <Row>
                <Col className="accordion-guide-pathway">
                    <div className="accordion-container">
                        <div className="accordion-group" role="tablist">
                            <div
                                className={`accordion-group-toggle ${
                                    filteredGuidePathway.elements.guide_pathway_accordion_items?.value.length > 1 ? 'visible' : ''
                                }`}
                            >
                                <button
                                    aria-label="button Open all items in accordion"
                                    className="accordion-group-toggle-content "
                                    onClick={() => setExpanded((expanded) => !expanded)}
                                    aria-expanded={expanded}
                                    data-testid="accordion-group-toggle"
                                >
                                    <span>{!expanded ? 'Open all' : 'Close all'}</span>
                                    <img src={!expanded ? ChevronDownIcon : ChevronUpIcon} alt=""></img>
                                    <span className="sr-only">button toggle all accordions</span>
                                </button>
                            </div>

                            {accordionContent.items?.length > 0 &&
                                accordionContent.items[0].elements.guide_pathway_accordion_items &&
                                accordionContent.items[0].elements.guide_pathway_accordion_items['linkedItems'].map(
                                    (i: AccordionContentItemGuidePathway, index) => {
                                        return (
                                            <AccordionItemGuidePathway
                                                key={index}
                                                item={i}
                                                expanded={expanded}
                                                linkedItems={accordionContent.linkedItems}
                                                match={props.match}
                                            />
                                        );
                                    },
                                )}
                        </div>
                    </div>
                </Col>
            </Row>
        )
    );
};

export default AccordionGuidePathway;
