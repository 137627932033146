import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ColorThemeEnum, ComButton, ContentItem, getThemeColourFromContentItem } from '@exporter-services/common-ui';
import './SidebarInfo.scss';
import HeadingLevelResolver from '../headingLevelResolver/HeadingLevelResolver';

interface SidebarInfoProps {
    data: {
        item: ContentItem;
    };
}

const SidebarInfo = (props: SidebarInfoProps) => {
    const CONTENT_THRESHOLD = 220;
    const CONTENT_THRESHOLD_WITH_ICON = 280;
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [linkText, setLinkText] = useState('Read more');
    const [chevronUp, setChevronUp] = useState(false);
    const {
        elements: { title, description, icon, background_color, headinglevelsnippet__level: headingLevel },
    } = props.data.item;

    const displayReadMore = useMemo(
        () =>
            (!icon?.value?.[0]?.url && sidebarRef?.current?.clientHeight > CONTENT_THRESHOLD) ||
            (icon?.value?.[0]?.url && sidebarRef?.current?.clientHeight > CONTENT_THRESHOLD_WITH_ICON),
        [sidebarRef?.current, icon?.value?.[0]?.url],
    );
    const backgroundColor = getThemeColourFromContentItem(background_color) || ColorThemeEnum[ColorThemeEnum.teal];
    const classNames = useMemo(
        () => (icon?.value?.[0]?.url ? ['fixed-height', backgroundColor, 'with-icon'] : ['fixed-height', backgroundColor]),
        [icon?.value?.[0]?.url],
    );

    const showHideText = () => {
        setChevronUp(!chevronUp);
        if (sidebarRef?.current?.classList?.contains('fixed-height')) {
            sidebarRef.current.classList.remove(...classNames);
            setLinkText('Read less');
        } else {
            sidebarRef.current.classList.add(...classNames);
            setLinkText('Read more');
        }
    };

    useEffect(() => {
        if (displayReadMore && !sidebarRef?.current?.classList?.contains('fixed-height')) {
            sidebarRef.current.classList.add(...classNames);
        }
    }, [sidebarRef?.current]);

    return (
        <div className={`sidebar-container ${backgroundColor}`}>
            <div ref={sidebarRef} className={`text-container${displayReadMore ? ' pb-4' : ''}`}>
                {icon?.value?.[0]?.url && (
                    <div className="icon-container">
                        <img src={icon?.value?.[0]?.url} alt={icon?.value?.[0]?.description || ''} />
                    </div>
                )}
                <HeadingLevelResolver
                    data={{
                        headingLevel,
                        titleText: title?.value,
                    }}
                />
                <div dangerouslySetInnerHTML={{ __html: description?.value }}></div>
            </div>
            {displayReadMore && (
                <ComButton variant="link" className={`arrow-down-icon${chevronUp ? ' expanded' : ''}`} onClick={showHideText}>
                    {linkText}
                </ComButton>
            )}
        </div>
    );
};

export default SidebarInfo;
