import React, { useEffect, useState } from 'react';
import { Elements } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import DeliveryService from '../../services/DeliveryService';
import { getTaxonomyTermLabel } from '@exporter-services/common-ui/src/utils/taxonomyHelper';
import { capitalise } from '../../utils/utils';
import { useAppSelector } from '../../hooks/reduxHooks';
import { ProductServiceSelectionInfo } from '../../models/ReduxModels';
import { CommonConstants } from '../../CommonConstants';

export class SearchResultsMessageContentItem extends ContentItem {
    specific_message: Elements.RichTextElement;
    not_specific_message: Elements.TextElement;
}

interface SearchResultsMessageProps {
    data: {
        item: SearchResultsMessageContentItem;
    };
}

const SearchResultsMessage = (props: SearchResultsMessageProps) => {
    const [showComponent, setShowComponent] = useState<boolean>(false);
    const [sectorLabel, setSectorLabel] = useState(null);
    const productsServicesSelectionState = useAppSelector<ProductServiceSelectionInfo>(
        (state) => state.productServiceSelection.productServiceSelectionInfo,
    );

    useEffect(() => {
        if (!props.data.item.elements.specific_message || !props.data.item.elements.not_specific_message) {
            setShowComponent(false);
            return;
        }

        if (productsServicesSelectionState) {
            if (productsServicesSelectionState.sectorCode !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
                const deliveryService = new DeliveryService();
                deliveryService.getTaxonomyGroup('sector').then((taxGroup) => {
                    const sectorLabelTemp = getTaxonomyTermLabel(taxGroup, productsServicesSelectionState.sectorCode);
                    setSectorLabel(capitalise(sectorLabelTemp));
                });
            }
            setShowComponent(true);
        }
    }, [productsServicesSelectionState, productsServicesSelectionState.industryCode, productsServicesSelectionState.sectorCode]);

    const getMessageHtml = () => {
        if (productsServicesSelectionState?.sectorCode !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) {
            if (props.data.item.elements.specific_message?.value) {
                const splits = props.data.item.elements.specific_message.value.split('{{sector}}');
                return (
                    <p className="results-message">
                        {splits[0]}
                        <span className="semi-bold">{sectorLabel}</span>
                        {splits[1]}
                    </p>
                );
            } else return <></>;
        } else {
            return <p className="results-message">{props.data.item.elements.not_specific_message?.value}</p>;
        }
    };

    return <>{showComponent && getMessageHtml()}</>;
};

export default SearchResultsMessage;
