import * as Scroll from 'react-scroll';

export const scrollTo = (refHTMLElement: React.MutableRefObject<any>) => {
    Scroll.animateScroll.scrollTo(document.documentElement.scrollTop + refHTMLElement.current.getBoundingClientRect().top, {
        duration: 600,
        delay: 100,
        smooth: true,
    });
};

export function smoothScrollTo(element: HTMLElement, to: number, duration: number): void {
    let start = element.scrollTop,
        change = to - start,
        currentTime = 0,
        increment = 20;
    function easeInoutquad(t, b, c, d) {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    }
    function animateScroll() {
        currentTime += increment;
        element.scrollTop = easeInoutquad(currentTime, start, change, duration);
        if (currentTime < duration) {
            setTimeout(animateScroll, increment);
        }
    }
    animateScroll();
}
