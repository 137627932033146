import GlobalState, { PageState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BootstrapBreakpointEnum } from '../../models/BootstrapBreakpointEnum';
import { PageInfo } from '../../models/ReduxModels';

const initState: PageState = {
    applicableBootstrapBreakpoints: [],
    pageInfo: undefined,
};

export const setApplicableBootstrapBreakpoints = createAsyncThunk(
    'page/applicableBootstrapBreakpoints',
    async (request: { pageWidth: number }, thunkAPI: any) => {
        const current = (thunkAPI.getState() as GlobalState).page.applicableBootstrapBreakpoints;

        const new_ = (thunkAPI.getState() as GlobalState).singletonServices.pageService.GetCurrentApplicableBootstrapBreakpointList(
            request.pageWidth,
        );

        if (JSON.stringify(current) === JSON.stringify(new_)) {
            return thunkAPI.rejectWithValue(true);
        }

        return await new Promise<BootstrapBreakpointEnum[]>((resolve) => {
            resolve(new_);
        });
    },
);

export const setPageInfo = createAsyncThunk('page/pageInfo', async (request: PageInfo, thunkAPI) => {
    return await new Promise<PageInfo>((resolve) => {
        resolve(request);
    });
});

const pageSlice = createSlice({
    name: 'page',
    initialState: initState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setApplicableBootstrapBreakpoints.fulfilled, (state, action) => {
            state.applicableBootstrapBreakpoints = action.payload as BootstrapBreakpointEnum[];
        });
        builder.addCase(setPageInfo.fulfilled, (state, action) => {
            state.pageInfo = action.payload;
        });
    },
});

const pageReducer = pageSlice.reducer;

export default pageReducer;
