import { Element, DOMNode } from 'html-react-parser';
import { ElementType } from 'domelementtype';

export interface TreeRecord<T1> {
    Id: string;
    Data: T1;
    Selected: boolean;
    Children: TreeRecord<T1>[];
    IsLeafLevel: boolean;
}

export type TreeNodeListItemHtmlModifiers = {
    classString: string;
    dataAttributeCollection: Record<string, string>;
};

export class TreeNodeElement {
    public onClickExtended: (clickEvent: React.MouseEvent<HTMLLIElement>) => void;
    public hasNextSibling: boolean = false;
    public hasSeeMore: boolean = false;
    public hasContractibility: boolean = false;
    public hasSeeMoreTablet: boolean = false;
    public hasContractibilityTablet: boolean = false;
    public hasSeeMoreMobile: boolean = false;
    public hasContractibilityMobile: boolean = false;
    public hasSelection: boolean = false;
}

export abstract class TreeUtilitiesBase {
    public static hasLIGotSeeMore = (li: DOMNode, i: number, nodeCountAtIndex: number, hasMoreThresholdCount: number): boolean => {
        return TreeUtilities.hasLIGotNextSiblings(li as Element, i) && nodeCountAtIndex === hasMoreThresholdCount;
    };

    public static hasLIGotContractibility = (li, i: number, nodeCountAtIndex: number, hasMoreThresholdCount: number): boolean => {
        return TreeUtilities.hasLIGotPreviousSiblings(li, i) && nodeCountAtIndex > hasMoreThresholdCount;
    };

    public static hasLIGotSelection = (li: Element): boolean => {
        return li.attribs['class']?.includes('has-selection');
    };

    public static hasLIGotNextSiblings = (li: Element, i: number): boolean => {
        return (
            li.parent.children.find((c, j) => {
                return c.type === ElementType.Tag && (c as Element).name === 'li' && i < j;
            }) !== undefined
        );
    };

    public static hasLIGotPreviousSiblings = (li: Element, i: number): boolean => {
        return (
            li.parent.children.find((c, j) => {
                return c.type === ElementType.Tag && (c as Element).name === 'li' && i > j;
            }) !== undefined
        );
    };

    public static getNodeCountAtIndex = (domNode: DOMNode, i: number): number => {
        let response = 0;
        domNode.parent.children.forEach((c, j) => {
            if (j <= i && c.type === ElementType.Tag && (c as Element).tagName === 'li') {
                ++response;
            }
        });
        return response;
    };

    protected static baseGenerateTreeHtmlInternal<T1>(
        treeHierarchy: TreeRecord<T1>[],
        isRoot = false,
        depth: number,
        nodeItemTitleModifer: (
            treeRecord: TreeRecord<T1>,
            isRoot: boolean,
            depth: number,
            index: number,
            isLeafLevel: boolean,
            classesString?: string,
            dataAttributeCollection?: Record<string, string>,
        ) => string,
        itemHtmlModifiers?: Record<string, TreeNodeListItemHtmlModifiers>,
    ) {
        if (!treeHierarchy || treeHierarchy.length === 0) {
            return '';
        }

        let listNodes = ''.concat(
            treeHierarchy
                .map((c, i) => {
                    let data = nodeItemTitleModifer(c, isRoot, depth, i, !!!(c.Children && c.Children.length > 0));
                    let children =
                        c.Children && c.Children.length > 0
                            ? `<ul>${TreeUtilitiesBase.baseGenerateTreeHtmlInternal(
                                  c.Children,
                                  false,
                                  depth + 1,
                                  nodeItemTitleModifer,
                                  itemHtmlModifiers,
                              )}</ul>`
                            : '';

                    const className: string = `${c.Selected ? 'has-selection' : ''} ${
                        itemHtmlModifiers?.[c.Id]?.classString ? itemHtmlModifiers[c.Id].classString : ''
                    }`;
                    const dataAttributesString = (() => {
                        if (itemHtmlModifiers?.[c.Id]?.dataAttributeCollection) {
                            let arr = [];
                            const keys = Object.keys(itemHtmlModifiers[c.Id].dataAttributeCollection);
                            for (let i = 0; i < keys.length; ++i) {
                                arr.push(`${keys[i]}="${itemHtmlModifiers[c.Id].dataAttributeCollection[keys[i]]}"`);
                            }

                            return arr.join(' ');
                        }

                        return '';
                    })();

                    return `<li dataIsroot2="${isRoot}" class="${className}" data-id="${c.Id}" ${dataAttributesString}><div class="icon-click-overlay"></div><div class="data">${data}</div>${children}</li>`;
                })
                .join(''),
        );

        return isRoot ? `<ul>${listNodes}</ul>` : listNodes;
    }
}

export class TreeUtilities extends TreeUtilitiesBase {
    public static generateTreeHtml<T1>(
        treeHierarchy: TreeRecord<T1>[],
        nodeItemTitleModifer: (treeRecord: TreeRecord<T1>, isRoot: boolean, depth: number, index: number, isLeafLevel: boolean) => string,
    ) {
        if (!treeHierarchy) return null;

        let depth = 1;
        let isRoot = true;

        return TreeUtilities.generateTreeHtmlInternal(treeHierarchy, isRoot, depth, nodeItemTitleModifer);
    }

    protected static generateTreeHtmlInternal<T1>(
        treeHierarchy: TreeRecord<T1>[],
        isRoot = false,
        depth: number,
        nodeItemTitleModifer: (treeRecord: TreeRecord<T1>, isRoot: boolean, depth: number, index: number, isLeafLevel: boolean) => string,
    ) {
        return super.baseGenerateTreeHtmlInternal(treeHierarchy, isRoot, depth, nodeItemTitleModifer);
    }
}
