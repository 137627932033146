import React from 'react';
import './NewsPathwayTile.scss';
import { Col, Row } from 'reactstrap';

export interface NewsPathwayTileProps {
    data: {
        id?: string;
        title: string;
        lastUpdated?: string;
        imageLink?: string;
        imageAltDescription?: string;
        category?: string;
        categoryIconClass: string;
        categoryTagClass: string;
        linkUrl: string;
    };
}

export const NewsPathwayTile = (props: NewsPathwayTileProps) => {
    return (
        <div className="news-pathway-tile-wrapper" key={props.data.id}>
            <a className="news-pathway-tile-anchor" href={props.data.linkUrl}>
                <Row className="news-pathway-tile-card mx-0">
                    <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }} style={{ minHeight: 240 }} className="p-4">
                        <div className="news-pathway-tile-card-content">
                            {props.data.lastUpdated && (
                                <div className="label-search-cards news-pathway-tile-card-category">{props.data.lastUpdated}</div>
                            )}
                            <h3 className="news-pathway-tile-card-title">{props.data.title}</h3>
                        </div>
                        <div className="news-pathway-tile-card-footer">
                            <div className={`tag ${props.data.categoryTagClass}`}>
                                <div className={`${props.data.categoryIconClass}`}></div>
                                <div>{props.data.category}</div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }} className="px-0">
                        <div className="news-pathway-tile-card-image">
                            <img src={props.data.imageLink} alt={props.data.imageAltDescription} />
                        </div>
                    </Col>
                </Row>
            </a>
        </div>
    );
};

export default NewsPathwayTile;
