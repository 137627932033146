import React, { useState, useContext, useRef, useEffect } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import BodyCopy from '../../controls/bodyCopy/BodyCopy';
import { BodyCopyItem } from '../../controls/bodyCopy/BodyCopyItem';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import { personalisationContext } from '../../providers/personalisation';
import XptDownload from './XptDownload';
import TagManager from 'react-gtm-module';
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks';
import { exportPlanDownload } from '../../providers/reducers/exportPlanSlice';
import { ExportPlanDownloadInfo, ExportPlanDownloadRequest } from '../../models/ReduxModels';

interface XptExportPlanStep3Props {
    match?: any;
    data: {
        item?: ContentItem;
        linkedItems: IContentItemsContainer;
    };

    setIsBackAvailable: Function;
    setButtonsHidden: Function;
    setFinalStepCompletion: Function;
}

const XptExportPlanStep3 = (props: XptExportPlanStep3Props) => {
    const { personalisationState } = useContext(personalisationContext);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [downloaded, setDownloaded] = useState<boolean>(false);
    const downloadFileRef = useRef(null);

    const exportPlanDownloadData = useAppSelector<ExportPlanDownloadInfo>((state) => state.exportPlan.exportPlanDownloadInfo);
    const dispatch = useAppDispatch();
    const base64ToArrayBuffer = (base64: string) => {
        const binaryString = window.atob(base64); // Comment this if not using base64
        const bytes = new Uint8Array(binaryString.length);
        return bytes.map((byte, i) => binaryString.charCodeAt(i));
    };

    useEffect(() => {
        if (exportPlanDownloadData?.data) {
            const blob = new Blob([base64ToArrayBuffer(exportPlanDownloadData.data.FileContents)], {
                type: exportPlanDownloadData.data.ContentType,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = exportPlanDownloadData.data.FileDownloadName;
            link.click();
            setDownloading(false);
            setDownloaded(true);
            props.setButtonsHidden(true);
            props.setFinalStepCompletion();
        }
    }, [exportPlanDownloadData]);

    const sendGtmDatalayerEvent = () => {
        const DL_EVENT_NAME = 'export_plan_builder_tool_download_button_click';
        if (!personalisationState.hsCode && personalisationState.industryCode) {
            TagManager.dataLayer({
                dataLayer: {
                    event: DL_EVENT_NAME,
                    dlv_industry: personalisationState.industryCode,
                    dlv_sector: personalisationState.sectorCode,
                    dlv_product_code: '',
                    dlv_service_or_product: 'service',
                    dlv_market: personalisationState.marketCode,
                },
            });
        } else {
            TagManager.dataLayer({
                dataLayer: {
                    event: DL_EVENT_NAME,
                    dlv_industry: '',
                    dlv_sector: '',
                    dlv_product_code: personalisationState.hsCode,
                    dlv_service_or_product: 'product',
                    dlv_market: personalisationState.marketCode,
                },
            });
        }
    };

    const download = () => {
        setDownloading(true);
        props.setIsBackAvailable(false);

        dispatch(
            exportPlanDownload({
                industryCode: personalisationState.industryCode,
                sectorCode: personalisationState.sectorCode,
                hsCode: personalisationState.hsCode,
                marketCode: personalisationState.marketCode,
            } as ExportPlanDownloadRequest),
        );

        sendGtmDatalayerEvent();
    };

    const renderDownload = (paramDownloadFileRef: any) => {
        return (
            <>
                {!downloaded && (
                    <LoadingOverlay spinner={<SyncLoader />} text="Please wait" className="loader">
                        <div className="p-4 p-md-5" ref={paramDownloadFileRef}>
                            <XptDownload
                                title={props.data.item?.elements.step3_download_title.value}
                                description={props.data.item?.elements.step3_download_description.value}
                                filename={props.data.item?.elements.step3_download_filename.value}
                                downloading={downloading}
                                onClick={download}
                            />
                        </div>
                    </LoadingOverlay>
                )}
            </>
        );
    };

    const renderCongrats = () => {
        return (
            <>
                {downloaded && (
                    <div className="mt-4">
                        {props.data.item?.elements.step4_title.value && <h2>{props.data.item?.elements.step4_title.value}</h2>}
                        {props.data.item?.elements.step4_description.value && <p>{props.data.item?.elements.step4_description.value}</p>}
                        <BodyCopy
                            data={{
                                item: props.data.item?.elements.step4_contents['linkedItems'][0] as BodyCopyItem,
                                linkedItems: props.data.linkedItems,
                            }}
                            match={props.match}
                            displayTitle={true}
                        />
                    </div>
                )}
            </>
        );
    };

    return (
        <div className="exportplanstep3">
            {renderDownload(downloadFileRef)}
            {renderCongrats()}
        </div>
    );
};

export default XptExportPlanStep3;
