import React from 'react';
import PageBody from '../pageBody/PageBody';
import Layout from '../../site/Layout';
import ErrorPageHeader from '../pageHeader/ErrorPageHeader';
import { Container } from 'reactstrap';

const Error = () => {
    return (
        <Layout>
            <ErrorPageHeader data={{ title: 'Error' }} />
            <PageBody>
                <Container>
                    <p>An error occurred while processing your request, please try again.</p>
                </Container>
            </PageBody>
        </Layout>
    );
};

export default Error;
