export class UserService {
    public isOffshore = (geolocationCode: string) => {
        if (!geolocationCode) {
            return undefined;
        }

        return geolocationCode.toUpperCase() !== 'AU';
    };

    public getGeolocation = (): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            fetch('/api/geolocation/country/iso2lcode')
                .then((response) => response.json())
                .then((data: { Value: string }) => {
                    resolve(data.Value);
                })
                .catch((err) => reject(err));
        });
    };
}
