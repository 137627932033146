import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'reactstrap';
import QuizProgressBar from './QuizProgressBar';
import QuizQuestion from './QuizQuestion';
import QuizResults from './QuizResults';
import { differenceBetweenQuizQuestionLists } from './QuizQuestionsContainer.utils';
import {
    findEarliestQuizQuestionUnansweredFromQuerystring,
    generateResultsQueryString,
    generateServiceQuerystringByStoredPersonalisation,
    isPersonalisationQuerystringContainingResults,
    QuizAnswersStateType,
} from '../../utils/personalisationUtils';
import { useNavigate } from 'react-router-dom';
import { personalisationContext } from '../../providers/personalisation';
import { useAppSelector } from '../../hooks/reduxHooks';
import { OnLoadPersonalisationInfo } from '../../models/ReduxModels';
import { ComButton } from '@exporter-services/common-ui';
import { setProgress } from '../../providers/personalisation/reducer';
import { scrollTo } from '../../utils/scrollUtils';
import { usePrevious } from '../../hooks/usePrevious';

interface QuizCardsContainerProps {
    industry: string;
    questions: Array<any>;
    generic_yes_message: any;
    generic_no_message: any;
    onAnswerClicked: (e, a) => void;
    isOnLoadQuizAnswersApplied: boolean;
}

const QuizCardsContainer = (props: QuizCardsContainerProps) => {
    const [questionsCount, setQuestionsCount] = useState<number>(0);
    const [currentQuestionNumber, setCurrentQuestionNumber] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<any>(null);
    const [answered, setAnswered] = useState<boolean>(false);
    const [showResults, setShowResults] = useState<boolean>(false);
    const scrollRef = useRef(null);
    const [industry, setIndustry] = useState<string>();
    const previousQuestionArray = usePrevious(props.questions);
    const [isOnLoadNavUserToRelevantStepApplied, setIsOnLoadNavUserToRelevantStepApplied] = useState<boolean>(false);
    const onLoadPersonalisation = useAppSelector<OnLoadPersonalisationInfo>((state) => state.productServiceSelection.onLoadPersonalisationInfo);
    const { personalisationState, dispatch } = useContext(personalisationContext);
    const navigate = useNavigate();
    const [focusedValue, setFocusedValue] = useState<string>();

    useEffect(() => {
        if (
            props.isOnLoadQuizAnswersApplied &&
            !isOnLoadNavUserToRelevantStepApplied &&
            isPersonalisationQuerystringContainingResults(onLoadPersonalisation)
        ) {
            const earliestQuestionUnanswered = findEarliestQuizQuestionUnansweredFromQuerystring(onLoadPersonalisation, props.questions);
            if (earliestQuestionUnanswered.type === QuizAnswersStateType.AllAnswered) {
                setQuestionsCount(props.questions.length);
                setAnswered(true);
                setCurrentQuestion(null);
                setCurrentQuestionNumber(props.questions.length);
                setIndustry(props.industry);
                setShowResults(true);
                setIsOnLoadNavUserToRelevantStepApplied(true);
            } else {
                setQuestionsCount(props.questions.length);
                setAnswered(false);
                setCurrentQuestion(props.questions[0]);
                setCurrentQuestionNumber(0);
                setIndustry(props.industry);
                setShowResults(false);
                setIsOnLoadNavUserToRelevantStepApplied(true);
            }
        } else if (
            (personalisationState.startAgain || props.industry !== industry) &&
            props.questions?.length > 0 &&
            (!previousQuestionArray || differenceBetweenQuizQuestionLists(previousQuestionArray, props.questions))
        ) {
            setQuestionsCount(props.questions.length);
            setAnswered(props.questions[0].answer !== null);
            setCurrentQuestion(props.questions[0]);
            setCurrentQuestionNumber(0);
            setShowResults(false);
            setIndustry(props.industry);
        }
    }, [props.industry, props.questions, previousQuestionArray, props.isOnLoadQuizAnswersApplied]);

    const handeOnHideResults = () => navigate(generateServiceQuerystringByStoredPersonalisation(personalisationState));

    const handleOnShowResults = () => navigate(generateResultsQueryString(personalisationState, props.questions));

    const moveToPreviousQuestion = () => {
        setFocusedValue('yes');
        const newQuestionNumber = currentQuestionNumber - 1;
        const isHidingResults = props.questions.length === currentQuestionNumber;
        setAnswered(props.questions[newQuestionNumber].answer !== null);
        setCurrentQuestion(props.questions[newQuestionNumber]);
        setCurrentQuestionNumber(newQuestionNumber);
        setShowResults(false);
        scrollToTop();
        isHidingResults && handeOnHideResults();
    };

    const moveToNextQuestion = () => {
        setFocusedValue('yes');
        const isLastQuestionAnswered = currentQuestionNumber === questionsCount - 1;
        setShowResults(isLastQuestionAnswered);
        setAnswered(currentQuestionNumber < questionsCount - 1 && props.questions[currentQuestionNumber + 1].answer);
        setCurrentQuestion(currentQuestionNumber < questionsCount - 1 ? props.questions[currentQuestionNumber + 1] : null);
        setCurrentQuestionNumber(currentQuestionNumber + 1);
        scrollToTop();
        isLastQuestionAnswered && handleOnShowResults();
        dispatch(setProgress());
    };

    const answerHandler = (e) => {
        setAnswered(true);
        props.onAnswerClicked(e, currentQuestion);
    };

    const scrollToTop = () => {
        scrollTo(scrollRef);
    };

    const renderButton = (type: string) => {
        const isBack = type.toUpperCase() === 'BACK';
        const id = isBack ? 'back' : currentQuestionNumber === questionsCount - 1 ? 'finished' : 'next';
        const label = isBack ? <span>Previous</span> : currentQuestionNumber === questionsCount - 1 ? 'Show results' : <span>Next</span>;
        return isBack && currentQuestionNumber > 0 ? (
            <ComButton id={id} onClick={moveToPreviousQuestion} variant="link" aria-pressed="false">
                {label}
            </ComButton>
        ) : isBack && currentQuestionNumber === 0 ? null : !isBack && currentQuestionNumber === questionsCount ? null : !isBack &&
          currentQuestionNumber < questionsCount &&
          answered ? (
            <ComButton id={id} onClick={moveToNextQuestion}>
                {label}
            </ComButton>
        ) : null;
    };

    return (
        <div ref={scrollRef} className="step-container mb-4">
            {questionsCount > 0 && (
                <Row>
                    <Col>
                        <QuizProgressBar questionsCount={questionsCount} questionsNumber={currentQuestionNumber + 1} showResults={showResults} />
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    {showResults ? (
                        <QuizResults
                            industry={props.industry}
                            questions={props.questions}
                            generic_yes_message={props.generic_yes_message}
                            generic_no_message={props.generic_no_message}
                        />
                    ) : (
                        props.questions &&
                        props.questions.length > 1 &&
                        props.questions.length > currentQuestionNumber && (
                            <QuizQuestion
                                question={props.questions[currentQuestionNumber]}
                                focusedValue={focusedValue}
                                index={currentQuestionNumber + 1}
                                onAnswerClicked={answerHandler}
                                onScrolltoTop={scrollToTop}
                            />
                        )
                    )}
                </Col>
            </Row>
            <Row>
                <Col className="button-container">
                    {renderButton('back')}
                    {renderButton('next')}
                </Col>
            </Row>
        </div>
    );
};

export default QuizCardsContainer;
