import parse, { Element } from 'html-react-parser';
import React, { useEffect, useState } from 'react';
import { ContentItem } from '@exporter-services/common-ui';
import { client } from '../../config';
import Table from '../table/Table';
import ComponentResolver from '../../ComponentResolver';
import { DataNode } from 'domhandler';
import { cloneDeep } from 'lodash';
import { ElementType } from 'domelementtype';
import { ResolveContentLink } from '@exporter-services/common-ui/src/utils/contentHelpers';
import useGetKontentPlaceholderValues, { KontentPlaceHolder, replacePlaceHolders } from '../../hooks/useGetKontentPlaceholderValues';

interface ResolveLinkProps {
    data: {
        item: any;
        linkedItems?: any;
        match?: any;
        onLoad?: any;
        onLoadDescription?: any;
    };
    type?: string;
    paramDescription?: string;
}

const getId = (title: string): string =>
    title
        ?.replace(/\s+/g, '-')
        .replace(/[^\w-]/g, '')
        .toLowerCase();

const ResolveLink = (props: ResolveLinkProps) => {
    let componentType = props.type;

    const [description, setDescription] = useState(
        props.data.item.description
            ? props.data.item.description
            : props.data.item?.elements?.description
              ? props.data.item?.elements?.description
              : null,
    );

    const kontentPlaceHolder: KontentPlaceHolder = useGetKontentPlaceholderValues().kontentPlaceHolder;

    useEffect(() => {
        if (props.data.item.description && kontentPlaceHolder.sector && kontentPlaceHolder.ahecc && kontentPlaceHolder.market) {
            let updatedDescription = getUpdatedDescription();
            setDescription(updatedDescription);
        }
    }, [kontentPlaceHolder]);

    const getUpdatedDescription = () => {
        let descriptionTemp = cloneDeep(props.data.item.elements.description);
        descriptionTemp.value = replacePlaceHolders(descriptionTemp.value, kontentPlaceHolder);
        return descriptionTemp;
    };

    const replaceEmbededObject = (value: any, links: any, domNode: Element) => {
        if (domNode.name === 'a' && domNode.attribs['data-item-id'] && !domNode.attribs['href']) {
            let id = domNode.attribs['data-item-id'];
            let link = links.find((link) => link.linkId === id);
            //resolve/reset link
            domNode.attribs['href'] = ResolveContentLink(client, link).url;
        }

        if (
            domNode.name === 'p' &&
            domNode.children &&
            domNode.children.length === 1 &&
            domNode.children.filter((c) => (c as Element).type === ElementType.Tag).length === 1 &&
            domNode.children.filter((c) => (c as Element)?.type === ElementType.Tag && (c as Element).name === 'br').length > 0
        ) {
            domNode.attribs['class'] = 'line-break';
        }

        if (domNode.name === 'object' && domNode.attribs['data-codename']) {
            let component: ContentItem =
                props.data.linkedItems[domNode.attribs['data-codename']] ??
                props.data.linkedItems.find((l: any) => l.system.codename === domNode.attribs['data-codename']);
            return (
                <ComponentResolver
                    data={{ item: component, linkedItems: props.data.linkedItems }}
                    match={props.data.match}
                    onLoad={props.data.onLoad}
                    onLoadDescription={props.data.onLoadDescription}
                    type={props.type}
                />
            );
        }
        if (domNode.name === 'table') {
            return <Table table={domNode as Element} />;
        }

        if (domNode.name === 'h2' && domNode.children && domNode.children.length > 0) {
            return (
                <div className="body-copy" id={getId((domNode.children[0] as DataNode).data)}>
                    <h2>{(domNode.children[0] as DataNode).data}</h2>
                </div>
            );
        }
    };

    const replaceEmbededObjectAndTable = (value: any, links: any, domNode: Element) => {
        replaceEmbededObject(value, links, domNode);
        if (domNode.name === 'table') {
            return <Table table={domNode as Element} />;
        }
    };

    switch (componentType) {
        case 'call_to_action':
            return (
                <a href={props.data.item.elements.call_to_action_link.value} className={'button'}>
                    <span>{props.data.item.elements.call_to_action_text.value}</span>
                </a>
            );
        case 'pro_tips':
            return (
                <div>
                    {parse(props.data.item.elements.tip.value, {
                        replace: (domNode) =>
                            replaceEmbededObject(props.data.item.elements.tip.value, props.data.item.elements.tip.links, domNode as Element),
                    })}
                </div>
            );
        case 'a_real_example':
            return (
                <div>
                    {parse(props.data.item.elements.example.value, {
                        replace: (domNode) =>
                            replaceEmbededObject(props.data.item.elements.example.value, props.data.item.elements.example.links, domNode as Element),
                    })}
                </div>
            );
        case 'market_not_listed_events':
            if (props.data.item) {
                return (
                    <div>
                        {parse(props.data.item.elements.market_not_listed.value, {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.market_not_listed.value,
                                    props.data.item.elements.market_not_listed.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else {
                return null;
            }
        case 'searchcontainer_sector_not_selected':
            if (props.data.item.elements.not_found) {
                return (
                    <div>
                        {parse(props.data.item.elements.sector_not_selected.value, {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.sector_not_selected.value,
                                    props.data.item.elements.sector_not_selected.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else {
                return null;
            }
        case 'searchcontainer_commodity_not_selected':
            if (props.data.item.elements.not_found) {
                return (
                    <div>
                        {parse(props.data.item.elements.commodity_not_selected.value, {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.commodity_not_selected.value,
                                    props.data.item.elements.commodity_not_selected.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else {
                return null;
            }
        case 'search_dropdown_question':
            if (props.data.item.elements.not_found) {
                return (
                    <div>
                        {parse(props.data.item.elements.not_found.value, {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.not_found.value,
                                    props.data.item.elements.not_found.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else if (props.data.item.elements.not_listed) {
                return (
                    <div>
                        {parse(props.data.item.elements.not_listed.value, {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.not_listed.value,
                                    props.data.item.elements.not_listed.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else {
                return null;
            }
        case 'market_not_listed':
            if (props.data.item.elements.market_not_listed) {
                return (
                    <div>
                        {parse(props.data.item.elements.market_not_listed.value.replace('{{product}}', props.paramDescription), {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.market_not_listed.value.replace('{{product}}', props.paramDescription),
                                    props.data.item.elements.market_not_listed.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else {
                return null;
            }
        case 'market_not_sure':
            if (props.data.item.elements.market_not_sure) {
                return (
                    <div>
                        {parse(props.data.item.elements.market_not_sure.value.replace('{{product}}', props.paramDescription), {
                            replace: (domNode) =>
                                replaceEmbededObject(
                                    props.data.item.elements.market_not_sure.value.replace('{{product}}', props.paramDescription),
                                    props.data.item.elements.market_not_sure.links,
                                    domNode as Element,
                                ),
                        })}
                    </div>
                );
            } else {
                return null;
            }
        case 'quizItem':
            //check if user has selected an answer value for a question
            if (props.data.item) {
                return (
                    <>
                        {parse(props.data.item.value, {
                            replace: (domNode) => replaceEmbededObject(props.data.item.value, props.data.item.links, domNode as Element),
                        })}
                    </>
                );
            } else {
                return null;
            }
        case 'tickboxlist':
            return (
                <div>
                    {parse(props.data.item.elements.tickbox_list_items.value, {
                        replace: (domNode) =>
                            replaceEmbededObject(
                                props.data.item.elements.tickbox_list_items.value,
                                props.data.item.elements.tickbox_list_items.links,
                                domNode as Element,
                            ),
                    })}
                </div>
            );
        case 'exporter_resources_content':
            return (
                <>
                    {parse(description.value, {
                        replace: (domNode) =>
                            replaceEmbededObjectAndTable(
                                replacePlaceHolders(description.value, kontentPlaceHolder),
                                description.links,
                                domNode as Element,
                            ),
                    })}
                </>
            );
        case 'exporter_resources_notfound':
            return (
                <>
                    {parse(props.data.item.elements.not_found.value, {
                        replace: (domNode) =>
                            replaceEmbededObjectAndTable(
                                props.data.item.elements.not_found.value,
                                props.data.item.elements.not_found.links,
                                domNode as Element,
                            ),
                    })}
                </>
            );
        default:
            return description ? (
                <div>
                    {parse(replacePlaceHolders(description.value, kontentPlaceHolder), {
                        replace: (domNode) => replaceEmbededObject(description.value, description.links, domNode as Element),
                    })}
                </div>
            ) : (
                <></>
            );
    }
};

export default ResolveLink;
