import { faInstagram, faLinkedinIn, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const FooterTop = () => {
    return (
        <Container fluid className="footer-top py-4 py-md-6 d-print-none" id="footer-top">
            <Container>
                <Row>
                    <Col md={3} className="footer-links">
                        <h3>About us</h3>
                        <ul>
                            <li>
                                <a href="/about-us" data-testid="about-us">
                                    About us
                                </a>
                            </li>
                            <li>
                                <a href="/contact-us" data-testid="contact-us">
                                    Contact us
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.austrade.gov.au/en/site-information/privacy-policy.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Privacy
                                    <span className="sr-only">Opens in new window</span>
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="footer-links">
                        <h3>Quick links</h3>
                        <ul>
                            <li>
                                <a
                                    href="https://www.austrade.gov.au/en/site-information/site-disclaimer.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Site disclaimer
                                    <span className="sr-only">Opens in new window</span>
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.austrade.gov.au/en/site-information/accessibility.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Accessibility
                                    <span className="sr-only">Opens in new window</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.austrade.gov.au/en/site-information/cookies.html" target="_blank" rel="noopener noreferrer">
                                    Cookies
                                    <span className="sr-only">Opens in new window</span>
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col md={3} className="footer-divider d-block d-md-none">
                        <hr />
                    </Col>
                    <Col md={{ size: 3, offset: 2 }} className="footer-social align-self-end float-right">
                        <ul>
                            <li>
                                <a href="https://www.linkedin.com/company/australian-trade-commission" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedinIn} />
                                    <span className="sr-only">LinkedIn</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/austradeofficial" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faInstagram} className="instagram" />
                                    <span className="sr-only">Instagram</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/user/austrade" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faYoutube} />
                                    <span className="sr-only">YouTube</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Austrade" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faXTwitter} />
                                    <span className="sr-only">Twitter</span>
                                </a>
                            </li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default FooterTop;
