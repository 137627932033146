import { StringFilterDictionary } from './StringFilterDictionary';
import { ToolsAndTemplatesItem } from './ToolsAndTemplatesItem';

export class ToolsAndTemplatesUtils {
    static getItemType = (item: ToolsAndTemplatesItem) => {
        if (item.elements.tag.value?.length > 0) {
            return { code: item.elements.tag.value[0].codename, name: item.elements.tag.value[0].name };
        }
        return null;
    };

    static applyTypesFilter = (toolsAndTemplatesItems: ToolsAndTemplatesItem[], filterCriteria?: StringFilterDictionary) => {
        if (filterCriteria) {
            const filteredTypeCodes = Object.keys(filterCriteria).filter((typeCode) => filterCriteria[typeCode]);

            if (filteredTypeCodes.length > 0) {
                const filteredItems = toolsAndTemplatesItems.filter((item) =>
                    filteredTypeCodes.includes(ToolsAndTemplatesUtils.getItemType(item).code),
                );
                return filteredItems;
            }
        }
        return toolsAndTemplatesItems;
    };
}
