import React, { createContext, ReactElement, ReactNode, useEffect, useReducer, useRef } from 'react';
import Reducer from './reducer';
import { ContextType, PersonalisationStateInterface } from './types';

export const initialState: PersonalisationStateInterface = {
    industryCode: '',
    sectorCode: '',
    isPersonalised: false,
    hsCode: '',
    marketCode: '',
};

function initializeState() {
    const fromLocalStorage = JSON.parse(localStorage.getItem('personalisationState') as string);
    return fromLocalStorage || initialState;
}

export const personalisationContext = createContext({} as ContextType);

export function PersonalisationStore({ children }: { children: ReactNode }): ReactElement {
    const [personalisationState, dispatch] = useReducer(Reducer, initializeState());
    const initialRenderPersonalisationState = useRef(true);

    useEffect(() => {
        if (initialRenderPersonalisationState.current) {
            initialRenderPersonalisationState.current = false;
            return;
        }

        const persistedObject = {
            industryCode: personalisationState.industryCode,
            sectorCode: personalisationState.sectorCode,
            hsCode: personalisationState.hsCode,
            marketCode: personalisationState.marketCode,
        };

        localStorage.setItem('personalisationState', JSON.stringify(persistedObject));
    }, [personalisationState]);

    return <personalisationContext.Provider value={{ personalisationState, dispatch }}>{children}</personalisationContext.Provider>;
}
