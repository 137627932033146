import React, { useState } from 'react';
import './FilterRadiobutton.scss';
import { Colors } from '../../../styles';
import ComRadioButton from '../../generic/formControls/ComRadioButton';

export interface FilterRadiobuttonProps {
    data: {
        id: string;
        label?: string;
        value?: string;
        count?: number;
        selection?: string;
        index: number;
    };
    name: string;
    onChange?: Function;
}

export const FilterRadiobutton = (props: FilterRadiobuttonProps) => {
    const [, setRefsCreated] = useState<boolean>(false);

    const handleOnChange = () => {
        props.onChange?.(props.data.value);
    };

    return (
        <div className="filter-radiobutton">
            <div className="filter-radiobutton-item">
                <div className="radio">
                    <ComRadioButton
                        data={{
                            Index: props.data.index,
                            Title: props.data.label,
                            Value: props.data.value,
                            Id: props.data.id,
                        }}
                        refsCreated={true}
                        handleOnRefCreate={() => {
                            setRefsCreated(true);
                        }}
                        selectedValue={props.data.selection}
                        name={props.name}
                        setLabelStyle={(p) => {
                            return {
                                color: p.Value === props.data.selection ? Colors.bgaBlueLinks : '',
                            };
                        }}
                        handleOnChange={handleOnChange}
                    />
                </div>
            </div>
            {props.data.count && props.data.count > 0 && <div className="filter-radiobutton-count">{props.data.count}</div>}
        </div>
    );
};
