import React, { ReactNode } from 'react';
import './PageBody.scss';
import { Container } from 'reactstrap';

interface BodyProps {
    children: ReactNode;
    pageType?: any;
}

export const HOTJAR_CONTENT_FEEDBACK_CSS_SELECTOR = 'hotjar-content-feedback';

const PageBody = (props: BodyProps) => {
    const isHomePage = () => window.location.pathname === '/';
    const getHotjarContentFeedbackCssSelector = () => (isHomePage() ? '' : HOTJAR_CONTENT_FEEDBACK_CSS_SELECTOR);

    return (
        <div id="mainsection" className={`page-body ${getHotjarContentFeedbackCssSelector()}`}>
            <Container>{props.children}</Container>
        </div>
    );
};
export default PageBody;
