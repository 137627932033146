import { ComButton } from '@exporter-services/common-ui';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

const AcceptCookiesBanner = () => {
    const [, setShow] = useState(true);

    const getCookies = localStorage.getItem('cookies');

    const acceptCookies = () => {
        localStorage.setItem('cookies', 'true');
        setShow(false);
    };

    return (
        <>
            {getCookies !== 'true' && (
                <Container className="accept-cookies-banner pt-3 pb-3 d-print-none">
                    <Row>
                        <Col md={9}>
                            <FontAwesomeIcon icon={faInfoCircle} /> By continuing to use this website, you accept our use of{' '}
                            <a href="https://www.austrade.gov.au/site-information/about-cookies" target="_blank" rel="noopener noreferrer">
                                cookies
                                <span className="sr-only">Opens in new window</span>
                            </a>{' '}
                            under the conditions set out in our{' '}
                            <a href="https://www.austrade.gov.au/site-information/privacy-policy" target="_blank" rel="noopener noreferrer">
                                privacy policy
                                <span className="sr-only">Opens in new window</span>
                            </a>
                        </Col>
                        <Col md={3} className="text-end">
                            <ComButton onClick={() => acceptCookies()}>Accept</ComButton>
                        </Col>
                    </Row>
                </Container>
            )}
        </>
    );
};

export default AcceptCookiesBanner;
