import React, { useRef, useEffect, useCallback, useContext } from 'react';
import { uniqueId } from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import './leftNavigation.scss';
import { personalisationContext } from '../../providers/personalisation';
import { validatePersonalisation } from '../../utils/personalisationUtils';
import useGetKontentPlaceholderValues, { KontentPlaceHolder, replacePlaceHolders } from '../../hooks/useGetKontentPlaceholderValues';

export interface LeftNavLink {
    id: string;
    title: string;
    selected?: boolean;
    visible: boolean;
    industrySector?: any;
    skipPersonalisationCheck?: boolean;
}

export interface LeftNavControlProps {
    id?: string;
    classNames?: string;
    leftNavLinks: LeftNavLink[];
}

const LeftNavControl = (props: LeftNavControlProps) => {
    const { location } = window; // Support pages using base tag
    const refSidebar = useRef<HTMLDivElement | null>(null);

    const { personalisationState } = useContext(personalisationContext);
    const kontentPlaceHolder: KontentPlaceHolder = useGetKontentPlaceholderValues().kontentPlaceHolder;

    useEffect(() => {
        document.addEventListener('scroll', handleScroll);
    }, []);

    const handleScroll = useCallback(() => {
        if (refSidebar.current) {
            const h2s = document.getElementsByTagName('h2');
            const navLinks = Array.from(refSidebar.current.querySelectorAll('.left-nav-list-item a'));
            let matchFound = false;

            for (let i = 0; i < h2s.length; i++) {
                let h2 = h2s[i] as HTMLElement;
                let navLink = navLinks.find((l) => l.textContent.toLowerCase().trim() === h2.textContent.toLowerCase().trim());

                navLink?.classList.remove('is-current');

                const h2Bottom = h2.getBoundingClientRect().bottom;

                if (!matchFound && h2Bottom > 0) {
                    if (isContentVisible(h2Bottom)) {
                        navLink?.classList.add('is-current');
                    } else if (i > 0) {
                        h2 = h2s[i - 1] as HTMLElement;
                        navLink = navLinks.find((l) => l.textContent.toLowerCase().trim() === h2.textContent.toLowerCase().trim());
                        navLink?.classList.add('is-current');
                    }

                    matchFound = true;
                }
            }
        }
    }, []);

    const isContentVisible = (h2Bottom: number) => {
        const windowHeight = window.innerHeight;
        const isVisible = windowHeight > h2Bottom && windowHeight < h2Bottom + windowHeight;

        return isVisible;
    };

    return (
        <>
            <div id={props.id ? props.id : uniqueId('leftnav_')} className="left-hand-content-menu sticky-top" ref={refSidebar}>
                <p className="leftNavHeading">On this page</p>
                <ul className="left-nav-list">
                    {props.leftNavLinks.map((r) => {
                        if (
                            r.title &&
                            r.visible &&
                            (r.skipPersonalisationCheck || validatePersonalisation(personalisationState, r?.industrySector))
                        ) {
                            return (
                                <li className={'left-nav-list-item'} key={r.id}>
                                    <Link to={`${location.pathname}${location.search}#${r.id}`} id={`link-${r.id}`} className={'nav-anchor'}>
                                        {replacePlaceHolders(r.title, kontentPlaceHolder)}
                                    </Link>
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            </div>
        </>
    );
};

export default LeftNavControl;
