import React, { BaseSyntheticEvent, KeyboardEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import AsyncSelect from 'react-select/async';
import './SiteSearchInput.scss';
import { PageName } from '../../models/PageName';
import { CSSObjectWithLabel, InputActionMeta } from 'react-select';
import { useLazyGetAutoCompleteResultsQuery } from '../../providers/reducers/siteSearchApi';
import { sanitiseCapturingGroup } from '../../utils/utils';

type SiteSearchInputProps = {
    replaceHistory?: boolean;
    onFocus?: () => void;
    onBlur?: (e: BaseSyntheticEvent) => void;
};

export const SiteSearchInput = ({ replaceHistory, onFocus, onBlur }: SiteSearchInputProps) => {
    const DL_EVENT_NAME = 'site_search';
    const MIN_CHARS_FOR_SEARCH = 2;
    const [query] = useSearchParams();

    const [getAutoCompleteResults] = useLazyGetAutoCompleteResultsQuery();

    const [originalSearchText, setOriginalSearchText] = useState('');
    const [searchText, setSearchText] = useState(query.get('searchTerm') || '');
    const [options, setOptions] = useState([]);
    const [upDownArrow, setArrowUpDown] = useState(false);
    const [focusedOption, setFocusedOption] = useState('');
    const [searchTextCleared, setSearchTextCleared] = useState(false);

    const selectRef = useRef(null);
    const btnRef = useRef<HTMLButtonElement>(null);

    const customStyles = {
        container: (): CSSObjectWithLabel => ({ width: '100%', position: 'relative' }),
        option: (styles: any, state: any) => {
            if (upDownArrow && state.isFocused) setFocusedOption(state.data.value);
            return {
                ...styles,
            };
        },
        control: () => ({}),
        valueContainer: () => ({}),
        input: () => ({}),
    };

    const handleSearch = (selectedOption: string = '') => {
        const searchTerm = selectedOption || searchText;

        if (searchTerm) {
            TagManager.dataLayer({
                dataLayer: {
                    event: DL_EVENT_NAME,
                    dlv_site_search_query: originalSearchText || searchTerm,
                    dlv_autodrop_click: !!selectedOption || options.slice(1).some((option) => option.value === searchTerm),
                },
            });

            setOptions([]);

            const url = `/${PageName.SiteSearchResults}?searchTerm=${encodeURIComponent(searchTerm)}`;
            replaceHistory ? window.location.replace(url) : window.location.assign(url);
        }
    };

    useEffect(() => {
        if (selectRef.current.inputRef) {
            selectRef.current.inputRef.placeholder = 'Search';
            selectRef.current.inputRef.setAttribute('data-testid', 'search-text');
            selectRef.current.inputRef.setAttribute('style', '');
            selectRef.current.inputRef.spellcheck = true;
        }
    }, [(selectRef?.current as any)?.inputRef]);

    useEffect(() => {
        if (upDownArrow && focusedOption) setSearchText(focusedOption);
    }, [upDownArrow, focusedOption]);

    const loadSuggestions = async (searchValue: string) => {
        let mappedSuggestions = [];

        if (searchValue.length >= MIN_CHARS_FOR_SEARCH) {
            const suggestions = await getAutoCompleteResults({ searchTerm: encodeURIComponent(searchValue) }, true).unwrap();

            mappedSuggestions = suggestions.map((el) => ({
                value: el,
                label: el.replaceAll(new RegExp(`(${sanitiseCapturingGroup(searchValue, true)})`, 'gi'), '<strong class="highlight">$1</strong>'),
            }));

            mappedSuggestions.unshift({ value: searchValue, label: searchValue });
        }

        setOptions(mappedSuggestions);

        return new Promise<any>((resolve) => resolve(mappedSuggestions));
    };

    const clearText = () => {
        setSearchText('');
        setOptions([]);
        setArrowUpDown(false);
        setFocusedOption('');
        setSearchTextCleared(true);

        if (query.size > 0) {
            window.location.replace(`/${PageName.SiteSearchResults}`);
        }
    };

    const displayOptions = useMemo(() => options.length > 0 && searchText.length >= MIN_CHARS_FOR_SEARCH, [options, searchText]);

    useEffect(() => {
        if (searchTextCleared) {
            selectRef?.current?.inputRef?.focus();
            setSearchTextCleared(false);
        }
    }, [searchTextCleared]);

    return (
        <>
            <AsyncSelect
                ref={selectRef}
                inputId="search-text"
                classNamePrefix="site-search"
                placeholder=""
                formatOptionLabel={(data) => {
                    return (
                        <div
                            className="w-100"
                            onClick={(e) => {
                                setSearchText((e.target as HTMLDivElement).innerText);
                                setArrowUpDown(false);
                                setFocusedOption('');
                                handleSearch((e.target as HTMLDivElement).innerText);
                            }}
                            onMouseMove={(e) => setArrowUpDown(false)}
                            dangerouslySetInnerHTML={{ __html: data?.label }}
                        />
                    );
                }}
                tabSelectsValue={false}
                isOptionSelected={(option, selectValue) => {
                    return option.value === selectValue?.[0]?.value;
                }}
                loadingMessage={({ inputValue }) => {
                    if (inputValue.length >= MIN_CHARS_FOR_SEARCH) return 'Loading...';
                    return null;
                }}
                noOptionsMessage={({ inputValue }) => {
                    if (!inputValue || inputValue.length < MIN_CHARS_FOR_SEARCH || options.length <= 0) return null;
                }}
                defaultOptions={options}
                loadOptions={loadSuggestions}
                isClearable
                isSearchable
                openMenuOnClick={displayOptions}
                openMenuOnFocus={displayOptions}
                defaultValue={{ label: searchText, value: searchText }}
                value={{ label: searchText, value: searchText }}
                aria-label="Please enter your search text"
                styles={customStyles}
                onMenuClose={() => {
                    setArrowUpDown(false);
                    setFocusedOption('');
                }}
                onFocus={() => {
                    loadSuggestions(searchText);
                    onFocus?.();
                }}
                onBlur={onBlur}
                onInputChange={(value: string, actionMeta: InputActionMeta) => {
                    if (actionMeta.action === 'input-change') {
                        setOriginalSearchText(value);
                        setSearchText(value);
                    }
                }}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => {
                    setArrowUpDown(false);
                    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') setArrowUpDown(true);

                    if (e.key === 'Enter') {
                        if (searchText.length >= MIN_CHARS_FOR_SEARCH) {
                            btnRef.current.focus();
                            btnRef.current.click();
                        }
                    }
                }}
                inputValue={searchText}
            />

            <div className="search-btn">
                {searchText && (
                    <div
                        className="clear-text-container"
                        tabIndex={0}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') clearText();
                        }}
                        onClick={clearText}
                        aria-label="clear search text, clickable icon"
                    >
                        <span className="close-mini-icon clear-text"></span>
                    </div>
                )}
                <button
                    className="search-icon"
                    ref={btnRef}
                    onClick={() => handleSearch()}
                    aria-label="search for content containing your search text"
                ></button>
            </div>
        </>
    );
};
