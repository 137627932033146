import React, { VoidFunctionComponent } from 'react';
import { isStringNullOrUndefined, STRING_EMPTY } from '../../utils/stringHelper';

export interface ComImageProps extends React.ImgHTMLAttributes<any> {
    src: string;
    alt: string;
}

const ComImage: VoidFunctionComponent<ComImageProps> = (props): JSX.Element => {
    const getAltText = () => (!isStringNullOrUndefined(props.alt) ? props.alt : STRING_EMPTY);

    return <img {...props} src={props.src} alt={getAltText()} />;
};

export default ComImage;
