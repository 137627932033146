import { combineReducers } from 'redux';
import hierarchyApiReducer from './hierarchyApi';
import lawsAndRegsReducer from './lawsAndRegsSlice';
import singleServicesReducer from './singletonServicesSlice';
import mvLawsAndRegsReducer from './mvLawsAndRegsSlice';
import pageReducer from './pageSlice';
import marketReducer from './marketSlice';
import userReducer from './userSlice';
import marketsApiReducer from './marketsApi';
import mvLawsAndRegsApiReducer from './mvLawsAndRegsApi';
import commoditiesApiReducer from './commoditiesApi';
import newsFilterReducer from './newsFilterSlice';
import productServiceSelectionReducer from './productServiceSelectionSlice';
import taxonomyReducer from './taxonomySlice';
import siteSearchApiReducer from './siteSearchApi';
import commodityReducer from './commoditySlice';
import exportPlanReducer from './exportPlanSlice';

const globalReducer = combineReducers({
    singletonServices: singleServicesReducer,
    lawsAndRegs: lawsAndRegsReducer,
    mvLawsAndRegs: mvLawsAndRegsReducer,
    productServiceSelection: productServiceSelectionReducer,
    page: pageReducer,
    market: marketReducer,
    user: userReducer,
    newsFilter: newsFilterReducer,
    taxonomy: taxonomyReducer,
    commoditiesApi: commoditiesApiReducer,
    hierarchyApi: hierarchyApiReducer,
    marketsApi: marketsApiReducer,
    mvLawsAndRegsApi: mvLawsAndRegsApiReducer,
    siteSearchApi: siteSearchApiReducer,
    commodity: commodityReducer,
    exportPlan: exportPlanReducer,
});

export default globalReducer;
