import React, { useEffect, useState } from 'react';
import { MenuItemData } from './MenuItemData';
import './AccountMenu.scss';

interface AccountMenuProps {
    data: {
        menu: Array<MenuItemData>;
    };
}

const AccountMenu = (props: AccountMenuProps) => {
    const [showAccount, setShowAccount] = useState(false);
    const [accountMenuItems, setAccountMenuItems] = useState<any>(null);

    const hideAccountMenu = (event: any) => {
        if (!event.target?.parentNode?.closest('.account-wrapper')) {
            setShowAccount(false);
        }
    };

    useEffect(() => {
        window.addEventListener('click', hideAccountMenu);

        return () => window.removeEventListener('click', hideAccountMenu);
    }, []);

    useEffect(() => {
        if (props.data?.menu) {
            const accountMenuItems = buildAccountMenu(props.data?.menu).flat(5);
            if (accountMenuItems) {
                setAccountMenuItems(accountMenuItems);
            }
        }
    }, [props.data?.menu]);

    const buildAccountMenu = (items: MenuItemData[]) => {
        let childMenus = [];
        return items.map((item: MenuItemData) => {
            if (item.menuItems?.length) childMenus = buildAccountMenu(item.menuItems);

            if (item.showInAccountMenu) {
                const mainMenu = {
                    id: item.id,
                    title: item.title,
                    url: item.url,
                    menuIcon: item.menuIcon,
                };

                return [mainMenu, ...childMenus];
            }

            return [...childMenus];
        });
    };

    return (
        <div className="account-wrapper">
            <div
                className="account-initials"
                data-testid="account-initials"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') setShowAccount((flag) => !flag);
                }}
                onClick={() => setShowAccount((flag) => !flag)}
            >
                VI
            </div>
            {showAccount && (
                <div className="account-menu">
                    <div>
                        <div className="account-initials menu-item">VI</div>
                        <div className="account-name" title="Venkat Iyer">
                            Venkat Iyer
                        </div>
                    </div>
                    <div className="menu-items-container">
                        {accountMenuItems?.map((item: any) => (
                            <a className="menu-item" key={item.id} href={item.url}>
                                {item.menuIcon?.value?.[0] ? (
                                    <img className="icon" src={item.menuIcon.value[0].url} alt={item.menuIcon.value[0].description || ''} />
                                ) : (
                                    <div className="icon-placeholder"></div>
                                )}
                                <div className="menu-title">{item.title}</div>
                            </a>
                        ))}
                    </div>
                    <a className="menu-item" href="/">
                        <div className="icon signout-icon"></div>
                        <div className="menu-title">Sign out</div>
                    </a>
                </div>
            )}
        </div>
    );
};

export default AccountMenu;
