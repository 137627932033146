import React, { useState } from 'react';
import AcceptCookiesBanner from './AcceptCookiesBanner';
import FeedbackBanner from './FeedbackBanner';
import './Header.scss';
import HeaderTitle from './HeaderTitle';
import NavigationMenu from '../../controls/menus/NavigationMenu';
import { MenuItemData } from '../../controls/menus/MenuItemData';

interface HeaderProps {
    data: {
        menu: Array<MenuItemData>;
    };
}

const Header = (props: HeaderProps) => {
    const [isShowMobileNavMenu, setIsShowMobileNavMenu] = useState(false);

    function handleMobileNavMenuClick() {
        setIsShowMobileNavMenu(!isShowMobileNavMenu);
    }

    return (
        <>
            <AcceptCookiesBanner />
            <FeedbackBanner data={{ menu: props.data?.menu }} />
            <HeaderTitle isMobileNavMenuOpen={isShowMobileNavMenu} onMobileNavMenuClick={handleMobileNavMenuClick} />
            {props.data?.menu && <NavigationMenu data={{ menu: props.data?.menu, isShowMobileMenu: isShowMobileNavMenu }} />}
        </>
    );
};

export default Header;
