import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StepperItem } from './StepperItem';
import QuizQuestionsContainer from './QuizQuestionsContainer';
import { personalisationContext } from '../../providers/personalisation';
import { CommonConstants } from '../../CommonConstants';
import { useAppSelector } from '../../hooks/reduxHooks';
import {
    LocalStorageQuestionAnswer,
    getAnswerFromQuerystring,
    getAnswerFromLocalStorage,
    readQuestionAnswersFromLocalStorage,
    storeQuestionAnswersToLocalStorage,
    isLocalStorageContainingQuizResponsesWhenPersonalisationIsByLocalStorage,
    isLocalStorageContainingQuizResponsesWhenPersonalisationIsByQuerystring,
    isWhenPersonalisationIsByLocalStorage,
    getLocalStorageKey,
} from './Stepper.utils';
import { OnLoadPersonalisationInfo } from '../../models/ReduxModels';
import {
    findEarliestQuizQuestionUnansweredFromQuerystring,
    generateServiceQueryString,
    isPersonalisationQuerystringContainingResults,
    QuizAnswersStateType,
} from '../../utils/personalisationUtils';
import { usePrevious } from '../../hooks/usePrevious';

interface StepperProps {
    data: {
        content: StepperItem;
    };
}

const Stepper = (props: StepperProps) => {
    const NOT_LISTED_CODENAME = CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME;
    const [questionArray, setQuestionArray] = useState<any>(null);
    const { personalisationState } = useContext(personalisationContext);
    const [industry, setIndustry] = useState<string>();
    const [previousIndustryNewlyPopulated, setPreviousIndustryNewlyPopulated] = useState<string>();
    const onLoadPersonalisation = useAppSelector<OnLoadPersonalisationInfo>((state) => state.productServiceSelection.onLoadPersonalisationInfo);
    const previousOnLoadPersonalisationState = usePrevious(onLoadPersonalisation.isSet) ?? false;
    const [isOnLoadActionComplete, setIsOnLoadActionsComplete] = useState<boolean>(false);
    const [localStorageData, setLocalStorageData] = useState<LocalStorageQuestionAnswer[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        const sectorCode = personalisationState.sectorCode;

        setIndustry(
            (!sectorCode ||
            !props.data.content.elements.checklist_items['linkedItems'].find((a) =>
                a.elements.industry_sector?.value?.find((a: any) => a.codename === sectorCode),
            )
                ? NOT_LISTED_CODENAME
                : sectorCode
            ).toLowerCase(),
        );
    }, [personalisationState.sectorCode]);

    useEffect(() => {
        const data = readQuestionAnswersFromLocalStorage(industry);
        setLocalStorageData(data);
    }, [industry]);

    useEffect(() => {
        if (personalisationState.startAgain) {
            Object.keys(localStorage)
                .filter((key) => key.startsWith('quiz'))
                .forEach((key) => localStorage.removeItem(key));

            if (industry === NOT_LISTED_CODENAME) populateQuizResponsesIfAvailable(false, industry);
        }
    }, [personalisationState.startAgain]);

    const initializeQuestionList = (): [] => {
        let filteredQns = props.data.content.elements.checklist_items['linkedItems'].filter(
            (a) =>
                a.elements.industry_sector?.value?.find((a: any) => a.codename === industry) ||
                a.elements.industry_sector?.value?.find((a: any) => a.codename === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME),
        ) as any;

        return filteredQns.map((b: any, i: number) => ({
            id: b.system.id,
            question: b.elements.question,
            yes_message: b.elements.yes_message,
            no_message: b.elements.no_message,
            next_steps: b.elements.next_steps,
            answer: null,
        }));
    };

    const populateAnswersFromQuerystring = (data: { id: any; answer: any }[]) => {
        for (let index = 0; index < data.length; index++) {
            const questionAnswer = data[index];
            questionAnswer.answer = getAnswerFromQuerystring(onLoadPersonalisation, index + 1);
        }
    };

    const populateAnswersFromLocalStorage = (data: { id: any; answer: any }[]) => {
        for (let index = 0; index < data.length; index++) {
            const questionAnswer = data[index];
            questionAnswer.answer = getAnswerFromLocalStorage(questionAnswer.id, localStorageData);
        }
    };

    const populateQuizResponsesIfAvailable = (isGetAnswersFromQuerystring: boolean, industry: string) => {
        let data = initializeQuestionList();
        if (isGetAnswersFromQuerystring) {
            populateAnswersFromQuerystring(data);
        }

        setQuestionArray(data);
        setPreviousIndustryNewlyPopulated(industry);
        storeQuestionAnswersToLocalStorage(data, industry);
        return data;
    };

    const populateQuestionDataForIndustry = (industry: string, isGetAnswersFromQuerystring: boolean) => {
        if (props.data.content.elements.checklist_items.value.length > 0) {
            populateQuizResponsesIfAvailable(isGetAnswersFromQuerystring, industry);
        }
    };

    const populateQuestionDataForIndustryViaLocalStorage = () => {
        if (props.data.content.elements.checklist_items.value.length > 0) {
            let data = initializeQuestionList();
            populateAnswersFromLocalStorage(data);
            setQuestionArray(data);
        }
    };

    const populateQuestionData = useCallback(
        (industry: string, previousIndustryNewlyPopulated: string, isGetAnswersFromQuerystring: boolean) => {
            if (localStorageData?.length > 0) {
                //user resuming quiz from saved responses earlier
                let data = initializeQuestionList();
                populateAnswersFromLocalStorage(data);
                setQuestionArray(data);
            } else if (industry && industry !== previousIndustryNewlyPopulated) {
                //user viewing the quiz for the first time will have empty responses
                populateQuestionDataForIndustry(industry, isGetAnswersFromQuerystring);
            }
        },
        [localStorageData, previousOnLoadPersonalisationState, industry, previousIndustryNewlyPopulated, onLoadPersonalisation.isSet],
    );

    const handleOnLoadPopulateNotListed = () => {
        let data = initializeQuestionList();
        if (isPersonalisationQuerystringContainingResults(onLoadPersonalisation)) populateAnswersFromQuerystring(data);
        else if (localStorage.getItem(getLocalStorageKey(industry))) populateAnswersFromLocalStorage(data);

        setQuestionArray(data);
    };

    const removeQuestionsAndAnswersFromQuerystring = () => {
        navigate(generateServiceQueryString(personalisationState.industryCode, personalisationState.sectorCode));
    };

    const handleOnLoadPopulateSelectableIndustryQuizResponses = () => {
        if (isPersonalisationQuerystringContainingResults(onLoadPersonalisation)) {
            populateQuestionDataForIndustry(onLoadPersonalisation.sector, true);

            const questionsAnsweredState = findEarliestQuizQuestionUnansweredFromQuerystring(onLoadPersonalisation, initializeQuestionList());
            if (questionsAnsweredState.type !== QuizAnswersStateType.AllAnswered) {
                removeQuestionsAndAnswersFromQuerystring();
            }
        } else if (isWhenPersonalisationIsByLocalStorage(onLoadPersonalisation, personalisationState)) {
            if (isLocalStorageContainingQuizResponsesWhenPersonalisationIsByLocalStorage(onLoadPersonalisation, personalisationState)) {
                populateQuestionDataForIndustryViaLocalStorage();
            } else {
                populateQuizResponsesIfAvailable(false, personalisationState.sectorCode);
            }
        } else if (isLocalStorageContainingQuizResponsesWhenPersonalisationIsByQuerystring(onLoadPersonalisation, personalisationState)) {
            populateQuestionDataForIndustryViaLocalStorage();
        }
    };

    const isReadyToProcessOnLoadPersonalisation = (onLoadPersonalisation: OnLoadPersonalisationInfo, currentIndustry: string) => {
        return (
            onLoadPersonalisation.isSet &&
            ((onLoadPersonalisation.sector === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME &&
                currentIndustry === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME) ||
                (onLoadPersonalisation.sector !== CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME &&
                    currentIndustry === onLoadPersonalisation.sector))
        );
    };

    useEffect(() => {
        if (!isOnLoadActionComplete && isReadyToProcessOnLoadPersonalisation(onLoadPersonalisation, industry)) {
            handleOnLoadPopulateNotListed();

            handleOnLoadPopulateSelectableIndustryQuizResponses();

            setIsOnLoadActionsComplete(true);
        } else if (onLoadPersonalisation.isSet) {
            populateQuestionData(industry, previousIndustryNewlyPopulated, false);
        }
    }, [localStorageData, industry, onLoadPersonalisation.isSet]);

    const onRadioButtonClick = (e: any, a: any) => {
        let questionArrayItem = questionArray[getIndex(a)];
        if (questionArrayItem) {
            questionArrayItem.answer = e.target.value;
            storeQuestionAnswersToLocalStorage(questionArray, industry);
        }
    };

    const getIndex = (question: any) => {
        return questionArray.findIndex((a: any) => a.id === question.id);
    };

    return (
        <>
            {questionArray?.length > 0 ? (
                <QuizQuestionsContainer
                    industry={industry}
                    questions={questionArray}
                    generic_yes_message={props.data.content.elements.generic_yes_message}
                    generic_no_message={props.data.content.elements.generic_no_message}
                    onAnswerClicked={onRadioButtonClick}
                    isOnLoadQuizAnswersApplied={isOnLoadActionComplete}
                />
            ) : (
                <></>
            )}
        </>
    );
};
export default Stepper;
