import React, { useEffect, useState } from 'react';
import './MarketSearchPathway.scss';
import { client } from '../../config';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { useAppSelector } from '../../hooks/reduxHooks';
import { CurrentMarketSearchToolSearchData, ProductServiceSelectionInfo } from '../../models/ReduxModels';
import { PageName } from '../../models/PageName';
import { ImageTilePathway, ImageTilePathwayItem } from '@exporter-services/common-ui';
import {
    getImageItemDescription,
    getImageItemByMarketCode,
    getImageItemUrl,
    getImageItemSystemId,
} from '@exporter-services/common-ui/src/utils/contentItemHelper';
import { getMarketDisplayName } from '../../utils/marketSearchUtils';

interface MarketSearchPathwayProps {
    data: {
        content: any;
    };
    strongMarkets?: boolean;
    match: any;
}

const MarketSearchPathway = (props: MarketSearchPathwayProps) => {
    const [images, setImages] = useState(null);
    const [imageTilePathwayItems, setImageTilePathwayItems] = useState<ImageTilePathwayItem[]>([]);
    const [loading, setLoading] = useState(true);

    const productsServicesSelectionState = useAppSelector<ProductServiceSelectionInfo>(
        (state) => state.productServiceSelection.productServiceSelectionInfo,
    );

    const pendingMarketSearchInfo = useAppSelector((state) => state.market.pendingMarketSearchInfo);
    const marketSearchData = useAppSelector<CurrentMarketSearchToolSearchData>((state) => state.market.currentMarketSearchResultsInfo);

    const industry = props.match?.params?.industry ? props.match?.params?.industry?.replace(/-/g, '_') : productsServicesSelectionState.industryCode;

    const sector = props.match?.params?.sector
        ? props.match?.params?.sector?.replace(/-/g, '_')
        : props.match?.params?.industry
          ? props.match?.params?.industry?.replace(/-/g, '_')
          : productsServicesSelectionState.hsCode
            ? productsServicesSelectionState.hsCode
            : productsServicesSelectionState.sectorCode;

    useEffect(() => {
        if (pendingMarketSearchInfo?.timeTag || !marketSearchData?.timeTag) {
            return;
        }

        client
            .items()
            .equalsFilter('system.type', 'market_image')
            .depthParameter(2)
            .toPromise()
            .then((response) => {
                setImages(response.data.items);
            });
    }, [pendingMarketSearchInfo?.timeTag, marketSearchData?.timeTag]);

    useEffect(() => {
        if (images) {
            const pathwayItems = props.data.content.map((m) => {
                const marketCode = m.market;
                const marketImageItem = getImageItemByMarketCode(images, marketCode);

                return {
                    systemId: getImageItemSystemId(marketImageItem),
                    title: getMarketDisplayName(marketImageItem.elements.marketcodesnippet__market.value[0].name),
                    imageUrl: getImageItemUrl(marketImageItem),
                    imageAltText: getImageItemDescription(marketImageItem),
                    pathway_link: { value: getMarketUrl(marketCode) },
                } as ImageTilePathwayItem;
            });
            setImageTilePathwayItems(pathwayItems);
            setLoading(false);
        }
    }, [props.data.content, images]);

    const getMarketUrl = (market: string) => {
        const marketUrl =
            props.match && props.strongMarkets
                ? `/${props.match.params.slug}/${PageName.ShortlistProducts}/${sector}/${market.toLowerCase()}`
                : props.match && !props.strongMarkets
                  ? `/${props.match.params.slug}/${PageName.Shortlist}/${industry}/${sector}/${market.toLowerCase()}`
                  : '';
        return marketUrl;
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            <div className="market-search-pathway-box-container">
                <ImageTilePathway data={{ items: imageTilePathwayItems }} client={client} />
            </div>
        </LoadingOverlay>
    );
};
export default MarketSearchPathway;
