import { NewsFilterInfo } from '../../models/ReduxModels';
import GlobalState, { NewsFilterState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { NewsFilterItem } from '../../models/NewsFilterItem';

const initState: NewsFilterState = {
    newsFilterInfo: {
        data: null,
        retrieved: false,
        pending: false,
    },
};

const newsFilterSlice = createSlice({
    name: 'newsFilter',
    initialState: initState,
    reducers: {
        pendingNewsFilterInfo(state) {
            state.newsFilterInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getNewsDataFromKentico.fulfilled, (state, action) => {
            state.newsFilterInfo = action.payload as NewsFilterInfo;
        });
    },
});

export const getNewsDataFromKentico = createAsyncThunk('newsFilter/getNewsData', async (request: null, thunkAPI: any) => {
    const state = thunkAPI.getState() as GlobalState;
    if (state.newsFilter.newsFilterInfo.retrieved || state.newsFilter.newsFilterInfo.pending) {
        return thunkAPI.rejectWithValue(true);
    }
    thunkAPI.dispatch(newsFilterSlice.actions.pendingNewsFilterInfo());
    return await new Promise<NewsFilterInfo>((resolve) => {
        state.singletonServices.newsFilterService
            .getNewsFilterData()
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp as NewsFilterItem[] });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

const newsFilterReducer = newsFilterSlice.reducer;

export default newsFilterReducer;
