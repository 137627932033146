import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const FooterBottom = () => {
    return (
        <footer>
            <Container fluid className="footer-bottom py-5 d-print-none">
                <Container>
                    <Row>
                        <Col>
                            <p className="pt-4">
                                We acknowledge the traditional owners of the country throughout Australia and their continuing connection to land, sea
                                and community. We pay our respect to them and their cultures and to the elders past and present.
                            </p>
                            <p>Authorised by the Australian Government, Canberra</p>
                            <p>
                                <a
                                    href="https://www.austrade.gov.au/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title="Australian Trade and Investment Commission (Austrade)"
                                >
                                    Built by Australian Trade and Investment Commission (Austrade)
                                </a>
                            </p>

                            <p>© {new Date().getFullYear()} Australian Trade and Investment Commission </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </footer>
    );
};

export default FooterBottom;
