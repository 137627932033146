import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { HscodeHierarchy } from '../../models/HscodeHierarchy';
import { HierarchyResult } from '../../models/HierarchyResult';

export const hierarchyApi = createApi({
    baseQuery: fetchBaseQuery({ baseUrl: '/api/' }),
    reducerPath: 'hierarchyApi',
    endpoints: (build) => ({
        getMVCommodityHierarchy: build.query<HscodeHierarchy[], { sector: string; market: string }>({
            query: ({ sector, market }) => `mv/hierarchy/countries/${market}/products/${sector}`,
            transformResponse: (response: HierarchyResult) => response.Hierarchy,
        }),
    }),
});

export type CommodityHierarchyApiResult = { data: HscodeHierarchy[]; isLoading: boolean; isError: boolean };

export const { useGetMVCommodityHierarchyQuery } = hierarchyApi;

export default hierarchyApi.reducer;
