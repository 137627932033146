import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

let reactPlugin = null;
let appInsights = null;

const createTelemetryService = () => {
    const initialize = (instrumentationKey, browserHistory) => {
        if (!instrumentationKey) {
            throw new Error('Instrumentation key not provided.');
        }

        reactPlugin = new ReactPlugin();

        appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: instrumentationKey,
                maxBatchInterval: 0,
                extensions: [reactPlugin],
                extensionConfig: {
                    [reactPlugin.identifier]: {
                        history: browserHistory,
                    },
                },
            },
        });

        appInsights.loadAppInsights();
    };

    return { reactPlugin, appInsights, initialize };
};

export const telemetryService = createTelemetryService();
export const getAppInsights = () => appInsights;
