import React from 'react';
import { FilteredResultsContainerItem } from './FilteredResultsContainerItem';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import FilteredToolsAndTemplatesResultsContainer from '../../domains/toolsandtemplates/FilteredToolsAndTemplatesResultsContainer';
import FilteredEventsResultsContainer from '../../domains/est/FilteredEventsResultsContainer';
import FilteredNewsResultsContainer from '../../domains/news/FilteredNewsResultsContainer';
import MarketExplorerSearchContainer from '../../domains/marketExplorer/MarketExplorerSearchContainer';
import SiteSearchResultsContainer from '../../domains/siteSearch/SiteSearchResultsContainer';

interface FilteredResultsContainerProps {
    data: {
        item: FilteredResultsContainerItem;
        linkedItems?: IContentItemsContainer;
    };
}

const FilteredResultsContainer = (props: FilteredResultsContainerProps) => {
    const resultType = props.data.item.elements.result_type?.value?.length > 0 ? props.data.item.elements.result_type.value[0].codename : '';

    const renderResultTypeContainer = () => {
        switch (resultType) {
            case 'search_results':
                return (
                    <SiteSearchResultsContainer
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                        }}
                    />
                );
            case 'markets':
                return <MarketExplorerSearchContainer />;
            case 'tools_and_templates':
                return (
                    <FilteredToolsAndTemplatesResultsContainer
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                        }}
                    />
                );
            case 'events':
                return <FilteredEventsResultsContainer />;
            case 'news':
                return (
                    <FilteredNewsResultsContainer
                        data={{
                            item: props.data.item,
                        }}
                    />
                );
            default:
                return '';
        }
    };

    return <div className="filtered-results-container">{renderResultTypeContainer()}</div>;
};
export default FilteredResultsContainer;
