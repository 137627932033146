import React, { useEffect, useState } from 'react';
import HeroPathway from '../heroPathway/HeroPathway';
import ImagePathway from '../imagePathway/ImagePathway';
import { HeroPathwayItem } from '../heroPathway/HeroPathwayItem';
import { ImagePathwayItem } from '../imagePathway/ImagePathwayItem';
import GuidePathway from '../guidePathway/GuidePathway';
import { GuidePathwayItem } from '../guidePathway/GuidePathwayItem';
import InlinePathway from '../inlinePathway/InlinePathway';
import { InlinePathwayItem } from '../inlinePathway/InlinePathwayItem';
import Contacts from '../contacts/Contacts';
import { ContactItem } from '../contacts/ContactItem';
import './PathwayList.scss';
import { getMultipleChoiceSelectedOptions } from '../../../utils/contentItemHelper';
import { DeliveryClient, ElementModels } from '@kontent-ai/delivery-sdk';
import { PageLayoutType } from '../../../models/enums';

export interface PathwayListProps {
    data: {
        cardItems: any[];
        title: string;
        title_tag?: any;
    };
    pageLayoutType?: PageLayoutType;
    client?: DeliveryClient;
}

const PathwayList = (props: PathwayListProps) => {
    const [titleTag, setTitleTag] = useState<ElementModels.MultipleChoiceOption>(null);

    useEffect(() => {
        setTitleTag(getMultipleChoiceSelectedOptions(props.data.title_tag)[0]);
    }, []);

    const renderPathway = () => {
        if (props.data.cardItems?.length > 0) {
            switch (props.data.cardItems[0].system.type) {
                case 'hero_pathway':
                    return (
                        <HeroPathway
                            data={{
                                content: props.data.cardItems as HeroPathwayItem[],
                                parentHeadingLevel: titleTag?.codename,
                            }}
                            client={props.client}
                        />
                    );
                case 'image_pathway':
                    return (
                        <ImagePathway
                            data={{
                                content: props.data.cardItems as ImagePathwayItem[],
                                parentHeadingLevel: titleTag?.codename,
                            }}
                            client={props.client}
                        />
                    );
                case 'guide_pathway':
                    return (
                        <GuidePathway
                            data={{
                                content: props.data.cardItems as GuidePathwayItem[],
                                parentHeadingLevel: titleTag?.codename,
                            }}
                            pageLayoutType={props.pageLayoutType}
                            client={props.client}
                        />
                    );
                case 'inline_pathway':
                    return (
                        <InlinePathway
                            data={{
                                content: props.data.cardItems as InlinePathwayItem[],
                            }}
                            client={props.client}
                        />
                    );
                case 'contact':
                    return <Contacts data={{ items: props.data.cardItems as ContactItem[] }} />;
                default:
                    return '';
            }
        } else {
            return '';
        }
    };

    return (
        <div className="pathway-list">
            {props.data.title && (titleTag?.codename === 'h3' ? <h3>{props.data.title}</h3> : <h2>{props.data.title}</h2>)}
            {renderPathway()}
        </div>
    );
};

export default PathwayList;
