import { ITaxonomyGroup, ITaxonomyTerms } from '@kontent-ai/delivery-sdk';

const getLabelName = (terms: ITaxonomyTerms[], codeName: string) => {
    let result = '';
    for (let term of terms) {
        if (term.codename === codeName) {
            return term.name;
        }
        result = getLabelName(term.terms, codeName);
        if (result) {
            break;
        }
    }

    return result;
};

export const getTaxonomyTermLabel = (taxonomyGroup: ITaxonomyGroup, taxonomy_term_code: string) => {
    return getLabelName(taxonomyGroup.terms, taxonomy_term_code);
};
