import { ExportPlanDownloadInfo, ExportPlanDownloadRequest } from '../../models/ReduxModels';
import GlobalState, { ExportPlanState } from '../globalState/globalState';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const initState: ExportPlanState = {
    exportPlanDownloadInfo: { retrieved: false, pending: false },
};

const exportPlanSlice = createSlice({
    name: 'exportPlan',
    initialState: initState,
    reducers: {
        pendingExportPlanDownloadInfo(state) {
            state.exportPlanDownloadInfo.pending = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exportPlanDownload.fulfilled, (state, action) => {
            state.exportPlanDownloadInfo = action.payload as ExportPlanDownloadInfo;
        });
    },
});

export const exportPlanDownload = createAsyncThunk('exportPlan/exportPlanDownload', async (request: ExportPlanDownloadRequest, thunkAPI) => {
    thunkAPI.dispatch(exportPlanSlice.actions.pendingExportPlanDownloadInfo());
    return await new Promise<ExportPlanDownloadInfo>((resolve) => {
        (thunkAPI.getState() as GlobalState).singletonServices.exportPlanService
            .exportPlanDownload(request)
            .then((resp) => {
                resolve({ retrieved: true, pending: false, data: resp });
            })
            .catch(() => {
                resolve({ retrieved: true, pending: false });
            });
    });
});

const exportPlanReducer = exportPlanSlice.reducer;

export default exportPlanReducer;
