import { PageOrchestratorProps } from '../models/PageOrchestratorProps';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './reduxHooks';
import { getCurrentMarketNameInfo } from '../providers/reducers/marketSlice';
import { getMvTaxesAndDutiesInfo } from '../providers/reducers/mvLawsAndRegsSlice';
import { LawsAndRegsPageService } from '../services/LawsAndRegsPageService';

export default function useDutiesandtaxesLawsAndRegsPageOrchestrator(props: PageOrchestratorProps): {
    isTariffFinder: boolean;
} {
    const dispatch = useAppDispatch();
    const lawsAndRegsService = useAppSelector<LawsAndRegsPageService>((state) => state.singletonServices.lawsAndRegsService);

    const { market, sector, third_slug } = props.match.params;

    const isTariffFinder = lawsAndRegsService.isDutiesAndTaxesPage(third_slug);

    useEffect(() => {
        if (isTariffFinder) {
            dispatch(getCurrentMarketNameInfo({ reactRouterDomMatch: props.match }));
            dispatch(getMvTaxesAndDutiesInfo({ commodity: sector, market: market }));
        }
    }, [isTariffFinder, sector, market]);

    return { isTariffFinder };
}
