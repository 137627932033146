import React from 'react';
import { SearchContainerItem } from './SearchContainerItem';
import SectorExplorerSearchContainer from '../../domains/sectorExplorer/SectorExplorerSearchContainer';
import MarketSearchToolSearchContainer from '../../domains/mst/MarketSearchToolSearchContainer';
import TariffsSearchToolSearchContainer from '../../domains/lar/TariffsSearchToolSearchContainer';
import ExportRulesFinderSearchContainer from '../../domains/lar/ExportRulesFinderSearchContainer';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import RestrictedToOnshore from '../../page/errorPages/RestrictedToOnshore';

interface SearchContainerProps {
    data: {
        item: SearchContainerItem;
        linkedItems?: IContentItemsContainer;
    };
    onLoad?: (dataAvailable: boolean, title: string) => void;
    match?: any;
}

const SearchContainer = (props: SearchContainerProps) => {
    const contentType = props.data.item.elements.list_of_questions['linkedItems'][0].system.type;

    const renderQuestion = () => {
        switch (contentType) {
            case 'tariffs_search_tool_search_container':
                return (
                    <TariffsSearchToolSearchContainer
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                        }}
                        onLoad={props.onLoad}
                    />
                );

            case 'export_rules_finder_search_container':
                return (
                    <ExportRulesFinderSearchContainer
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                        }}
                        onLoad={props.onLoad}
                    />
                );

            case 'sector_explorer_search_container':
                return (
                    <SectorExplorerSearchContainer
                        data={{
                            item: props.data.item,
                        }}
                    />
                );

            case 'market_search_tool_search_container':
                return (
                    <MarketSearchToolSearchContainer
                        match={props.match}
                        data={{
                            item: props.data.item,
                            linkedItems: props.data.linkedItems,
                        }}
                        onLoad={props.onLoad}
                    />
                );
            default:
                return '';
        }
    };

    const render = () => {
        const isTariffFinder = contentType === 'tariffs_search_tool_search_container';
        if (isTariffFinder) {
            return <RestrictedToOnshore>{renderQuestion()}</RestrictedToOnshore>;
        } else {
            return <>{renderQuestion()}</>;
        }
    };

    return <div className="search-container">{render()}</div>;
};
export default SearchContainer;
