import { redirectTo } from '../utils/utils';

export default class HttpServiceBase {
    public handleError(error: any) {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    redirectTo('/unauthorized');
                    break;
                case 403:
                    redirectTo('/forbidden');
                    break;
                case 404:
                    redirectTo('/not-found');
                    break;
                default:
                    console.error(error.message);
                    console.warn(error.response.config.method, error.response.config.url, error.response.status);
                    console.warn('Request headers', error.response.config.headers);
                    console.warn('Response header', error.response.headers);
                    console.warn(error.response.data);
                    redirectTo('/error');
                    break;
            }
        } else {
            console.error(error.message);
            redirectTo('/error');
        }

        return Promise.reject(error);
    }
}
