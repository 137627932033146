import React, { useRef } from 'react';
import './FilterHeader.scss';
import { Colors } from '../../../styles';
import { ENTER_KEY } from '../../../utils/interactionHelper';

export interface FilterHeaderProps {
    data?: {
        id?: string;
        controlWidth?: string;
        label?: string;
        open?: boolean;
    };
    onClick?: Function;
}

export const FilterHeader = (props: FilterHeaderProps) => {
    const headerRef = useRef(null);

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === ENTER_KEY) {
            headerRef.current.click();
        }
    };

    const handleOnClick = () => {
        props.onClick?.(!props.data.open);
    };

    return (
        <div
            tabIndex={0}
            className="filter-header"
            id="location"
            ref={headerRef}
            onKeyPress={handleOnKeyPress}
            onClick={handleOnClick}
            role="button"
            aria-expanded={props.data.open}
        >
            <div className="filter-header-title">
                <p className="label" id={props.data.label?.toLowerCase()}>
                    {props.data.label}
                </p>
                <svg className={props.data.open ? 'svg-open' : ''} xmlns="http://www.w3.org/2000/svg" width="20" height="12.5" viewBox="0 0 20 12.5">
                    <g transform="translate(20 12.5) rotate(180)">
                        <path
                            id="Chevron_Up"
                            data-name="Chevron / Up"
                            d="M10,4.965,2.478,12.5,0,10.018,10,0,20,10.018,17.522,12.5Z"
                            fill={Colors.bgaAlmostBlack}
                        ></path>
                    </g>
                </svg>
            </div>
        </div>
    );
};
