import { useEffect, useState } from 'react';
import { useAppSelector } from './reduxHooks';
import { CurrentMarketNameInfo } from '../models/ReduxModels';
import { useParams } from 'react-router-dom';

export interface KontentPlaceHolder {
    sector?: string;
    ahecc?: string;
    market?: string;
}

export default function useGetKontentPlaceholderValues(): {
    kontentPlaceHolder: KontentPlaceHolder;
} {
    const [kontentPlaceHolder, setKontentPlaceHolder] = useState({});
    const [marketNameUpdated, setMarketNameUpdated] = useState<boolean>(false);
    const [marketName, setMarketName] = useState<string>(null);

    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);

    let { sector } = useParams();

    useEffect(() => {
        if (currentMarketNameInfo.retrieved) {
            setMarketName(currentMarketNameInfo.data);
            setMarketNameUpdated(true);
        }
    }, [currentMarketNameInfo]);

    useEffect(() => {
        if (sector && sector.length > 0) {
            setKontentPlaceHolder({ market: marketName, sector: sector, ahecc: sector });
        }
    }, [sector, marketNameUpdated]);

    return { kontentPlaceHolder: kontentPlaceHolder };
}

export const replacePlaceHolders = (title: string, kontentPlaceHolder: KontentPlaceHolder) => {
    const { sector, ahecc, market } = kontentPlaceHolder || ({} as KontentPlaceHolder);

    if (title) {
        title = title.replaceAll('{{sector}}', sector ?? '{{sector}}');
        title = replaceAhecc(title, ahecc);
        title = title.replaceAll('{{market}}', market ?? '{{market}}');
    }
    return title;
};

const replaceAhecc = (initialAhecc: string, ahecc: string) => {
    if (initialAhecc.indexOf('{{ahecc}}') > -1) {
        initialAhecc = initialAhecc.replaceAll('{{ahecc}}', ahecc ?? '{{ahecc}}');
    }

    if (ahecc) {
        if (initialAhecc.indexOf('{{ahecc:4}}') > -1) {
            initialAhecc = initialAhecc.replaceAll('{{ahecc:4}}', ahecc?.substring(0, 4) ?? '{{ahecc}}');
        }

        if (initialAhecc.indexOf('{{ahecc:6}}') > -1) {
            initialAhecc = initialAhecc.replaceAll('{{ahecc:6}}', ahecc?.substring(0, 6) ?? '{{ahecc}}');
        }
    }

    return initialAhecc;
};
