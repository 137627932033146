import React, { useEffect, useState } from 'react';
import parse, { Element } from 'html-react-parser';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { useAppSelector } from '../../hooks/reduxHooks';
import './MVTariffs.scss';
import { MVDuty } from '../../models/MVDuty';
import { MVNote } from '../../models/MVNote';
import { CurrentMarketNameInfo, MvTaxesAndDutiesInfo } from '../../models/ReduxModels';
import Table from '../../controls/table/Table';

interface MVTariffsProps {
    data: {
        item: any;
        linkedItems: IContentItemsContainer;
    };
    match: any;
    type?: string;
}

const MVTariffs = (props: MVTariffsProps) => {
    const [tariffsData, setTariffsData] = useState<MVDuty[]>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [showDutiesComponent, setShowDutiesComponent] = useState<boolean>(true);
    const mvTaxesAndDutiesInfo = useAppSelector<MvTaxesAndDutiesInfo>((state) => state.mvLawsAndRegs.mvTaxesAndDutiesInfo);
    const currentMarketNameInfo = useAppSelector<CurrentMarketNameInfo>((state) => state.market.currentMarketNameInfo);
    const sector = props.match.params.sector;
    const [marketName, setMarketName] = useState<string>(null);

    const getDutyDescription = (dutyCode: string) => {
        switch (dutyCode) {
            case 'MFN':
                return 'Most favoured nation';
            default:
                return null;
        }
    };

    const overrideDutyDescription = (duties: MVDuty[]) => {
        if (duties?.length > 0) {
            return duties.map((duty) => {
                let dutyDescription = getDutyDescription(duty.DutyCode);
                return { ...duty, DutyDescription: dutyDescription ?? duty.DutyDescription };
            });
        }

        return duties;
    };

    //#region lifecycle
    useEffect(() => {
        if (mvTaxesAndDutiesInfo.retrieved) {
            const measureDutiesSectionTemp = mvTaxesAndDutiesInfo.data?.Duties?.length > 0;

            if (!measureDutiesSectionTemp) {
                setShowDutiesComponent(false);
            } else {
                setTariffsData(overrideDutyDescription(mvTaxesAndDutiesInfo.data?.Duties));
            }
        }
    }, [mvTaxesAndDutiesInfo.retrieved]);

    useEffect(() => {
        if (currentMarketNameInfo.retrieved) {
            setMarketName(currentMarketNameInfo.data);
        }
    }, [currentMarketNameInfo.retrieved]);

    useEffect(() => {
        if (mvTaxesAndDutiesInfo.retrieved && currentMarketNameInfo.retrieved) {
            setLoading(false);
        }
    }, [mvTaxesAndDutiesInfo.retrieved, currentMarketNameInfo.retrieved]);
    //#endregion

    const replaceEmbededObject = (domNode: Element) => {
        if (domNode.name === 'table') {
            return <Table table={domNode as Element} />;
        }
    };

    const generateDutiesTableContentHtml = () => {
        return `<table><tr><td>Type of tariff</td><td>Tariff rate</td></tr>${tariffsData
            .map(
                (t, i) =>
                    `<tr><td>${t.DutyDescription} (${t.DutyCode}) tariff</td><td>${t.Formula}${generateNotesTableContentHtml(t.Notes)}</td></tr>`,
            )
            .join('')}</table>`;
    };

    const generateNotesTableContentHtml = (notes: MVNote[]) => {
        if (!notes || notes.length === 0) {
            return '';
        }

        return `<div class='tariff-notes-heading'><b>Notes:</b></div>${notes.map((n, i) => `<div class='tariff-note'>${n.Text}</div>`).join('')}`;
    };

    return (
        <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
            {showDutiesComponent && tariffsData && (
                <div>
                    <h3>
                        Tariffs for {sector} to {marketName}
                    </h3>
                    {parse(generateDutiesTableContentHtml(), {
                        replace: replaceEmbededObject,
                    })}
                </div>
            )}
        </LoadingOverlay>
    );
};

export default MVTariffs;
