import { MvTaxesAndDuties } from '../models/MvTaxesAndDuties';
import { ErrorModel } from '../models/ErrorModel';
import { MvProduct } from '../models/MvProduct';

export class MVLawsAndRegsService {
    public getTaxesAndDutiesData = (commodity: string, market: string): Promise<MvTaxesAndDuties> => {
        return new Promise<MvTaxesAndDuties>((resolve, reject) => {
            //duty codes to be included in the response (should be upper case); supports multiple codes
            //example: dutyCodesToInclude=MFN&dutyCodesToInclude=CPTPP
            let queryString = '';
            let query = `/api/mv/countries/${market.toUpperCase()}/products/${commodity}/tariffs${queryString ? '?' + queryString : ''}`;

            fetch(query)
                .then((response) => response.json())
                .then((data: MvTaxesAndDuties | ErrorModel) => {
                    let found = false;
                    try {
                        let taxesAndDuties = data as MvTaxesAndDuties;
                        found = !!(taxesAndDuties?.Duties || taxesAndDuties?.Taxes);
                    } catch {}

                    if (found) {
                        resolve(data as MvTaxesAndDuties);
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    reject();
                });
        });
    };

    public getStandardNTLCCode = (sector: string, baseLevel: number) => {
        if (!sector) {
            return undefined;
        }
        if (sector.length >= baseLevel) {
            return sector;
        }
        let result = sector;
        while (result.length < baseLevel) {
            result += '0';
        }
        return result;
    };

    public getProduct = (sector: string, market: string): Promise<MvProduct> => {
        return new Promise<MvProduct>((resolve, reject) => {
            fetch(`/api/mv/countries/${market}/products/${sector}`)
                .then((r) => r.json())
                .then((data: MvProduct | ErrorModel) => {
                    let found = false;
                    try {
                        found = !!(data as MvProduct);
                    } catch {}

                    if (found) {
                        resolve(data as MvProduct);
                    } else {
                        reject();
                    }
                })
                .catch(() => {
                    reject();
                });
        });
    };
}
