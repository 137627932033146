import React, { useRef, useState } from 'react';
import './TooltipText.scss';
import TagManager from 'react-gtm-module';

export interface HSCodesHelpTextProps {
    data: {
        header: string;
        description: string;
    };
}

export const TooltipText = (props: HSCodesHelpTextProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const headerRef = useRef(null);
    const DL_EVENT_NAME = 'tooltip_click';

    const handleOnKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter') {
            headerRef.current.click();
        }
    };

    const handleOnClick = () => {
        let newState = !open;
        setOpen(newState);

        TagManager.dataLayer({
            dataLayer: {
                event: DL_EVENT_NAME,
                dlv_status: newState ? 'expand' : 'collapse',
            },
        });
    };

    return (
        <>
            <div
                tabIndex={0}
                className="mini tooltip-text"
                id="location"
                ref={headerRef}
                onKeyPress={handleOnKeyPress}
                onClick={handleOnClick}
                role="button"
                aria-expanded={open}
            >
                <div className="tooltip-text-dropdown-tile">
                    <div className="svg-container">
                        <svg xmlns="http://www.w3.org/2000/svg" className={open ? 'svg-open' : ''} width="7.5" height="12" viewBox="0 0 7.5 12">
                            <g data-name="Icon / chevron / down" transform="translate(0 12) rotate(-90)">
                                <path
                                    data-name="Chevron / Down"
                                    d="M4.521,6,0,10.513,1.489,12,7.5,6,1.489,0,0,1.487Z"
                                    transform="translate(12) rotate(90)"
                                    fill="#4569a0"
                                />
                            </g>
                        </svg>
                    </div>
                    <span>{props.data.header}</span>
                </div>
                <div
                    className={'mini tooltip-text-dropdown-content' + (open ? ' active' : '')}
                    dangerouslySetInnerHTML={{ __html: props.data.description }}
                ></div>
            </div>
        </>
    );
};
