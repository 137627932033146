import React from 'react';
import { Col } from 'reactstrap';
import { SiteSearchResultItem } from '../../providers/reducers/siteSearchApi';

export const SiteSearchResultTile = ({ searchResult, elementKey }: { searchResult: SiteSearchResultItem; elementKey: number }) => {
    const { Abstract, Title, Url } = searchResult;
    return (
        <div className="site-search-tile-wrapper" key={elementKey}>
            <Col xs={12}>
                <a href={Url}>{Title}</a>
            </Col>
            <Col xs={12} className="description">
                {Abstract}
            </Col>
        </div>
    );
};
