import { ContentItemElementsIndexer, Elements } from '@kontent-ai/delivery-sdk';

export interface PageHeaderProps {
    data: {
        type?: any;
        title: any;
        title_tag?: string;
        titleNumber?: Elements.NumberElement;
        description: any;
        metaDescription?: string;
        image_or_video?: any;
        label?: any;
        lastUpdated_at?: any;
        marketType?: Elements.MultipleChoiceElement;
        urlSlug?: Elements.UrlSlugElement;
        backgroundColorTheme?: Elements.MultipleChoiceElement;
        eventDates?: [string?, string?];
    };
    match?: any;
}

export function getPageHeaderBackgroundStyling(backgroundColorTheme: ContentItemElementsIndexer) {
    let className = '';
    let tempTheme = 'blue';

    if (backgroundColorTheme?.value?.length > 0) {
        tempTheme = backgroundColorTheme.value[0].codename;
    }

    switch (tempTheme) {
        case 'blue':
        case 'teal':
        case 'yellow':
        case 'purple':
            className = `page-header-${tempTheme}-theme`;
            break;
        default:
            className = 'page-header-blue-theme';
            break;
    }

    return className;
}
