import { IContentItem } from '@kontent-ai/delivery-sdk';

export interface NewsFilterItem extends IContentItem {
    id?: string;
    title?: string;
    abstract?: string;
    lastUpdated?: Date;
    imageLink?: string;
    imageCodeName?: string;
    imageCaption?: string;
    urlSlug?: string;
    articleType?: ArticleType;
}

export enum ArticleType {
    NewsArticle = 0,
    SuccessStoryArticle = 1,
    BlogArticle = 2,
}

export const ArticleTypeLabels = {
    [ArticleType.NewsArticle]: 'News',
    [ArticleType.SuccessStoryArticle]: 'Success story',
    [ArticleType.BlogArticle]: 'Blog',
};
