import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { Helmet } from 'react-helmet';
import './SiteSearchResultsHeader.scss';
import { SiteSearchInput } from '../../controls/siteSearch/SiteSearchInput';
import EbgaHead from '../EbgaHead';

interface SiteSearchHeaderProps {
    data: {
        title: any;
        title_tag: string;
        description: any;
        metaDescription: string;
    };
}

const SiteSearchResultsHeader = (props: SiteSearchHeaderProps) => {
    const {
        data: { title, title_tag, description, metaDescription },
    } = props;

    return (
        <>
            <EbgaHead metaDescription={metaDescription} abstract={description} pageTitle={title} titleTag={title_tag} />
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
            <div className="search-results-header">
                <Container>
                    <Row>
                        <Col className="d-flex" xs={12} sm={9} lg={6}>
                            <h1 className="sr-only">Search Results</h1>
                            <SiteSearchInput replaceHistory />
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};
export default SiteSearchResultsHeader;
