import React from 'react';
import PageBody from '../pageBody/PageBody';
import Layout from '../../site/Layout';
import ErrorPageHeader from '../pageHeader/ErrorPageHeader';
import { Container } from 'reactstrap';

const Unauthorized = () => {
    return (
        <Layout>
            <ErrorPageHeader data={{ title: 'Unauthorized' }} />
            <PageBody>
                <Container>
                    <p>Sorry, but you are either not authenticated or authenticated incorrectly.</p>
                </Container>
            </PageBody>
        </Layout>
    );
};

export default Unauthorized;
