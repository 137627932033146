import React from 'react';
import { ComButton } from '../../base/ComButton';

export interface FilterClearProps {
    data?: {
        label?: string;
    };
    onClick?: Function;
}
export const FilterClear = (props: FilterClearProps) => {
    const handleOnClick = () => {
        props.onClick?.();
    };

    return (
        <ComButton variant="link" className="refresh-icon" onClick={handleOnClick} title={props.data.label}>
            <span>{props.data.label}</span>
        </ComButton>
    );
};
