import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import GovtCrest from '../../assets/govtCrest.svg';
import './HeaderTitle.scss';
import { SiteSearchInput } from '../../controls/siteSearch/SiteSearchInput';
import { PageName } from '../../models/PageName';
import { isSiteSearchEnabled } from '../../config';

interface HeaderTitleProps {
    isMobileNavMenuOpen: boolean;
    onMobileNavMenuClick?: () => void;
}

const HeaderTitle = (props: HeaderTitleProps) => {
    const [width, setWidth] = useState('w-50');
    const [showSearch, setShowSearch] = useState(false);
    const location = useLocation();

    const isResultsPage = location.pathname.includes(PageName.SiteSearchResults);

    const onSearchInputFocus = () => {
        setWidth('w-75');
    };

    const onSearchInputBlur = (e) => {
        setWidth(e.target.value ? 'w-75' : 'w-50');
    };

    return (
        <header>
            <Container className="header-title d-print-none">
                <Row>
                    <Col className="header-logo-holder">
                        <Link to="/" reloadDocument>
                            <img className="au-gov-logo float-start d-none d-md-block" src={GovtCrest} alt="Australian Government logo" />
                            <div className="au-gov-logo-divider float-start d-none d-md-block"></div>
                            <div className="au-gov-logo-text float-start">
                                <div className="logo-text" data-testid="header-title-text">
                                    Go Global Toolkit
                                </div>
                                <div className="logo-sub-text d-none d-md-block">Powered by Austrade</div>
                            </div>
                        </Link>
                    </Col>
                    <Col xs={6} className="menu-search d-flex d-md-none">
                        {isSiteSearchEnabled && !isResultsPage && (
                            <div data-testid="mobile-search" className={`mobile-search ${showSearch ? 'open' : ''}`}>
                                <button
                                    tabIndex={0}
                                    onClick={() => {
                                        setShowSearch(!showSearch);
                                        props?.isMobileNavMenuOpen && props?.onMobileNavMenuClick?.();
                                    }}
                                >
                                    Show Search Box
                                </button>
                            </div>
                        )}
                        <div className={`mobile-nav-menu ${props.isMobileNavMenuOpen ? 'open' : ''}`}>
                            <button
                                tabIndex={0}
                                onClick={() => {
                                    props?.onMobileNavMenuClick?.();
                                    setShowSearch(false);
                                }}
                            >
                                Menu
                            </button>
                        </div>
                    </Col>
                    {isSiteSearchEnabled && !isResultsPage && (
                        <Col md={6} className={`search-field ${showSearch ? 'd-flex' : 'd-none'} d-md-flex`}>
                            <div className={`d-flex justify-content-end ${width}`}>
                                <SiteSearchInput onFocus={onSearchInputFocus} onBlur={onSearchInputBlur} />
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        </header>
    );
};

export default HeaderTitle;
