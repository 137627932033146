import React, { useEffect, useState } from 'react';
import { uniqueId } from 'lodash';

export interface LinkControlProps {
    id?: string;
    className?: string;
    href: string;
    target?: string;
    text: string;
    externallink?: boolean;
    externalLinkPosition?: 'before' | 'after';
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const LinkControl = (props: LinkControlProps) => {
    const [id, setId] = useState<string>('');

    useEffect(() => {
        let id = props.id ? props.id : uniqueId('exportservices_link_');
        setId(id);
    }, [props.id]);

    return (
        <a id={id} className={`external-link ${props.className}`} href={props.href} target={props.target} onClick={(e) => e.stopPropagation()}>
            <span>{props.text}</span>
        </a>
    );
};

export default LinkControl;
