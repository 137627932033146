import React, { useEffect, useState } from 'react';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem, PathwayList, PathwayListItem } from '@exporter-services/common-ui';
import { client } from '../../config';
import { filterContentItemAgainstUrlParams, filterContentItemsAgainstUrlParams } from '../../utils/utils';
import { useUrlParams } from '../../hooks/useUrlParams';

interface EbgaPathwayListProps {
    data: {
        item: PathwayListItem;
        title: string;
        linkedItems: IContentItemsContainer;
    };
    match?: any;
}

export default function EbgaPathwayList(props: EbgaPathwayListProps) {
    const urlCodes = useUrlParams(props.match);
    const [filteredContentItems, setFilteredContentItems] = useState<ContentItem[]>(null);

    useEffect(() => {
        if (urlCodes) {
            const pathwayItem = filterContentItemAgainstUrlParams(props.data.item, urlCodes);

            const cardItems = pathwayItem?.elements.cards['linkedItems'];
            if (cardItems?.length > 0) {
                const filteredItems = filterContentItemsAgainstUrlParams(cardItems, urlCodes);
                setFilteredContentItems(filteredItems);
            }
        }
    }, [props.data.item, urlCodes]);

    return filteredContentItems?.length > 0 ? (
        <PathwayList
            data={{ cardItems: filteredContentItems, title: props.data.title, title_tag: props.data.item.elements.title_tag }}
            client={client}
        />
    ) : null;
}
