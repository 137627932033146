import React, { useRef } from 'react';
import { useEffect } from 'react';
import './videoTranscript.scss';
import { strPadLeft } from '../../../utils/utils';
import { smoothScrollTo } from '../../../utils/scrollUtils';
import { usePrevious } from '../../../hooks/usePrevious';

type VideoTranscriptProps = {
    transcriptData?: any;
    playedSeconds?: number | null;
    transcriptClickHandler?: Function;
    style?: object;
};

const VideoTranscript = (props: VideoTranscriptProps) => {
    let transcriptContainer: HTMLElement;
    const startTime = useRef<number>(null);
    const endTime = useRef<number>(null);
    let activeNode: any;
    let transcripts: HTMLElement;

    const prevPlayedSeconds = usePrevious(props.playedSeconds);

    useEffect(() => {
        startTime.current = 0;
        endTime.current = props.transcriptData[0].start;

        if (props.playedSeconds !== prevPlayedSeconds && startTime.current !== props.playedSeconds) {
            if (startTime.current === 0 || props.playedSeconds < startTime.current || props.playedSeconds > endTime.current) {
                let activeNodeNumber;

                for (let i = 0; i < props.transcriptData.length; i++) {
                    let transcript = props.transcriptData[i];
                    if (props.playedSeconds > transcript.start && props.playedSeconds < transcript.start + transcript.dur) {
                        activeNodeNumber = i;
                        break;
                    }
                }
                if (activeNodeNumber === 0 || activeNodeNumber) {
                    let startTime = props.transcriptData[activeNodeNumber].start;
                    let endTime = props.transcriptData[activeNodeNumber].start + props.transcriptData[activeNodeNumber].dur;
                    updateActiveNode(activeNodeNumber, startTime, endTime);
                }
            }
        }
    }, [props.playedSeconds, prevPlayedSeconds, props.transcriptData]);

    const updateActiveNode = (i: number, sTime: number, eTime: number): void => {
        if (activeNode) {
            activeNode.classList.remove('video-transcript-active');
        }
        activeNode = transcripts.children[i];
        activeNode.classList.add('video-transcript-active');
        shouldTranscriptScroll(activeNode);
        startTime.current = sTime;
        endTime.current = eTime;
    };

    const shouldTranscriptScroll = (activeNode: HTMLElement): void => {
        if (activeNode.offsetTop > 23) {
            smoothScrollTo(transcriptContainer, activeNode.offsetTop - 25, 350); //scroll container down, 25 is the distance to top line
        }
    };

    const handleTranscriptClick =
        (e) =>
        (startTime: number, duration: number, i: number): void => {
            e.preventDefault();
            updateActiveNode(i, startTime, startTime + duration);
            props.transcriptClickHandler(startTime); // trigger onSeek in Video
        };

    const renderTranscriptForScreenReadersOnly = (): JSX.Element => {
        return props.transcriptData.map((t, i) => {
            return <p key={i}>{t.text}</p>;
        });
    };

    const renderTranscriptList = (): JSX.Element => {
        return props.transcriptData.map((t, i) => {
            let minutes: number = Math.floor(t.start / 60);
            let seconds: number = Math.floor(t.start - minutes * 60);
            let finalTime: string = strPadLeft(minutes, '0', 2) + ':' + strPadLeft(seconds, '0', 2);
            return (
                <li className="video-transcript-content-line" key={i}>
                    <button onClick={(e) => handleTranscriptClick(e)(t.start, t.dur, i)}>
                        <div>
                            <span>{finalTime}</span>
                        </div>
                        <p>{t.text}</p>
                    </button>
                </li>
            );
        });
    };

    return (
        <div style={props.style} className="video-transcript">
            <div
                className="video-transcript-content"
                ref={(ele) => {
                    transcriptContainer = ele;
                }}
                aria-hidden="true"
            >
                <div>
                    <ul
                        ref={(ele) => {
                            transcripts = ele;
                        }}
                    >
                        {props.transcriptData && props.transcriptData !== 'loading' ? renderTranscriptList() : null}
                    </ul>
                </div>
            </div>
            <div className="sr-only">{renderTranscriptForScreenReadersOnly()}</div>
        </div>
    );
};

export default VideoTranscript;
