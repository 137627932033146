import React from 'react';
import { Progress } from 'reactstrap';
import './QuizProgressBar.scss';

interface QuizProgressBarProps {
    questionsNumber: number;
    questionsCount: number;
    showResults: boolean;
}

const QuizProgressBar = (props: QuizProgressBarProps) => {
    const value = props.showResults ? 100 : (props.questionsNumber / props.questionsCount) * 100.0;
    return (
        <>
            <div className="progressbar-container">
                <Progress value={value}>
                    <div className="progress-text">{Math.round(value)}%</div>
                </Progress>
            </div>
        </>
    );
};
export default QuizProgressBar;
