import React, { useState, useEffect, useContext, useRef } from 'react';
import { Row, Col } from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { client } from '../../config';
import { personalisationContext } from '../../providers/personalisation';
import { ContentItem } from '@exporter-services/common-ui';
import { scrollTo } from '../../utils/scrollUtils';
import { useGetDisplayType } from '../../hooks/useGetDisplayType';
import { useGetMarketsQuery, useGetAllStrongMarketsQuery, useGetStrongMarketsByHSCodeQuery } from '../../providers/reducers/marketsApi';

interface XptExportPlanStep2Props {
    data: {
        item?: ContentItem;
    };

    marketCode: string;
    setMarketCode: Function;
    setIsBackAvailable: Function;
    setIsNextAvailable: Function;
}

interface MarketOption {
    Code: string;
    Label: string;
}

const XptExportPlanStep2 = (props: XptExportPlanStep2Props) => {
    const { personalisationState } = useContext(personalisationContext);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedMarket, setSelectedMarket] = useState<MarketOption>();
    const [marketOptions, setMarketOptions] = useState<MarketOption[]>();
    const { isMobile, isTablet } = useGetDisplayType();
    const marketsDropdownRef = useRef(null);

    const getMarketsQueryResult = useGetMarketsQuery({});
    const getAllStrongMarketsQueryResult = useGetAllStrongMarketsQuery({});
    const getStrongMarketsByHSCodeQuery = useGetStrongMarketsByHSCodeQuery(personalisationState.hsCode);

    useEffect(() => {
        props.setIsBackAvailable(true);
        props.setIsNextAvailable(false);

        if (getMarketsQueryResult.isSuccess && getAllStrongMarketsQueryResult.isSuccess) {
            let allMarkets = getMarketsQueryResult.data;
            let allStrongMarkets = getAllStrongMarketsQueryResult.data;

            if (getStrongMarketsByHSCodeQuery.isSuccess) {
                let filteredStrongMarkets = getStrongMarketsByHSCodeQuery.data;
                return ExtractMarkets(allMarkets, allStrongMarkets, filteredStrongMarkets);
            }
        }
    }, [getMarketsQueryResult.isSuccess, getAllStrongMarketsQueryResult.isSuccess, getStrongMarketsByHSCodeQuery.isSuccess]);

    const ExtractMarkets = (allMarkets: any[], allStrongMarkets: any[], filteredStrongMarkets: any[]) => {
        client
            .items()
            .equalsFilter('system.type', 'market_profile')
            .containsFilter('elements.industrysectorcodesnippet__sector', [personalisationState.sectorCode])
            .depthParameter(5)
            .toPromise()
            .then((response: any) => {
                let markets = extractMarketsFromResponse(response);
                if (markets.length === 0) {
                    markets = extractStrongMarketsFromResponse(allStrongMarkets);
                }
                let uniqueMarkets = extractUniqueMarkets(markets);
                let marketOptions = personalisationState.hsCode
                    ? extractFilteredProductMarketOptions(filteredStrongMarkets, allMarkets)
                    : extractFilteredMarketOptions(uniqueMarkets, allMarkets);

                setMarketOptions(marketOptions);
                hydrateFilters(marketOptions);
                setLoading(false);
            });
    };

    const hydrateFilters = (markets: MarketOption[]) => {
        let market = markets?.find((m) => personalisationState.marketCode && m.Code === personalisationState.marketCode.toLowerCase());
        if (market) {
            setSelectedMarket(market);
            props.setIsNextAvailable(true);
        }
    };

    const extractStrongMarketsFromResponse = (allStrongMarkets: any[]) => {
        let markets = allStrongMarkets
            ?.filter((item) => item.Code === personalisationState.hsCode)
            ?.map((item: any) => item.CountryCode.toUpperCase())
            ?.flat();

        return markets;
    };

    const extractMarketsFromResponse = (response: any) => {
        if (!response.data.items) {
            return [];
        }

        let markets = Array.from(
            response.data.items
                .filter((item) => item.elements.marketcodesnippet__market?.value)
                .map((item: any) => item.elements.marketcodesnippet__market.value.map((r: any) => r.codename.toUpperCase())),
        ).flat();

        return markets;
    };

    const extractUniqueMarkets = (markets: any) => {
        return Array.from(new Set(markets.map((eventMarket: String) => eventMarket)));
    };

    const extractFilteredMarketOptions = (uniqueMarkets: any, allMarkets: any[]) => {
        return uniqueMarkets
            .filter((marketCode: String) => validateMarketCode(marketCode))
            .map((marketCode: any) => {
                return {
                    Code: marketCode.toLowerCase(),
                    Label: marketName(marketCode, allMarkets),
                };
            })
            .sort((a, b) => a.Label.localeCompare(b.Label));
    };

    const extractFilteredProductMarketOptions = (filteredStrongMarkets: any[], allMarkets: any[]) => {
        return filteredStrongMarkets
            ?.filter((strongMarket: any) => validateMarketCode(strongMarket.CountryCode))
            ?.sort((a, b) => a.Rank - b.Rank)
            ?.map((strongMarket: any) => {
                return {
                    Code: strongMarket.CountryCode.toLowerCase(),
                    Label: marketName(strongMarket.CountryCode, allMarkets),
                };
            });
    };

    const validateMarketCode = (marketCode: String) => {
        if (marketCode === null) return false;
        if (marketCode.toUpperCase().indexOf('ALL') !== -1) return false;
        return marketCode.length !== 0;
    };

    const marketName = (marketCode: String, allMarkets: any[]) => {
        return allMarkets.find((y: any) => y.Iso3lCode === marketCode).Name;
    };

    const changeMarket = (event) => {
        let codeName = event.target.value;
        if (!codeName) return;
        let market = marketOptions?.find((m) => m.Code === codeName);
        setSelectedMarket(market);
        props.setMarketCode(market ? market.Code : '');
        props.setIsNextAvailable(market);
    };

    const isMarketSelected = (market) => {
        return selectedMarket && market.Code === selectedMarket.Code;
    };

    const marketsDropdownFocus = () => {
        if (isMobile || isTablet) {
            scrollTo(marketsDropdownRef);
        }
    };

    const renderPrimaryMarkets = (paramMarketsDropdownRef: any) => {
        return (
            <div className="filtergroup-form-field-container" ref={paramMarketsDropdownRef}>
                <label className="field-label" htmlFor="market">
                    {props.data.item.elements.step2_recommended_markets_title?.value}
                </label>
                <select id="market" name="market" onChange={changeMarket} onFocus={marketsDropdownFocus}>
                    {!selectedMarket && (
                        <option key="select_a_market" value="">
                            Select a market
                        </option>
                    )}
                    {marketOptions?.map((market) => (
                        <option
                            className={`${isMarketSelected(market) ? 'selected' : ''}`}
                            key={market.Code}
                            value={market.Code}
                            selected={isMarketSelected(market)}
                        >
                            {market.Label}
                        </option>
                    ))}
                </select>
            </div>
        );
    };

    return (
        <div className="exportplanstep2">
            <LoadingOverlay active={loading} spinner={<SyncLoader />} text="Please wait" className="loader">
                <h2 className="sr-only">{props.data.item?.elements.step2_description.value}</h2>
                <Row className="mt-3">
                    <Col md="12">{renderPrimaryMarkets(marketsDropdownRef)}</Col>
                </Row>
            </LoadingOverlay>
        </div>
    );
};

export default XptExportPlanStep2;
