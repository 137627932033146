import React, { PropsWithChildren } from 'react';
import './ComButton.scss';

type ComButtonProps = {
    variant?: 'primary' | 'secondary' | 'tertiary' | 'link';
};

export const ComButton = ({
    className,
    onClick,
    type = 'button',
    children,
    disabled = false,
    variant = 'primary',
    ...props
}: PropsWithChildren<React.ButtonHTMLAttributes<any>> & ComButtonProps): JSX.Element => {
    return (
        <button type={type} onClick={onClick} className={`gtm-${variant}-btn ${variant} ${className || ''}`} disabled={disabled} {...props}>
            {children}
        </button>
    );
};
