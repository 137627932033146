import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export type UrlParamsType = {
    industryCode: string;
    sectorCode: string;
    hsCode: string;
    marketCode: string;
};

export const useUrlParams = (match: any) => {
    let { industry, sector, market } = match?.params || {};
    const [query] = useSearchParams();
    const [urlParams, setUrlParams] = useState<UrlParamsType>(null);

    useEffect(() => {
        industry = industry ?? query.get('industry');
        sector = sector ?? query.get('sector');

        setUrlParams({
            industryCode: isNaN(sector) ? industry : '',
            sectorCode: isNaN(sector) ? sector : '',
            hsCode: isNaN(sector) ? '' : sector,
            marketCode: market,
        });
    }, [query]);

    return urlParams;
};
