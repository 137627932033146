import React from 'react';
import { ContactItem } from './ContactItem';
import LinkControl from '../linkControl/LinkControl';
import { getLinkOpenTypeString } from '../../../utils/linkOpenTypeHelper';
import './Contacts.scss';

interface ContactsProps {
    data: {
        items: ContactItem[];
    };
}

const Contacts = (props: ContactsProps) => {
    return (
        <div className="contacts pb-4">
            {props.data.items.map((a: ContactItem, i) => (
                <section key={i}>
                    <LinkControl href={a.elements.link.value} target={getLinkOpenTypeString(a)} externallink text={a.elements.title.value} />
                    <br />
                </section>
            ))}
        </div>
    );
};

export default Contacts;
