import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks';
import { UserGeolocationInfo } from '../models/ReduxModels';
import { getUserGeolocation } from '../providers/reducers/userSlice';

export default function useUserGeolocation(): {
    geolocationCode: string;
    isOffshore: boolean;
    retrievalComplete: boolean;
} {
    const [value, setValue] = useState<string>(undefined);
    const [isOffshore, setIsOffshore] = useState<boolean>(false);
    const [retrievalComplete, setRetrievalComplete] = useState<boolean>(false);
    const geolocationInfo = useAppSelector<UserGeolocationInfo>((state) => state.user.geolocationInfo);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserGeolocation({}));
    }, []);

    useEffect(() => {
        if (!geolocationInfo.retrieved) {
            return;
        }

        setValue(geolocationInfo.data.code);
        setIsOffshore(geolocationInfo.data.isOffshore);
        setRetrievalComplete(geolocationInfo.retrieved);
    }, [geolocationInfo.retrieved]);

    return { geolocationCode: value, isOffshore: isOffshore, retrievalComplete: retrievalComplete };
}
