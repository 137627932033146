import React from 'react';
import ComponentResolver from '../../ComponentResolver';
import { Col, Row } from 'reactstrap';
import { IContentItemsContainer } from '@kontent-ai/delivery-sdk';
import { ContentItem } from '@exporter-services/common-ui';
import './SectorLandingPage.scss';

interface SectorLandingPageProps {
    data: {
        items: ContentItem[];
        linkedItems: IContentItemsContainer;
    };
    match: any;
}

const SectorLandingPage = (props: SectorLandingPageProps) => {
    return (
        <Row>
            <Col md={7}>
                {props.data.items
                    ?.filter((item) => item.system.type !== 'crm_form')
                    ?.map((item: ContentItem) => {
                        return (
                            <ComponentResolver data={{ item: item, linkedItems: props.data.linkedItems }} key={item.system.id} match={props.match} />
                        );
                    })}
            </Col>
            <Col md={5} className="d-print-none">
                <div className="crmFormContainer sticky-top">
                    {props.data.linkedItems &&
                        props.data.items
                            .filter((item) => item.system.type === 'crm_form')
                            .map((item: ContentItem) => {
                                return (
                                    <ComponentResolver
                                        data={{ item: item, linkedItems: props.data.linkedItems }}
                                        key={item.system.id}
                                        match={props.match}
                                    />
                                );
                            })}
                </div>
            </Col>
        </Row>
    );
};

export default SectorLandingPage;
