import { BootstrapBreakpointEnum } from '../models/BootstrapBreakpointEnum';

export class PageService {
    static readonly SMALL_BREAKPOINT: number = 576;
    static readonly MEDIUM_BREAKPOINT: number = 768;
    static readonly LARGE_BREAKPOINT: number = 992;
    static readonly EXTRA_LARGE_BREAKPOINT: number = 1200;
    static readonly EXTRA_EXTRA_LARGE_BREAKPOINT: number = 1400;

    public GetCurrentBootstrapBreakpoint = (pageWidth: number): BootstrapBreakpointEnum => {
        if (isNaN(pageWidth) || pageWidth === null) {
            return undefined;
        }

        if (pageWidth < PageService.SMALL_BREAKPOINT) {
            return BootstrapBreakpointEnum.ExtraSmall;
        } else if (pageWidth >= PageService.SMALL_BREAKPOINT && pageWidth < PageService.MEDIUM_BREAKPOINT) {
            return BootstrapBreakpointEnum.Small;
        } else if (pageWidth >= PageService.MEDIUM_BREAKPOINT && pageWidth < PageService.LARGE_BREAKPOINT) {
            return BootstrapBreakpointEnum.Medium;
        } else if (pageWidth >= PageService.LARGE_BREAKPOINT && pageWidth < PageService.EXTRA_LARGE_BREAKPOINT) {
            return BootstrapBreakpointEnum.Large;
        } else if (pageWidth >= PageService.EXTRA_LARGE_BREAKPOINT && pageWidth < PageService.EXTRA_EXTRA_LARGE_BREAKPOINT) {
            return BootstrapBreakpointEnum.ExtraLarge;
        }

        return BootstrapBreakpointEnum.ExtraExtraLarge;
    };

    public GetCurrentApplicableBootstrapBreakpointList = (pageWidth: number): Array<BootstrapBreakpointEnum> => {
        if (isNaN(pageWidth) || pageWidth === null) {
            return undefined;
        }

        let response = [];
        if (pageWidth < PageService.SMALL_BREAKPOINT) {
            response.push(BootstrapBreakpointEnum.ExtraSmall);
        }
        if (pageWidth >= PageService.SMALL_BREAKPOINT) {
            response.push(BootstrapBreakpointEnum.Small);
        }
        if (pageWidth >= PageService.MEDIUM_BREAKPOINT) {
            response.push(BootstrapBreakpointEnum.Medium);
        }
        if (pageWidth >= PageService.LARGE_BREAKPOINT) {
            response.push(BootstrapBreakpointEnum.Large);
        }
        if (pageWidth >= PageService.EXTRA_LARGE_BREAKPOINT) {
            response.push(BootstrapBreakpointEnum.ExtraLarge);
        }
        if (pageWidth >= PageService.EXTRA_EXTRA_LARGE_BREAKPOINT) {
            response.push(BootstrapBreakpointEnum.ExtraExtraLarge);
        }

        return response;
    };

    public IsCurrentBootstrapBreakpointOrGreater = (
        currentApplicableBreakpoints: BootstrapBreakpointEnum[],
        breakpointToCheck: BootstrapBreakpointEnum,
    ) => {
        if (breakpointToCheck === BootstrapBreakpointEnum.ExtraSmall) {
            return true;
        } else {
            if (currentApplicableBreakpoints.find((b) => b === breakpointToCheck)) {
                return true;
            }
        }

        return false;
    };

    public IsCurrentBootstrapBreakpointOnly = (
        currentApplicableBreakpoints: BootstrapBreakpointEnum[],
        breakpointToCheck: BootstrapBreakpointEnum,
    ) => {
        if (breakpointToCheck === BootstrapBreakpointEnum.ExtraSmall) {
            if (
                currentApplicableBreakpoints.length === 1 &&
                currentApplicableBreakpoints.filter((b) => b === BootstrapBreakpointEnum.ExtraSmall).length === 1
            ) {
                return true;
            }
        } else {
            if (
                this.IsCurrentBootstrapBreakpointOrGreater(currentApplicableBreakpoints, breakpointToCheck) &&
                currentApplicableBreakpoints.filter((b) => b > breakpointToCheck).length === 0
            ) {
                return true;
            }
        }

        return false;
    };
}
