import { CommonConstants } from '../CommonConstants';
import { client } from '../config';
import {
    KenticoIndustryDictionary,
    KenticoIndustryOption,
    KenticoIndustrySectorCollection,
    KenticoMarket,
    KenticoSectorOptionFlat,
    KenticoRegion,
} from '../models/ReduxModels';
import Pluralize from 'pluralize';

export class TaxonomyService {
    //#region getSectorTaxonomyGroup
    public getSectorTaxonomyGroup = (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            client
                .taxonomy('sector')
                .toPromise()
                .then((response) => {
                    resolve(this.processSectorTaxonomyGroup(response.data));
                })
                .catch((err) => reject(err));
        });
    };

    processSectorTaxonomyGroup = (response: any): KenticoIndustrySectorCollection => {
        if (!response?.taxonomy?.terms) return response;

        let sectorsCollection: KenticoSectorOptionFlat = {};
        let industryDictionary: KenticoIndustryDictionary = {};
        let index = 0;

        let industryArrayList: KenticoIndustryOption[] = response.taxonomy.terms
            .filter(
                (r: any) =>
                    !(
                        r.codename === CommonConstants.KONTENT_TAXONOMY_ALL_CODENAME ||
                        r.codename === CommonConstants.KONTENT_TAXONOMY_NOT_LISTED_CODENAME
                    ),
            )

            .map((r: any) => {
                let industryRecord: KenticoIndustryOption = {
                    id: index,
                    code: r.codename,
                    description: r.name,
                };

                industryDictionary[r.codename] = { description: r.name };

                this.processSectors(r.terms, index, r.codename, sectorsCollection);
                index++;
                return industryRecord;
            });

        return {
            industryCollection: industryArrayList,
            industryDictionary: industryDictionary,
            sectorCollectionFlat: sectorsCollection,
        } as KenticoIndustrySectorCollection;
    };

    processSectors = (terms: any, paramIndustryId: number, paramIndustryCode: string, paramSectorsCollection: KenticoSectorOptionFlat) => {
        if (!terms) return;

        terms.forEach((r: any) => {
            paramSectorsCollection[r.codename] = { description: r.name, industryId: paramIndustryId, industryCode: paramIndustryCode };

            if (r.terms?.length) {
                this.processSectors(r.terms, paramIndustryId, paramIndustryCode, paramSectorsCollection);
            }
        });
    };

    //#endregion

    //#region getRegionMarketMapping
    public getRegionMarketMapping = (): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            client
                .items()
                .equalsFilter('system.type', 'region')
                .notEqualsFilter('system.workflow_step', 'archived')
                .toPromise()
                .then((response) => {
                    resolve(this.processRegionMarketResult(response.data));
                })
                .catch((err) => reject(err));
        });
    };

    processRegionMarketResult = (response: any): KenticoRegion[] => {
        if (!response?.items) return null;

        let regionMarketList: KenticoRegion[] = response.items.map((r: any) => {
            let tempMarkets = this.processMarkets(r.elements.marketcodesnippet__market);
            let regionRecord: KenticoRegion = {
                code: r.system.codename,
                description: r.system.name,
                markets: tempMarkets,
            };
            return regionRecord;
        });

        return regionMarketList;
    };

    processMarkets = (marketsData: any) => {
        if (!marketsData?.value) return null;

        let markets: KenticoMarket[] = [];

        marketsData.value.forEach((r: any) => {
            let position = r.name.indexOf('|') === -1 ? r.name.length : r.name.indexOf('|');
            markets.push({ code: r.codename, description: r.name.substring(0, position).trim() });
        });

        return markets;
    };

    //#endregion

    //#region getPageCategoryTaxonomy
    public getPageCategoryTaxonomy = (): Promise<Array<string>> => {
        return new Promise<Array<string>>((resolve, reject) => {
            client
                .taxonomy('page_category')
                .toPromise()
                .then((response) => {
                    resolve(this.processPageCategoryResponse(response.data));
                })
                .catch((err) => reject(err));
        });
    };

    processPageCategoryResponse = (data: any): Array<string> => {
        return data?.taxonomy?.terms?.map((term) => Pluralize(term.name));
    };
    //#endregion
}
