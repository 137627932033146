import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import './UNCommtrade.scss';
import MarketSizeIcon from '../../assets/AEG-Global.svg';
import MarketGrowthIcon from '../../assets/AEG-Growth.svg';
import AustralianMarketShareIcon from '../../assets/AEG-magnify glass and Aust map.svg';
import AustralianMarketShareGrowthIcon from '../../assets/AEG-Pie-chart.svg';
import { ContentItem } from '@exporter-services/common-ui';
import LoadingOverlay from 'react-loading-overlay-ts';
import SyncLoader from 'react-spinners/SyncLoader';
import { useGetHSCodeLabelQuery } from '../../providers/reducers/commoditiesApi';
import { Commodity } from '../../models/Commodity';
import { useGetUnCommTradeDataQuery } from '../../providers/reducers/marketsApi';
import { UNCommtrade } from '../../models/UNCommtrade';

interface UNCommtradeProps {
    data: {
        item: ContentItem;
    };
    match: any;
    onLoad?: (dataAvailable: boolean, title: string) => void;
}

const UNCommtradeComponent = (props: UNCommtradeProps) => {
    const [unCommtrade, setunCommtrade] = useState<UNCommtrade>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [commodity, setCommodity] = useState<Commodity>();
    const [commodityLoading, setCommodityLoading] = useState<boolean>(true);
    const dataNotAvailable = 'Data not available';

    const getUnCommTradeDataQueryResponse = useGetUnCommTradeDataQuery(
        { marketCode: props.match.params.market, hsCode: props.match.params.sector },
        { skip: !props.match.params.market || !props.match.params.sector },
    );

    const getHSCodeLabelQueryResponse = useGetHSCodeLabelQuery(
        { hsCode: props.match.params.sector },
        { skip: !(props.match.params.sector && (props.match.params.sector.length === 4 || props.match.params.sector.length === 6)) },
    );

    const formatMillions = (amount: number) => {
        // Nine Zeroes for Billions
        const PRECISION = 2;
        let abs = Math.abs(amount);
        let preciseValue = parseFloat(abs.toString()).toFixed(PRECISION);

        return abs >= 1.0e9
            ? parseFloat((abs / 1.0e9).toString()).toFixed(PRECISION) + 'B'
            : // Six Zeroes for Millions
              abs >= 1.0e6
              ? parseFloat((abs / 1.0e6).toString()).toFixed(PRECISION) + 'M'
              : // Three Zeroes for Thousands
                abs >= 1.0e3
                ? parseFloat((abs / 1.0e3).toString()).toFixed(PRECISION) + 'K'
                : preciseValue;
    };

    useEffect(() => {
        if (getUnCommTradeDataQueryResponse.isSuccess && getHSCodeLabelQueryResponse.isSuccess) {
            if (getUnCommTradeDataQueryResponse.data) {
                setunCommtrade(getUnCommTradeDataQueryResponse.data);
            }
            if (getHSCodeLabelQueryResponse.data) {
                setCommodity(getHSCodeLabelQueryResponse.data);
                setCommodityLoading(false);
            }
            setLoading(false);
            props.onLoad?.(!!getUnCommTradeDataQueryResponse.data, 'Market overview');
        }
    }, [getUnCommTradeDataQueryResponse.isSuccess, getHSCodeLabelQueryResponse.isSuccess]);

    const formattedPercentageData = (data: number) => {
        if (data === null || data === undefined) {
            return dataNotAvailable;
        } else {
            return `${data}%`;
        }
    };
    return (
        <>
            {unCommtrade && commodity && (
                <LoadingOverlay active={loading && commodityLoading} spinner={<SyncLoader />} text="Please wait" className="loader">
                    <div className="uncommtrade-container">
                        <span>
                            This <b>market is a strong performer</b> for Australian exports of <b>{commodity.Label}.</b>
                        </span>
                        <div className="uncommtrade-content">
                            <Row className="uncommtrade">
                                <Col md={6}>
                                    <Row>
                                        <Col className="d-none d-md-block" xs={2} md={4} lg={3}>
                                            <img src={MarketSizeIcon} alt=""></img>
                                        </Col>
                                        <Col xs={10} md={8} lg={9} className="text">
                                            Market value in USD ({unCommtrade.EndYear})<p>{formatMillions(unCommtrade.MarketSize)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col className="d-none d-md-block" xs={2} md={4} lg={3}>
                                            <img src={MarketGrowthIcon} alt=""></img>
                                        </Col>
                                        <Col xs={10} md={8} lg={9} className="text">
                                            Market growth ({unCommtrade.StartYear} – {unCommtrade.EndYear})
                                            <p>{formattedPercentageData(unCommtrade.MarketGrowth)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="uncommtrade">
                                <Col md={6}>
                                    <Row>
                                        <Col className="d-none d-md-block" xs={2} md={4} lg={3}>
                                            <img src={AustralianMarketShareIcon} alt=""></img>
                                        </Col>
                                        <Col xs={10} md={8} lg={9} className="text">
                                            Australian market share ({unCommtrade.EndYear})
                                            <p>{formattedPercentageData(unCommtrade.AustralianMarketShare)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row>
                                        <Col className="d-none d-md-block" xs={2} md={4} lg={3}>
                                            <img src={AustralianMarketShareGrowthIcon} alt=""></img>
                                        </Col>
                                        <Col xs={10} md={8} lg={9} className="text">
                                            Australian market share growth ({unCommtrade.StartYear} – {unCommtrade.EndYear})
                                            <p>{formattedPercentageData(unCommtrade.AustralianMarketShareGrowth)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="uncommtrade">
                                <Col md={6}></Col>
                            </Row>

                            <Row>
                                <Col>
                                    <a href={props.data.item.elements.link.value}>{props.data.item.elements.link_text.value}</a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </LoadingOverlay>
            )}
        </>
    );
};

export default UNCommtradeComponent;
