import { ExportPlanDownloadRequest } from '../models/ReduxModels';

export class ExportPlanService {
    public exportPlanDownload = (request: ExportPlanDownloadRequest): Promise<any> => {
        return new Promise<any>((resolve, reject) => {
            fetch('/api/exportplannertool/GenerateExportPlan', {
                method: 'POST',
                body: JSON.stringify({
                    IndustryCode: request.industryCode,
                    SubSectorCode: request.sectorCode,
                    HsProductCode: request.hsCode,
                    MarketIsoCode: request.marketCode,
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                    Responsetype: 'blob',
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    resolve(data);
                })
                .catch((err) => reject(err));
        });
    };
}
